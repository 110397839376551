import React, {useEffect, useRef, useState} from "react";
import '@assets/css/style.css'
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import {useHistory} from "react-router-dom";
import useExpand from "@utils/hooks/useExpand";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const PayAndGetPayedSection = () => {
  const [cashbackSection, setCashbackSection] = useState();
  const [marketplaceSection, setMarketplaceSection] = useState();
  const [payAndGetPayedSection, setPayAndGetPayedSection] = useState();
  const history = useHistory();
  const {i18n} = useTranslation();
  const containerRef = useRef();
  const imageRef = useRef();
  const buttonRef = useRef();
  const marketPlaceContainerRef = useRef();
  const marketPlaceButtonRef = useRef();
  const cashbackContainerRef = useRef();
  const cashbackImageRef = useRef();
  const cashbackButtonRef = useRef();
  useExpand(containerRef, imageRef, buttonRef);
  useExpand(marketPlaceContainerRef, null, marketPlaceButtonRef);
  useExpand(cashbackContainerRef, cashbackImageRef, cashbackButtonRef)
  const navigate = (url) => {
    history.push(url);
  };

  useEffect(async () => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/home-page?locale=${language}&populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setCashbackSection(_.get(attributes, 'Cashback', {}));
      setMarketplaceSection(_.get(attributes, 'Marketplace', {}));
      setPayAndGetPayedSection(_.get(attributes, 'PayAndGetPayed', {}));
    })
  }, [i18n?.language]);

  return (
    <div className="flex flex-col w-full justify-center items-center px-5 lg:px-0">
      <div
        className="relative overflow-hidden md:w-2/3 bg-brand-dark-blue pay-and-get-paid-component md:pr-6 pt-10 lg:pr-5 xl:pt-15"
        ref={containerRef}>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="flex justify-start lg:justify-center items-center self-center">
            {payAndGetPayedSection ?
              <img ref={imageRef} src={payAndGetPayedSection?.image?.data?.attributes?.url} alt="Pok"
                   className="shine w-2/3 lg:w-full bottom-0 left-0 lg:-mb-30 xl:-mb-3 3xl:-mb-6"/> :
              <SkeletonLoader variant='circular' height={300} width={300}/>}
          </div>
          <div className="w-5/6 flex flex-col lg:w-11/12 md:mt-0 px-3 xl:px-10 text-left pl-5">
            <div
              className="font-montserrat 3xl:text-5xl md:text-3xl font-semibold text-white leading-5 tracking-wide mt-5">
              {payAndGetPayedSection ? payAndGetPayedSection?.title : <SkeletonLoader/>}
            </div>
            <div
              className="font-light text-white font-montserrat mt-5 mb-5 lg:mt-10 lg:mb-15 text-xs md:text-sm">
              {payAndGetPayedSection ? payAndGetPayedSection?.description : <SkeletonLoader variant='text' count={2}/>}
            </div>
            <div className="flex justify-start mb-10 3xl:mt-20">
              {payAndGetPayedSection ? <CPrimaryButton buttonRef={buttonRef} label={payAndGetPayedSection?.button}
                                                       onClick={() => navigate("/pay-and-get-paid")}/> :
                <SkeletonLoader variant='text' width={600}/>}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 w-full lg:grid-cols-2 md:w-2/3 mt-12 gap-12" style={{ minHeight: "500px" }}>
        <div className="relative px-6 py-10 md:py-10 lg:px-5 xl:py-15 market-place-component"
             ref={marketPlaceContainerRef}>
          <div className="flex flex-col md:mt-0 xl:px-6 text-left md:text-left">
            <div
              className="font-montserrat xl:text-4xl md:text-3xl text-2xl font-semibold text-white leading-5 tracking-wide md:-mt-1">
              {marketplaceSection ? marketplaceSection?.subtitle : <SkeletonLoader variant='text'/>}
            </div>
            <div className="absolute bottom-0 left-3 xl:left-10 mb-8 xl:mb-10">
              {marketplaceSection ? <CPrimaryButton buttonRef={marketPlaceButtonRef} label={marketplaceSection?.button}
                                                    onClick={() => navigate("/marketplace")}/> :
                <SkeletonLoader variant='text' width={400}/>}
            </div>
          </div>
        </div>
        <div className="relative px-6 py-10 md:py-10 lg:px-5 xl:py-15 cashback-component" ref={cashbackContainerRef}>
          <div className="flex flex-col">
            <div className="flex flex-col md:mt-0 xl:px-6 text-left md:text-left">
              <div
                className="font-montserrat xl:text-4xl md:text-3xl text-2xl font-semibold text-white leading-5 tracking-wide md:-mt-1">
                {cashbackSection ? cashbackSection?.subtitle : <SkeletonLoader variant='text'/>}
              </div>
              <div className="flex justify-end items-center self-end mt-32 md:mt-60 xl:mt-40 3xl:mt-60">
                {cashbackSection ?
                  <img ref={cashbackImageRef} src={cashbackSection?.image?.data?.attributes?.url} alt="percentage"
                       className="shine h-32 xl:h-44"/> : <SkeletonLoader variant='circular' height={200} width={200}/>}
              </div>
              <div className="absolute bottom-0 left-3 xl:left-10 mb-8 xl:mb-10">
                {cashbackSection ? <CPrimaryButton buttonRef={cashbackButtonRef} label={cashbackSection?.button}
                                                   onClick={() => navigate("/cashback")}/> :
                  <SkeletonLoader variant='text' width={400}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PayAndGetPayedSection