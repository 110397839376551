import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {supportedLanguages} from "@constants/languages";
import { FaChevronDown } from "react-icons/fa";

const LanguageSelector = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const {i18n} = useTranslation()

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => localStorage.setItem('POKWebLang', lang));
  }

  const onClickOutsideListener = useCallback(() => {
    setShowDropdown(false);
    document.removeEventListener('click', onClickOutsideListener);
  }, []);

  const addOnClickOutsideListener = useCallback(() => {
    document.addEventListener('click', onClickOutsideListener);
  }, [onClickOutsideListener]);

  const languageOptions = () => supportedLanguages.map((lang) => (
    <div
      id={lang}
      key={lang}
      className={`flex justify-center px-1 py-2 bg-transparent ${
        i18n.language === lang ? `bg-brand-light-blue` : 'hover:bg-brand-light-blue hover:bg-opacity-20 cursor-pointer'
      }`}
      onClick={() => switchLanguage(lang)}>
      <div className="text-sm font-medium uppercase">
        {lang}
      </div>
    </div>
  ));

  useEffect(() => {
    if (showDropdown) addOnClickOutsideListener();
  }, [addOnClickOutsideListener, showDropdown]);

  return (
    <div
      className="relative w-11">
      <div
        className={`w-full focus:outline-none py-1 flex items-center cursor-pointer ${showDropdown ? 'text-brand-light-blue' : 'text-white'} hover:text-brand-light-blue transition-text duration-300`}
        onClick={() => {
          setShowDropdown((prevState) => !prevState);
        }}>
        <div className="uppercase text-sm font-medium">
          {i18n.language}
        </div>
        <FaChevronDown className="h-3 ml-1" />
      </div>
      <div
        className={`${
          showDropdown ? 'max-h-56' : 'max-h-0'
        } transition-all duration-300 ease-in w-full absolute z-50 bg-white bg-opacity-90 mt-0.5 rounded-md shadow-2xl overflow-hidden`}>
        {languageOptions()}
      </div>
    </div>
  )
}

export default LanguageSelector;