import Layout from "@hoc/layout/Layout";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "@utils/hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import { dateFormatter, resizeImageHeight, showError } from "@utils/helpers";
import useMouseLight from "@utils/hooks/useMouseLight";
import CMSAPI from "@utils/plugins/CMSAPI";

const Tag = ({ name, ...props }) => (
  <div {...props}>
    <div className="group">
      <button
        className="flex news-data items-center justify-center rounded-full border-0.5 px-4 h-8 cursor-default"
      >
          <span
            className="text-center text-white whitespace-nowrap text-xxs md:text-sm uppercase font-montserrat font-semibold">
            {name}
          </span>
      </button>
    </div>
  </div>
)

const NewsArticlePage = () => {
  const [article, setArticle] = useState(undefined);
  const [imageHeight, setImageHeight] = useState(0);
  const newsArticleRef = useRef(null);
  useMouseLight(newsArticleRef);

  const { id } = useParams();
  const history = useHistory();
  const mainImgRef = useRef();
  const { width } = useWindowDimensions();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/news/${id}?locale=${language}&sort=date:DESC&pagination[pageSize]=10&populate=deep`).then((response) => {
      const news = response.data.data?.attributes;
      setArticle(news);
    }).catch(() => {
      showError(t('somethingWentWrong'));
      history.push('/news?selectedTab=news');
    });
  }, [id, i18n.language, history, t]);

  useEffect(() => {
    if (mainImgRef.current?.offsetWidth) {
      setImageHeight(resizeImageHeight(mainImgRef.current?.offsetWidth));
    }
  }, [width, mainImgRef.current?.offsetWidth]);

  return (
    <div className="flex flex-col">
      <Layout>
        <div className='fixed' ref={newsArticleRef}/>
        <div className="flex flex-col items-center justify-center relative pt-20 pb-10 md:pb-20">
          <div className="w-full px-5 md:w-2/3">
            <div className="flex flex-1">
              <img
                ref={mainImgRef}
                src={article?.photoUrl?.data?.attributes?.url}
                className="object-cover w-full rounded-lg"
                style={{ height: imageHeight }}
                alt=""
              />
            </div>
            <div className="flex flex-row justify-between items-center my-5">
              <div className="flex-1">
                <CPrimaryButton
                  label="Back"
                  onClick={() => history.push(`/news`)}
                  className="w-22"
                />
              </div>
              <div className="flex flex-1 justify-end mr-2">
                <Tag name="Blog" className="pr-1 md:pr-3"/>
                <Tag name={dateFormatter(article?.date, "DD MMMM YYYY")}/>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 my-3 md:mt-16 pl-2">
              <div className="font-montserrat text-3xl text-white font-bold pb-5 md:pb-10 uppercase mr-4">
                {article?.title}
              </div>
              <div className="font-montserrat text-white text-justify pb-5">
                <div dangerouslySetInnerHTML={{ __html: article?.description }}/>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-padding"/>
      </Layout>
    </div>
  );
};
export default NewsArticlePage;
