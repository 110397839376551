const en = {
  home: "Home",
  getStarted: "Get Started",
  aboutUs: "About Us",
  contact: "Contact",
  contactUs: "Get in touch",
  contactUsDescription:
    "Contact us for every information or question regarding POK by filling out the form ",
  becomeAMember: "BECOME A MEMBER OF OUR COMMUNITY",
  downloadPOK: "Download the POK application on your smartphone",
  send: "Send",
  blog: "Blog",
  message: "Message",
  shortMessage: "Short message",
  vacancies: "Vacancies",
  sendMessage: "Send Message",
  writeYourMessage: "Write your message...",
  nameSurname: "Name Surname",
  phone: "Phone",
  phoneNumber: "Phone Number",
  termsAndConditions: "Terms & Conditions",
  frequentlyAskedQuestions: "Frequently Asked Questions",
  privacyData: "Privacy Data",
  superEasy: "Super easy!",
  boardMembers: "Board Members",
  pokBoard: "POK board members",
  homeHeaderSubtitle: "NOW YOU CAN",
  homeHeaderTitle: "Reliable payments and money transfers",
  sendMoneyTitle: "Create your profile today",
  sendMoneyBody:
    "Enter your phone number and we will send you a link to download our application. Don't forget to invite your friends to join our growing community",
  utilityPaymentsTitle: "Fast and Painless utility payments",
  utilityPaymentsDescription:
    "Effortless utility payments from the comfort of your smartphone. Utility payments can be completed within 15 seconds",
  utilityPaymentsElectricity: "Pay your electricity bills",
  utilityPaymentsWaterSupply: "Pay your water supply bills",
  utilityPaymentsElectricityDescription:
    "Recurring and one time payments of your bills",
  utilityPaymentsPhoneTitle: "Top up or pay your phone bills",
  utilityPaymentsPhoneDescription:
    "Pay your mobile phone bill or top up your account instantly and effortlessly using our application.",
  utilityPaymentsFinesTitle: "Pay traffic fines",
  utilityPaymentsFinesDescription:
    "We will notify you as soon as a fine for your car is registered in the police database. You can then pay it instantly through POK",
  billPaymentsTitle:
    "Instant payments, in retail, bars, restaurants and supermarkets",
  billPaymentsSubtitle:
    "All you have to do is scan a QR code using your smartphone and authorize the payment.",
  billPaymentsDescription:
    "Pay receipts in every merchant that is using POK payment services.",
  partners: "Partners:",
  aboutUsHeaderTitle:
    "An application for faster, safer, and cheaper payments and transfers",
  aboutUsHeaderDescription:
    "At POK we have mixed cutting edge technology and enhanced engineering practices so that our customers enjoy fast, reliable, and cheap payment services.",
  aboutUsDescriptionFirstParagraph:
    "POK offers electronic payment and money transfer services and continuously improves its services to ensure that the customers have the latest technological developments in the palm of their hands.",
  aboutUsDescriptionSecondParagraph:
    "Whether you are a small business, a big corporate, a small shop or just an individual that wants to sell online don’t worry. We have put our best efforts and partnered with international companies so that we could offer to our customers a payment solution that is easier to integrate than any other available service in the market.",
  aboutUsFirstPointTitle: "We drive innovation",
  aboutUsFirstPointSubtitle:
    "Our R&D department is constantly working on improving the services we offer to our customers so that they have the best payment solution available to them.",
  aboutUsSecondPointTitle: "Ease of payments",
  aboutUsSecondPointSubtitle:
    "Our payment solution closes the gap between buyers and sellers by simplifying the process to the point that it can be used by literally, everyone.",
  aboutUsThirdPointTitle: "Our user base keeps growing",
  aboutUsThirdPointSubtitle:
    "We keep adding new features to our system to embrace the ever-growing demands of merchants offering their services online and wishing for a simplified payment solution.",
  aboutUsFourthPointTitle: "We are a team based in Tirana",
  aboutUsFourthPointSubtitle:
    "If you feel you have a great potential and want to be part of our team in the quest of revolutionizing the payment industry check out our vacancies.",
  merchant: "Merchant",
  nuis: "NUIS",
  businessNuis: "Business' NUIS",
  operator: "Operator",
  appsComingSoon: "Soon in App Store and Play Store",
  merchantQrSubtitle: "Space Bar",
  merchantQrTitle: "Download QR Code",
  merchantQrDescription:
    "Please print your static QR Code and place it near your POS",
  download: "Download",
  news: "News",
  read: "Read",
  emptyNews: "There are no news currently",
  back: "Back",
  latestNews: "Latest News",
  emptyArticle: "News article is missing",
  emptyFaq: "There are no questions yet",
  career: "Career",
  careerAtPOK: "POK",
  careersSubtitle: "Become a member of our team",
  careersIntroductionFirst:
    "We took upon us the challenge to bring the best fintech services in Albania",
  careersIntroductionSecond:
    "For this purpose we have used the latest and most advanced technologies in every cell of our services",
  careersIntroductionThird:
    " If you want to do your part in improving fintech services do not hesitate to contact us",
  currentVacancies: "Currently there are {{recordCount}} vacant positions",
  applicationDeadline: "Application deadline:",
  applyForThisPosition: "Apply for this position",
  name: "Name",
  surname: "Surname",
  email: "Email",
  uploadCV: "Upload curriculum in pdf format",
  uploadCoverLetter: "Upload cover letter in pdf format",
  sendApplication: "Send application",
  whereToPayWithPOK: "Where you can pay with POK",
  whereToDescription: "New businesses are added every day to our big family!",
  whereToSubDescription:
    "Experience the joy of digital payments with POK and enjoy the purchases easier than ever before!",
  somethingWentWrong: "Something went wrong",
  applicationSuccess: "Application was a success",
  areYouMerchant: "Are you a merchant?",
  contactWithUs:
    "Click here in order to create your account for accepting payments with POK",
  contactUsMerchant: "Contact",
  contactForMerchant: "Contact for merchant account",
  businessName: "Business Name",
  businessNamePlaceholder: "Name of company/business",
  messageSuccess: "Message is sent successfully",
  contactSoon: "We will contact you very soon for the next steps",
  close: "Close",
  fieldNotEmpty: "Please fill the empty field",
  emailValidation: "Email should have the form: test@test.test",
  phoneValidation: "Phone number must be in the form: +355676767677",
  nuisValidation: "NIPT must be in the form: A12345678A",
  scanAndPay: "Scan and pay",
  downloadPOKApp: "Download POK application for fast payments",
  disputes: "Disputes",
  disputesDescription:
    "Fill in the form regarding the order you want to dispute",
  orderId: "Order ID",
  title: "Title",
  description: "Description",
  plateNumber: "Plate Number",
  vinNumber: "Vin Number",
  payTpl: "Pay TPL",
  next: "Next",
  nid: "NID (Personal Number)",
  firstname: "First Name",
  lastname: "Last Name",
  address: "Address",
  gender: "Gender",
  male: "Male",
  female: "Female",
  skip: "Skip",
  secondUser: "Second user (Optional)",
  secondUserText: "Enter second user data",
  searchVehicle: "Search vehicle",
  enterVehicleData: "Enter vehicle data",
  tplInsurance: "TPL Insurance",
  insuranceDuration: "Insurance duration",
  startDate: "Start date",
  endDate: "End date",
  birthday: "Birthday",
  insuranceDurationText: "Please enter the start date of the TPL Insurance",
  billingInfo: "Billing Info",
  billingInfoText: "Please complete the required fields",
  locality: "City",
  administrativeArea: "State",
  country: "Country",
  postalCode: "ZIP Code",
  vehicleData: "Vehicle data",
  ownerData: "Owner data",
  optional: "Optional",
  errors: {
    required: "Required",
    emptyNid: "Please enter personal number",
    invalidNid: "Invalid personal number",
    emptyPhone: "Please enter your phone number",
    emptyEmail: "Please enter your email",
    emptyFirstName: "Please enter first name",
    emptyLastName: "Please enter last name",
    emptyFirstAndLastName: "Please enter first and last name",
    emptyBirthday: "Please select birth date",
    emptyPlate: "Please enter vehicle registration plate",
    emptyVin: "Please enter vin number",
    selectGender: "Please select gender",
    countryCode: "Country cannot be empty",
    requiredState: "State cannot be empty",
    emptyLocality: "City cannot be empty",
    emptyAddress: "Address cannot be empty",
    incorrectPhoneFormat: "Phone number is not valid",
    incorrectEmailFormat: "Email is not valid",
    chooseAmount: "Please choose a refill amount",
    emptyContractCode: "Please enter your contract code",
    emptySerialNumber: "Please enter the serial number of the invoice",
    emptyMessage: "Please enter your message",
    invalidNuis: "This NUIS is not a valid one",
    notEmpty: "Cannot be empty",
  },
  fullName: "Full Name",
  type: "Type",
  utilities: "Utilities",
  services: "Services",
  waterSupply: "Water Supply",
  vehicleFines: "Vehicle Fines",
  internet: "Internet/Telephone",
  insurances: "Insurances",
  insuranceTypes: "Insurance Types",
  tplDescription: "Liability insurance for third parties",
  travelInsurance: "Travel health insurance",
  travelInsuranceDescription: "Travel health insurance",
  studentInsurance: "Student insurance abroad",
  albsigMed: "Albsig Med",
  vehicleOwnership: "Vehicle owner",
  searchCountry: "Search Country ...",
  person: "Person",
  business: "Business",
  toCheckout: "To Checkout",
  requestedPageNotFound: "Requested page not found",
  tplAmount: "TPL Amount",
  checkYourData: "Please check your data before proceeding with the payment",
  date: {
    day: "Day",
    month: "Month",
    year: "Year",
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  recharge: "Phone recharge",
  ourServices: "Our Services",
  whatWe: "What we",
  actuallyDo: "actually do?",
  qrPayments: "QR Payments",
  qrPaymentsDescription:
    "POK allows you to make payments quickly and easily by performing the trivial action of scanning a QR Code.",
  qrPaymentsFirstParagraph:
    "POK allows you to make payments quickly and easily by performing the trivial action of scanning a QR Code. We have worked closely with our customers and invested a lot of resources and research so that our payment solution is secure, fast, and, most importantly, easy to use.",
  qrPaymentsSecondParagraph:
    "Our Pay-by-Scanning option can be used in several scenarios, including Scanning a QR code at the cashier, scanning a fiscal invoice, scanning the QR code of a waiter, scanning a QR code on a bar or restaurant table, or scanning a QR code in e-commerce websites. Furthermore, you can leave a digital tip if you are satisfied with the service.",
  payByLinkTitle: "Pay by Link",
  payByLinkDescription:
    "Are you the owner of an Instagram business, a small business that doesn't have an e-commerce website, or you want to accept payments online for other services you offer your customers?",
  payByLinkParagraph:
    "Are you the owner of an Instagram business, a small business that doesn't have an e-commerce website, or you want to accept payments online for other services you offer your customers? Don't worry; you don't need to invest in software development; you need our Pay-By-Link solution. It is easy to use; All you have to do is log in to your merchant panel, create an invoice, generate the payment link and send it to your customer. We have also prepared detailed video instructions on how to use the feature so that you can start accepting payments today.",
  payByLinkVideoTutorial: "Check Out the Tutorial",
  paymentGatewayTitle: "Payment Gateway",
  paymentGatewayDescription:
    "Are you the owner of an e-commerce website and are looking for a solution that will allow you to start accepting payments online?",
  paymentGatewayParagraph:
    "Are you the owner of an e-commerce website and are looking for a solution that will allow you to start accepting payments online? Look no further. With our Payment Gateway solution, you can quickly start accepting payments online. Our payment gateway can be integrated into your e-commerce website through our REST API, or you can easily install our Woocomerce or Prestashop plugins. In addition, if you have an e-commerce website but don't have access to software developers for the integration, we can offer that service for you as well. All you have to do is create a POK account. Your customers don't need to have a POK account. We can process VISA and MasterCard credit and debit cards as well.",
  paymentGatewayDoc: "Read Full Documentation",
  availableECommercePlugins: "Available E-Commerce Plugins and SDKs",
  availableECommercePluginsDescription:
    "Are you a software developer willing to integrate a payment gateway into a system you are building?",
  phpSdkParagraph:
    "Developing a system and need a payment gateway? Try our REST API. Or, if you're using PHP, our composer package includes a PHP SDK for easy integration. You'll be ready to accept payments from POK users, Visa and Mastercard users in no time.",
  mobileSdkParagraph:
    "Need to accept payments in your mobile app? Our mobile payment SDKs for iOS and Android are the perfect solution. With quick integration and no need to leave your app, our SDKs work seamlessly and won't interfere with your UI.",
  here: "here",
  uktDescription: "Water Supply and Sewerage Utility of Tirana",
  ukdDescription: "Water Supply and Sewerage Utility of Durrës",
  ukvDescription: "Water Supply and Sewerage Utility of Vlorë",
  contractCode: "Contract Code",
  contractCodeText: "Enter your contract code",
  contract: "Contract",
  serialNumber: "Serial number",
  enterSerialNumberText: "Search your invoice by entering the serial number",
  enterContractCodeText: "Search your invoices by entering the contract code",
  invoiceNumber: "Invoice number",
  client: "Client",
  total: "Total",
  invoiceDate: "Invoice date",
  month: "Month",
  contractNumber: "Contract number",
  totalNoVat: "Total without VAT",
  vat: "VAT",
  invoiceValue: "Invoice value",
  interestOverdue: "Interest overdue",
  paidAmount: "Paid amount",
  pay: "Pay",
  details: "Details",
  noInvoices: "There are no invoices to be paid",
  pokServices: "POK Services",
  active: "Active",
  unavailable: "Unavailable",
  underMaintenance: "Under maintenance",
  pokServicesText:
    "We continuously monitor POK and all its services. If there are any performance or service interruptions, an update will be posted here.",
  clientCode: "Client Code",
  amountToBePaid: "Amount to be paid",
  bankCommission: "Komisione bankare",
  serial: "Serial",
  period: "Period",
  pay_with_pok: "Pay with POK",
  view_menu: "View Menu",
  purposeOfUsingPok: "Purpose of using POK",
  sendMoneyInstantly: "One app, all things money",
  sendMoneyInstantlyText:
    "You can send money to your friends instantly without any extra fee. Don't forget to invite your friends to join our growing community.",
  learnMore: "Learn more",
  exploreEasyPayments: "Explore easy payments",
  downloadNow: "Download Now",
  fines: "Fines",
  designedTo: "Everything in one application",
  utilitiesDescription:
    "Make utility payments from your phone in record time and without any cost.",
  ourBestFeaturesText: "With POK, you have everything in one place!",
  ourBestFeaturesSecondText: "Pay your bills with 0 commission.",
  ourBestFeaturesSubText: "More services, more benefits!",
  aboutUsTitle: "Fast, secure, zero commission payments.",
  aboutUsSubTitle:
    "POK is the application that allows you to transfer money in real-time, make payments at a wide network of stores that accept POK payments, make online payments on the largest e-commerce websites in Albania, or pay utility bills.",
  freePositions: "Free positions",
  review: "Review your data",
  postDescription:
    "Our latest news includes updates on POK, new store additions, and career opportunities. Stay informed and discover more about our company's recent developments.",
  comingSoon: 'Coming Soon',
  viewMore: 'View More',
  search: 'Search',
  getPokApp: 'Get Pok App',
  scanTheQRToDownloadTheApp: 'Scan the QR code to download the app',
  orGetADownloadLinkViaSMS: 'or get a download link via SMS',
  clickToDownLoad: 'Click to download'
};

export default en;
