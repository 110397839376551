import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {amountFormatter, dateFormatter, isObjectEmpty, showError} from "@utils/helpers";
import {
  RiBarcodeBoxLine,
  RiBarcodeFill,
  RiCalendarEventLine, RiFileUserLine, RiMedal2Line, RiPhoneLine,
  RiUser4Line,
  RiUserLine
} from "react-icons/ri";
import TplService from "@utils/services/TplService";
import _ from 'lodash'
import {prepareSdkOrderUrl} from "@utils/functions/Tpl";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const SectionTitle = ({ title }) => {
  return <div className="uppercase text-gray-400 font-medium text-sm mt-2">{title}</div>
}

const DetailItem = ({ icon, title, value, ...props }) => {
  return (
    <div {...props}>
      <div className="flex">
        <div className="mr-1 pt-1">
          {icon}
        </div>
        <div className="flex flex-col text-sm">
          <span className="mb-1 text-white text-opacity-70">{title}</span>
          <span className="text-sm text-white text-opacity-50">{value || <i>Not provided</i>}</span>
        </div>
      </div>
    </div>
  )
}

const ReviewData = ({
                      premium,
                      insuranceAgency,
                      billingInfo,
                      vehicleData,
                      ownerData,
                      tplDuration,
                      secondUser,
                      stepBackwards,
                    }) => {
  const { currencyCode, grossAmount } = premium

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation();

  const requestTpl = () => {
    const payload = {
      vehicleId: vehicleData.id,
      plate: vehicleData.plate,
      vin: vehicleData.vin,
      insuranceAgency,
      startDate: tplDuration.startDate,
      billingInfo,
      nid: ownerData.nid,
      ownerPhone: ownerData.phone,
      ownerEmail: ownerData.email,
    }

    if (!isObjectEmpty(secondUser)) payload.secondUser = secondUser

    setIsLoading(true)

    TplService.requestTpl(payload)
      .then((response) => {
        const confirmUrl = _.get(response, 'data.data.sdkOrder._self.confirmUrl', null)

        if (confirmUrl) {
          const url = prepareSdkOrderUrl(confirmUrl, billingInfo, ownerData)
          window.location.assign(url)
        }
      }).catch((err) => showError(err.response.data.message))
      .finally(() => setIsLoading(false))
  }

  return <div
    className="flex flex-col px-5 rounded-lg shadow">
    <div className="mb-2">
      <div className="text-center text-white text-base">{t('tplAmount')}</div>
      <div className="font-semibold text-2xl text-center text-white">{amountFormatter(grossAmount, currencyCode)}</div>
    </div>
    <div className="px-5 md:px-10">
      <SectionTitle title={t('vehicleData')}/>
      <div className="flex my-3">
        <DetailItem
          icon={<RiBarcodeBoxLine className="text-brand-light-blue"/>}
          title={t('plateNumber')}
          value={vehicleData?.plate}
          className="flex-1"
        />
        <DetailItem
          icon={<RiBarcodeFill className="text-brand-light-blue"/>}
          title={t('vinNumber')}
          value={vehicleData?.vin}
          className="flex-1"
        />
      </div>
      <SectionTitle title={t('tplInsurance')}/>
      <div className="flex my-3">
        <DetailItem
          icon={<RiCalendarEventLine className="text-brand-light-blue"/>}
          title={t('startDate')}
          value={dateFormatter(tplDuration?.startDate, 'DD/MM/YYYY')}
          className="flex-1"
        />
        <DetailItem
          icon={<RiCalendarEventLine className="text-brand-light-blue"/>}
          title={t('endDate')}
          value={tplDuration?.endDate}
          className="flex-1"
        />
      </div>
      <SectionTitle title={t('ownerData')}/>
      <div className="flex my-3">
        <DetailItem
          icon={<RiMedal2Line className="text-brand-light-blue"/>}
          title={t('type')}
          value={vehicleData?.isOwnedByBusiness ? t('business') : t('person')}
          className="flex-1"
        />
        <DetailItem
          icon={<RiUserLine className="text-brand-light-blue"/>}
          title={t('fullName')}
          value={`${vehicleData?.ownerFirstName} ${vehicleData?.ownerLastName}`}
          className="flex-1"
        />
      </div>
      <div className="flex my-3">
        <DetailItem
          icon={<RiFileUserLine className="text-brand-light-blue"/>}
          title={t('nid')}
          value={vehicleData?.ownerPersonalIdNumber}
          className="flex-1"
        />
        <DetailItem
          icon={<RiPhoneLine className="text-brand-light-blue"/>}
          title={t('phone')}
          value={ownerData?.phone}
          className="flex-1"
        />
      </div>
      {secondUser.firstName && (
        <>
          <SectionTitle title={t('secondUser')}/>
          <div className="flex my-3">
            <DetailItem
              icon={<RiUserLine className="text-brand-light-blue"/>}
              title={t('fullName')}
              value={`${secondUser?.firstName} ${secondUser?.lastName}`}
              className="flex-1"
            />
            <DetailItem
              icon={<RiFileUserLine className="text-brand-light-blue"/>}
              title={t('nid')}
              value={secondUser?.nid}
              className="flex-1"
            />
          </div>
          <div className="flex my-3">
            <DetailItem
              icon={<RiCalendarEventLine className="text-brand-light-blue"/>}
              title={t('birthday')}
              value={dateFormatter(secondUser?.birthday)}
              className="flex-1"
            />
            <DetailItem
              icon={<RiUser4Line className="text-brand-light-blue"/>}
              title={t('gender')}
              value={t(secondUser?.gender)} className="flex-1"
            />
          </div>
        </>
      )}
    </div>
    <div className="flex justify-end my-5">
      <CPrimaryButton isLoading={isLoading} label={t('toCheckout')} onClick={requestTpl} className="mr-1"/>
    </div>
  </div>
}

export default ReviewData