import React, { useState } from "react";
import { showError } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-collapse";
import _ from "lodash";
import DigicomService from "@utils/services/DigicomService";
import { FaChevronDown } from "react-icons/fa";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const DetailRow = ({ title, value, ...props }) => (
  <div {...props}>
    <div className="flex justify-between items-center pt-3 px-1 text-white opacity-100">
      <span className="pr-3 text-sm">{title}</span>
      <span className="text-sm">{value}</span>
    </div>
  </div>
);

const DigicomInvoice = ({ invoice }) => {
  const [showDetails, setShowDetails] = useState(false);
  const {
    serialNumber,
    contractCode,
    customerName,
    date,
  } = invoice;

  const { t } = useTranslation();
  const toggleDetails = () => setShowDetails((prev) => !prev);

  const requestPayment = () => {
    DigicomService.requestInvoicePayment(
      invoice.contractCode,
      invoice.serialNumber
    )
      .then((response) => {
        const confirmUrl = _.get(
          response,
          "data.data.sdkOrder._self.confirmUrl",
          null
        );
        if (confirmUrl) {
          window.location.assign(confirmUrl);
        }
      })
      .catch((err) => showError(err.response.data.message));
  };

  return (
    <div
      className="flex mb-12 md:mx-10 cursor-pointer justify-around rounded-2xl border border-opacity-50 shadow md:p-8 p-4 utility-checkout-card"
      onClick={toggleDetails}
    >
      <div className="flex flex-col">
        <div className="flex items-center ">
          <div>
            <h1 className="text-sm text-white font-medium leading-5 mx-1 mb-2">
              {date}
            </h1>
            <div className="flex flex-col lg:flex-row font-normal text-white leading-5 text-sm items-start lg:items-center mr-5">
              <span className="mr-1">{t("invoiceNumber")}:</span>
              <span className="mr-1">{serialNumber}</span>
            </div>
            <div className="flex flex-col lg:flex-row text-white font-normal leading-5 text-sm items-start lg:items-center mb-4">
              <span className="mr-1">{t("client")}:</span>
              <span className="mr-1">{customerName}</span>
            </div>
          </div>
          <div className="flex md:self-start">
            <CPrimaryButton
              label={t("next")}
              className="w-20 md:w-40 lg:ml-10"
              onClick={requestPayment}
            />
          </div>
        </div>
        {showDetails && <hr />}
        <Collapse isOpened={showDetails}>
          <div>
            <DetailRow className="mt-1" title={t("invoiceDate")} value={date} />
            <DetailRow title={t("invoiceNumber")} value={serialNumber} />
            <DetailRow
              className="mb-3"
              title={t("client")}
              value={customerName}
            />
            <hr />
            <DetailRow className="mt-1" title={t("month")} value={date} />
            <DetailRow title={t("contractNumber")} value={contractCode} />
          </div>
        </Collapse>
      </div>
      <div
        className="hidden xl:flex cursor-pointer text-white align-middle items-center p-2 justify-center lg:h-8 lg:w-8 h-5 w-5 bg-gray-600 rounded-full ml-2 mt-1.5"
        onClick={toggleDetails}
      >
        <FaChevronDown />
      </div>
    </div>
  );
};

export default DigicomInvoice;
