import React, {useState} from 'react'

const InsuranceItem = ({title, description, icon, onClick, comingSoon}) => {
    const [loaded, setLoaded] = useState(false);

    return <div onClick={onClick} className={`flex px-6 py-3 rounded-md shadow-md my-3 transform border ${comingSoon ? 'border-opacity-20 text-opacity-50' : 'border-opacity-100 cursor-pointer hover:bg-white hover:bg-opacity-10 hover:translate-y-0.5'}`}>
        {!loaded && (
            <div
                style={{ borderTopColor: 'transparent' }}
                className="w-4 h-4 border-2 border-blue-400 rounded-full animate-spin my-auto"
            />
        )}
        <img src={icon} alt={title} className={`mb-3 w-8 my-auto transform transition duration-500 hover:scale-125 ${!loaded ? 'hidden' : ''}`} onLoad={() => setLoaded(true)} />
        <div className="ml-5 mr-3 font-montserrat">
            <span className="text-white text-sm uppercase">{title}</span>
            <p className="text-white text-xs text-opacity-60 font-light">{description}</p>
        </div>
    </div>
}

export default InsuranceItem