import ukt from "@assets/svg/utilities/ukt.svg";
import ukd from "@assets/svg/utilities/ukd.svg";
import ukv from "@assets/svg/utilities/ukv.svg";

export const renderSupplierIcon = (supplier) => {
  switch (supplier) {
    case 'UKT':
      return ukt;
    case 'UKD':
      return ukd;
    default:
      return ukv
  }
}