const searchVehicleValidator = {
  plate: {
    presence: {
      allowEmpty: false,
      message: 'errors.emptyPlate'
    }
  },
  vin: {
    equality: {
      attribute: 'insuranceAgency',
      message: 'errors.emptyVin',
      comparator: (vin, insuranceAgency) => {
        console.log('viin', insuranceAgency === 'ALBSIG' ? true : vin)
        return insuranceAgency === 'ALBSIG' ? true : vin
      }
    }
  },
};

export default searchVehicleValidator;
