const vacantPositionApplicationValidator = {
  name: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  surname: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.notEmpty',
    },
    email: {
      message: 'errors.incorrectEmailFormat'
    }
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: 'errors.notEmpty'
    },
    format: {
      pattern: /^(\+355|0)6[0789]\d{7}$/,
      message: 'errors.incorrectPhoneFormat'
    }
  },
  resumeBase64: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  coverLetterBase64: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
};

export default vacantPositionApplicationValidator;
