import React, { useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import oneTelecom from "@assets/svg/utilities/one.svg";
import { useTranslation } from "react-i18next";
import {
  Step,
  StepProgressBar,
  useStepProgress,
  withStepProgress,
} from "react-stepz";
import SearchNumber from "@components/Utilities/Telephone/SearchNumber";
import SelectRefillAmount from "@components/Utilities/Telephone/SelectRefillAmount";
import CBackButton from "@core/buttons/CBackButton";
import OneTelecomCheckout from "@components/Utilities/Telephone/OneTelecom/OneTelecomCheckout";
import useMouseLight from "@utils/hooks/useMouseLight";

const OneTelecomPage = () => {
  const [phoneNumberAccount, setPhoneNumberAccount] = useState("");
  const [amount, setAmount] = useState("");
  const oneRef = useRef(null);
  useMouseLight(oneRef);

  const { t } = useTranslation();

  const steps = [
    {
      name: "search",
      nextStep: (phoneNumberAccount) => {
        setPhoneNumberAccount(phoneNumberAccount);
        stepForward();
      },
    },
    {
      name: "selectAmount",
      nextStep: (selectedAmount) => {
        setAmount(selectedAmount);
        stepForward();
      },
    },
    {
      name: "reviewData",
    },
  ];

  const { stepForward, stepBackwards } = useStepProgress({
    steps,
    startingStep: 0,
  });

  return (
    <Layout>
      <div ref={oneRef} className='fixed'/>
      <div className="flex justify-center items-center py-32 bg-brand-dark-blue utility-container">
        <div className="relative flex flex-col mx-2 w-full sm:w-1/2 lg:w-2/5 2xl:w-1/3 border border-opacity-20 utility-card">
          <div className="flex flex-col my-8">
            <div>
              <CBackButton className="md:pl-8 pl-4" />
            </div>
            <img src={oneTelecom} alt="OneTelecom" className="h-14" />
          </div>
          <div className="px-10 md:px-15">
            <div className="font-montserrat text-center text-white uppercase font-medium my-md-4">
              {t("recharge")}
            </div>
            <div className="flex justify-center py-5">
              <StepProgressBar
                className="w-4/5 md:w-2/3"
                steps={steps}
              />
            </div>
            <Step step={0}>
              <SearchNumber
                operator="ONE"
                onNextStep={steps[0].nextStep}
                initialState={phoneNumberAccount.phone}
              />
            </Step>
            <Step step={1}>
              <SelectRefillAmount
                operator="ONE"
                onNextStep={steps[1].nextStep}
                onStepBackwards={() => {
                  stepBackwards();
                  setAmount("");
                }}
                initialState={amount}
              />
            </Step>
            <Step step={2}>
              <OneTelecomCheckout
                onStepBackwards={stepBackwards}
                phoneNumberAccount={phoneNumberAccount}
                amount={amount}
              />
            </Step>
          </div>
        </div>
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  );
};

export default withStepProgress(OneTelecomPage);
