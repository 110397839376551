import React, { useRef } from 'react';
import Layout from '@hoc/layout/Layout';
import {useTranslation} from "react-i18next";
import InsuranceItem from "@components/Utilities/Insurances/InsuranceItem";
import TplCars from "@assets/svg/utilities/white/car_crash.svg"
import TravelHealth from "@assets/svg/utilities/white/travel_health.svg"
import Ansig from "@assets/svg/utilities/white/full/ansig.svg"
import {useHistory} from "react-router-dom";
import CBackButton from "@core/buttons/CBackButton";
import useMouseLight from "@utils/hooks/useMouseLight";

const AnsigPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const ansigRef = useRef(null);
  useMouseLight(ansigRef);

  const goToTplPage = () => {
    history.push('/utilities/pay-tpl?agency=ansig');
  }

  return (
    <Layout>
      <div ref={ansigRef} className='fixed'/>
      <div className="insurance-bg flex flex-col items-center bg-brand-dark-blue py-32 px-5 md:px-0">
        <div style={{backgroundImage: 'linear-gradient(299.73deg, rgba(255, 255, 255, 0.1064) 14.42%, rgba(1, 4, 43, 0.0686) 55.09%, rgba(255, 255, 255, 0.14) 100.22%)', 'backdropFilter': 'blur(70px)'}}
             className="relative backdrop-blur-xl border-b border-r border-opacity-20 rounded-4xl md:w-1/2 lg:w-2/5 drop-shadow-lg shadow-2xl">
          <div className="absolute pl-5 pt-5">
            <CBackButton />
          </div>
          <div className="py-15">
            <div className="flex flex-col mb-4">
              <img src={Ansig} alt="Ansig" className="h-13"/>
            </div>
            <div className="flex flex-col items-center">
              <div className="px-5 lg:w-2/3">
                <div className="uppercase text-gray-700 font-medium mt-6 mb-1">{t('insuranceTypes')}</div>
                <InsuranceItem title="TPL" description={t('tplDescription')} icon={TplCars} onClick={goToTplPage}/>
                <InsuranceItem title={t('travelInsurance')} description={t('travelInsuranceDescription')} icon={TravelHealth}
                               comingSoon/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  )
}

export default AnsigPage;
