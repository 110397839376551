import API from "@utils/plugins/API";

const GetAppService = {
  sendDownloadLink: (payload) =>
    API.post("/webpage-phone-numbers", payload),
  downLoadLink: () =>
    API.get("/public/download-link"),
};

export default GetAppService;
