import React, { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import API from "@utils/plugins/API";
import { showError, showMessage } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import useFormErrors from "@utils/hooks/useFormErrors";
import contactUsValidator from "@utils/validators/ContactUsValidator";
import CInput from "@core/inputs/CInput";
import CTextarea from "@core/inputs/CTextarea";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  const sendMessage = () => {
    const errors = validateErrors(
      { firstName, lastName, email, phone, message },
      contactUsValidator
    );
    if (errors) {
      return;
    }
    const fullName = firstName.trim() + " " + lastName.trim();
    setIsLoading(true);
    API.post("/webpage-contacts", { fullName, email, phone, message, token })
      .then((response) => {
        showMessage(response.data.message);
        clearForm();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
        handleReCaptchaVerify();
      });
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const reCaptchaToken = await executeRecaptcha();
    setToken(reCaptchaToken);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <div className="flex flex-col pt-4">
      <div className="flex justify-between sm:space-x-5 sm:flex-row flex-col">
        <CInput
          label={t("firstname")}
          placeholder={t("firstname")}
          id="firstName"
          value={firstName}
          handleInputChange={(value) =>
            clearError("firstName", value, setFirstName)
          }
          error={getError("firstName")}
          className="flex-1"
        />
        <CInput
          label={t("lastname")}
          placeholder={t("lastname")}
          id="lastName"
          value={lastName}
          handleInputChange={(value) =>
            clearError("lastName", value, setLastName)
          }
          error={getError("lastName")}
          className="flex-1 sm:pt-0 pt-5"
        />
      </div>
      <div className="flex justify-between sm:space-x-5 sm:py-6 py-3 sm:flex-row flex-col">
        <CInput
          id="email"
          label={t("email")}
          placeholder={t("email")}
          value={email}
          handleInputChange={(value) => clearError("email", value, setEmail)}
          error={getError("email")}
          className="flex-1"
        />
        <CInput
          id="phone"
          value={phone}
          label={t("phone")}
          placeholder="+355"
          error={getError("phone")}
          handleInputChange={(value) => clearError("phone", value, setPhone)}
          className="flex-1 sm:ml-5 sm:pt-0 pt-5"
        />
      </div>
      <div className="flex-1 py-2">
        <CTextarea
          label={t("message")}
          id="message"
          value={message}
          handleInputChange={(value) =>
            clearError("message", value, setMessage)
          }
          error={getError("message")}
        />
      </div>
      <div className="lg:pt-10 pt-6 flex sm:justify-end justify-start">
        <CPrimaryButton
          label={t("send")}
          onClick={sendMessage}
          className="sm:w-1/3 sm:flex-none flex-1"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ContactForm;
