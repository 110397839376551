import { ADD_APP_STATE, REMOVE_APP_STATE } from '@constants/redux';

export default (state = { isOpen: true }, action) => {
  switch (action.type) {
    case ADD_APP_STATE:
      return {
        ...state,
        ...action.payload
      };
      // return [
      //   ...state,
      //   { isOpen: true, }
      // ];
    case REMOVE_APP_STATE:
      return {};
    default:
      return state;
  }
};
