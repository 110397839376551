import React, { useEffect, useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import Header from "@components/AboutUsPage/Header";
import WhatIsPokSection from "@components/AboutUsPage/WhatIsPokSection";
import CardsSection from "@components/AboutUsPage/CardsSection";
import useMouseLight from "@utils/hooks/useMouseLight";
import GradientTop from "@assets/svg/homePage/gradient_top.svg";
import { useTranslation } from "react-i18next";
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";

const AboutUsPage = () => {
  const { i18n } = useTranslation();
  const [headerSection, setHeaderSection] = useState();
  const [whatIsPokData, setWhatIsPokData] = useState();
  const [cardsData, setCardsData] = useState();
  const aboutUsPageRef = useRef(null);
  useMouseLight(aboutUsPageRef);

  useEffect(async () => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/about-us?locale=${language}&populate=deep`).then(response => {
      const { attributes } = response.data.data;
      setHeaderSection(attributes);
      setWhatIsPokData(attributes);
      setCardsData(_.get(attributes, 'cards', []))
    })
  }, [i18n?.language]);

  return (
    <Layout>
      <div className="flex flex-col items-center bg-brand-dark-blue overflow-hidden 3xl:w-2/3 mx-auto">
        <div className="fixed" ref={aboutUsPageRef}/>
        <img src={GradientTop} alt="" className="absolute xl:-right-0" style={{ top: '-700px' }}/>
        <Header data={headerSection} />
        <WhatIsPokSection data={whatIsPokData} />
        <CardsSection data={cardsData} />
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  );
};

export default AboutUsPage;
