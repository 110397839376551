import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TabRadioButtons = ({
  id,
  initialActive = 0,
  tabs = [],
  children,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(initialActive);
  const { t } = useTranslation();

  return (
    <div {...props}>
      <div className="flex-flex-col-items-center">
        <div
          className={`flex items-center bg-gray-400 text-white h-10 px-1 text-sm rounded-md shadow-sm mx-2`}
        >
          {tabs.map((tab, index) => (
            <span
              key={tab}
              data-testid={`tab-${tab}`}
              className={`text-center flex-1 rounded-md cursor-pointer p-1 transition-all duration-300 ${
                activeTab === index
                  ? "bg-white text-black shadow"
                  : "hover:bg-opacity-70 mx-1"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {t(tab)}
            </span>
          ))}
        </div>
        {Array.isArray(children) && children[activeTab]}
      </div>
    </div>
  );
};

export default TabRadioButtons;
