import React from 'react';
import {useTranslation} from "react-i18next";

const Header = () => {
  const { t } = useTranslation()
  return (
    <div className="our-services-header-bg flex w-full justify-center items-center px-10 pt-32 pb-24 md:px-20 lg:px-40 md:pt-40 md:pb-24">
      <div className="flex flex-col w-full lg:w-2/3 uppercase text-white text-4xl xl:text-6xl font-semibold font-montserrat">
        <div
          className="text-center md:text-left mouse-text">
          {t('whatWe')}
        </div>
        <div
          style={{ textIndent: "50px" }}
          className="text-center md:text-left mouse-text">
          {t('actuallyDo')}
        </div>
      </div>
    </div>
  )
}

export default Header;

