import React from 'react';
import {amountFormatter} from '@utils/helpers';
import {useTranslation} from 'react-i18next';

const DetailRow = ({ title, value }) => (
  <div className="flex justify-between my-2 text-white">
    <span className="text-sm leading-none font-normal">{title}</span>
    <span className="text-sm leading-5 font-medium">{value}</span>
  </div>
);

const InvoiceDetails = ({ invoice, ...props }) => {
  const {
    serialNumber,
    contractCode,
    customerName,
    totalNoVat,
    totalVat,
    totalWithVat,
    totalInterestOverdue,
    amountPayed,
  } = invoice;
  const { t } = useTranslation();
  return (
    <div {...props}>
      <div className="rounded-lg px-3 lg:px-5 py-2 border border-opacity-40">
        <DetailRow title={t('invoiceNumber')} value={serialNumber}/>
        <DetailRow title={t('contractNumber')} value={contractCode}/>
        <DetailRow title={t('client')} value={customerName}/>
      </div>
      <div className="border-b px-1 pt-2">
        <DetailRow title={t('totalNoVat')} value={amountFormatter(totalNoVat, 'ALL')}/>
        <DetailRow title={t('vat')} value={amountFormatter(totalVat, 'ALL')}/>
        <DetailRow title={t('invoiceValue')} value={amountFormatter(totalWithVat, 'ALL')}/>
      </div>
      <div className="px-1 py-2">
        <DetailRow
          title={t('interestOverdue')}
          value={amountFormatter(totalInterestOverdue, 'ALL')}
        />
        <DetailRow
          title={t('paidAmount')}
          value={
            amountPayed > 0 ? (
              <span className="text-green-500">-{amountFormatter(amountPayed, 'ALL')}</span>
            ) : (
              <span>{amountFormatter(amountPayed, 'ALL')}</span>
            )
          }
        />
      </div>
    </div>
  );
};

export default InvoiceDetails;
