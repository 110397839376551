import React, { useCallback, useEffect, useState } from 'react'
import Article from "@components/News/Article";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";

const VacantPositionList = () => {
  const [vacantPositions, setVacantPositions] = useState([]);
  const history = useHistory();
  const { i18n } = useTranslation();

  const getVacancies = useCallback(() => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/vacant-positions?locale=${language}`).then(response => {
      setVacantPositions(_.get(response, 'data.data', {}))
    })
  }, [i18n]);

  useEffect(() => {
    getVacancies();
  }, [getVacancies]);

  return vacantPositions.map((article) =>
    <Article
      newsType={false}
      id={`read-vacant-position-${article.id}`}
      key={article.id}
      type="career"
      date={article?.attributes?.deadline}
      title={article?.attributes?.title}
      description={article?.attributes?.description}
      onClick={() => history.push(`/careers/${article.id}`)}
    />)
}

export default VacantPositionList