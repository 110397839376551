const selectAmountValidator = {
  selectedAmount: {
    presence: {
      allowEmpty: false,
      message: "errors.chooseAmount",
    }
  }
};

export default selectAmountValidator;
