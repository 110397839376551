import React, { useEffect, useRef, useState } from "react";
import '@assets/css/style.css'
import QuestionComponent from "@components/HomePage/FAQ/QuestionComponent";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import useExpand from "@utils/hooks/useExpand";
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const FaqSection = ({ pageSize }) => {
  const [faq, setFaq] = useState([]);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const containerRef = useRef();
  const buttonRef = useRef();
  useExpand(containerRef, null, buttonRef);

  useEffect(() => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/faqs?locale=${language}&pagination[pageSize]=${pageSize}`).then(response => {
      setFaq(_.get(response, 'data.data', []));
    })
  }, [pageSize, i18n.language])

  const viewMore = pageSize < 10

  return (
    <div className="flex w-full justify-center px-5 lg:px-0">
      <div ref={containerRef}
        className={`relative lg:w-2/3 bg-brand-dark-blue faq-component px-6 py-10 md:pt-20 md:px-10 lg:px-15 ${viewMore ? 'pb-30' : 'pb-20'} md:mx-10 lg:mx-5 mt-5`}>
        <div className='lg:mt-3 text-center md:text-left font-montserrat text-4xl lg:text-5xl 2xl:text-7xl text-white font-semibold pb-2 md:pb-8'>
          FAQ
        </div>
        {faq ? faq.map(faq => <QuestionComponent faq={faq?.attributes}/>) : <SkeletonLoader variant='text' count={2} width={600}/> }
        <div className="flex justify-end">
          {viewMore && (
            <div className="absolute bottom-10">
              <CPrimaryButton buttonRef={buttonRef} label={t("viewMore")} onClick={() => history.push('/faq')}/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default FaqSection