import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const Card = ({ icon, title = "", description = "", vacancies = false }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const navigate = () => {
    history.push("/news?selectedTab=career");
  };

  return (
    <div className="about-us-card flex flex-col justify-start rounded-2xl p-8">
      {icon ? <img src={icon} alt="title" className="w-10 h-10"/> :
        <SkeletonLoader variant='circular' width={50} height={50}/>}
      <div className="text-xl my-4 font-semibold font-montserrat">
        {title ? title : <SkeletonLoader variant='text'/>}
      </div>
      <div>{description ? description : <SkeletonLoader variant='text'/>}</div>
      {vacancies && (
        <div onClick={navigate} className="mt-4 underline cursor-pointer">
          {t("vacancies")}
        </div>
      )}
    </div>
  );
};

export default Card;
