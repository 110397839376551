import React, { useEffect, useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import API from "@utils/plugins/API";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Header from "@components/HomePage/Header";
import WhereToPay from "@components/HomePage/WhereToPay";
import AreYouMerchantSection from "@components/HomePage/AreYouMerchantSection";
import UtilitySection from "@components/HomePage/UtilitySection";
import FaqSection from "@components/HomePage/FAQ/FaqSection";
import GradientTop from "@assets/svg/homePage/gradient_top.svg"
import Partners from "@components/HomePage/Partners";
import OrderooSection from "@components/HomePage/OrderooSection";
import PayAndGetPaidSection from "@components/HomePage/PayAndGetPaidSection";
import useMouseLight from "@utils/hooks/useMouseLight";
import PokCardSection from "@components/HomePage/PokCardSection";
import CMSAPI from "@utils/plugins/CMSAPI";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { addHomePage } from "@redux/homepage/Action";

function HomePage() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const headerSection = useSelector((state) =>
    _.get(state, 'homePageReducer', {})
  );
  const orderooSection = useSelector((state) =>
    _.get(state, 'homePageReducer.Orderoo', {})
  );
  const pokCardsSection = useSelector((state) =>
    _.get(state, 'homePageReducer.PokCards', {})
  );
  const [siteKey, setSiteKey] = useState('');
  const homePageRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseLight(homePageRef, sectionRef, 'mouse-light-expanded')

  useEffect(() => {
    API.get(`/public/recaptcha-key`).then(response => {
      const siteKey = response.data.data.siteKey;
      setSiteKey(siteKey);
    })
  }, []);

  useEffect(async () => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/home-page?locale=${language}&populate=deep`).then(response => {
      const { attributes } = response.data.data;
      dispatch(addHomePage(attributes));
    })
  }, [i18n?.language, dispatch]);

  return (
    <Layout>
      <GoogleReCaptchaProvider reCaptchaKey={siteKey} scriptProps={{ async: true }}>
        <div className="flex flex-col items-center">
          <div ref={homePageRef} className='fixed'/>
          <div className="relative">
            <img src={GradientTop} alt="" className="absolute xl:-right-0" style={{ top: '-700px' }}/>
            <Header data={headerSection} sectionRef={sectionRef}/>
            <PayAndGetPaidSection/>
            <AreYouMerchantSection/>
            <UtilitySection/>
            <OrderooSection data={orderooSection}/>
            <WhereToPay/>
            <PokCardSection data={pokCardsSection}/>
            <FaqSection pageSize={6}/>
          </div>
        </div>
        <Partners/>
        <div className="footer-padding"/>
      </GoogleReCaptchaProvider>
    </Layout>
  );
}

export default HomePage;