import React from 'react';
import Routes from '@router/index';
import { Provider } from "react-redux";
import { store } from '@redux/Store';

const App = () => {
  return (
    <Provider store={store}>
      <Routes/>
    </Provider>
  );
};

export default App;
