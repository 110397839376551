import React, {useState} from 'react'
import useFormErrors from '@utils/hooks/useFormErrors';
import {useTranslation} from 'react-i18next';
import searchByContractCode from '@utils/validators/SearchByContractCode';
import {useHistory} from 'react-router-dom';
import UKService from "@utils/services/UKService";
import {showError} from "@utils/helpers";
import CInput from "@core/inputs/CInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const SearchByContractCodeForm = ({ provider }) => {
  const [contractCode, setContractCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { clearError, getError, validateErrors } = useFormErrors();
  const { t } = useTranslation();
  const history = useHistory();

  const search = () => {
    const errors = validateErrors({ contractCode }, searchByContractCode)
    if (errors) return;

    setIsLoading(true);
    UKService.searchByContractCode({ contractCode, ukProvider: provider.toUpperCase() })
      .then((response) => {
        const { invoices } = response.data.data
        history.push( `/utilities/water-supply/pay?provider=${provider}`,{invoices})
      }).catch((err) => showError(err.response.data.message))
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="my-10">
        {/*<p className="text-sm text-gray-400 text-center mb-10">{t('enterContractCodeText')}</p>*/}
      <CInput
        id="contractCode"
        label={t('contractCode')}
        placeholder={t('contractCodeText')}
        value={contractCode}
        handleInputChange={(value) => clearError('contractCode', value, setContractCode)}
        error={getError('contractCode')}
      />
      <div className="flex justify-end mt-5">
          <CPrimaryButton label={t('next')} isLoading={isLoading} onClick={search}/>
      </div>
    </div>
  )
}

export default SearchByContractCodeForm