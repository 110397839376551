const searchBySerialNumber = {
  serialNumber: {
    presence: {
      allowEmpty: false,
      message: 'errors.emptySerialNumber'
    }
  },
};

export default searchBySerialNumber;
