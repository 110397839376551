import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormErrors from "@utils/hooks/useFormErrors";
import CInput from "@core/inputs/CInput";
import searchPhoneNumberValidator from "@utils/validators/SearchPhoneNumberValidator";
import PhoneNumberService from "@utils/services/PhoneNumberService";
import { showError } from "@utils/helpers";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const SearchNumber = ({ operator, onNextStep, initialState }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  const searchNumber = () => {
    const errors = validateErrors({ phoneNumber }, searchPhoneNumberValidator);
    if (errors) return;

    setIsLoading(true);
    PhoneNumberService.search(phoneNumber, operator)
      .then((response) => {
        const { phoneNumberAccount } = response.data.data;
        onNextStep(phoneNumberAccount);
      })
      .catch((err) => showError(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!!initialState) {
      setPhoneNumber(initialState);
    }
  }, [initialState]);

  return (
    <div className="flex flex-col">
      <div className="my-8">
        <CInput
          id="phoneNumber"
          label={t("phoneNumber")}
          placeholder="+355 ..."
          value={phoneNumber}
          handleInputChange={(value) => {
            clearError("phoneNumber");
            setPhoneNumber(value.replace(/[^0-9 +]+/g, ""));
          }}
          error={getError("phoneNumber")}
        />
      </div>
      <div className="flex justify-end mb-10 mt-5">
        <CPrimaryButton
          label={t("next")}
          onClick={searchNumber}
          isLoading={isLoading}
          className="sm:w-1/3 flex w-full"
        />
      </div>
    </div>
  );
};

export default SearchNumber;
