import React, {useCallback, useEffect, useState} from "react";
import {Collapse} from "react-collapse";
import {useTranslation} from "react-i18next";
import CInput from "@core/inputs/CInput";
import useFormErrors from "@utils/hooks/useFormErrors";
import CTextarea from "@core/inputs/CTextarea";
import {showError, showMessage} from "@utils/helpers";
import merchantFormValidator from "@utils/validators/MerchantFormValidator";
import ContactService from "@utils/services/ContactService";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import "@assets/css/style.css";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import {useSelector} from "react-redux";
import _ from "lodash";

const AreYouMerchantSection = () => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [nuis, setNuis] = useState("");
    const [message, setMessage] = useState("");
    const [token, setToken] = useState("");
    const [collapseForm, setCollapseForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const header = useSelector((state) =>
        _.get(state, 'homePageReducer.CreateMerchantAccount', {})
    );

    const {executeRecaptcha} = useGoogleReCaptcha();

    const {t} = useTranslation();
    const {clearError, getError, validateErrors} = useFormErrors();

    const toggleCollapse = () => setCollapseForm((prevState) => !prevState);

    const closeForm = () => {
        setCollapseForm(false);
        setFullName("");
        setEmail("");
        setPhoneNumber("");
        setBusinessName("");
        setNuis("");
        setMessage("");
    };

    const sendMessage = () => {
        const payload = {
            fullName,
            email,
            phoneNumber,
            businessName,
            nuis,
            message,
            token,
        };
        const errors = validateErrors(payload, merchantFormValidator);
        if (errors) {
            return;
        }
        ContactService.sendMerchantMessage(payload)
            .then((response) => {
                showMessage(response.data.message);
                closeForm();
            })
            .catch((err) => showError(err.response.data.message))
            .finally(() => {
                setIsLoading(false);
                handleReCaptchaVerify();
            });
    };

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }
        const reCaptchaToken = await executeRecaptcha();
        setToken(reCaptchaToken);
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return (
        <div className="flex flex-col w-full justify-center items-center px-5 lg:px-0 md:pr-6 pt-10 pb-10 lg:pr-5 xl:py-15">
            <div
                className={`gradient-container rounded-4xl ease-in-out transition ${
                    collapseForm ? "" : "bounce"
                } relative overflow-hidden md:w-2/3`}
            >
                <div
                    className="flex md:flex-row z-1 flex-col items-center cursor-pointer md:p-14 sm:p-10 p-8"
                    onClick={toggleCollapse}
                >
                    <div
                        className="w-full lg:text-3xl text-xl md:text-left text-center font-semibold md:mr-10 font-montserrat">
                        {header?.title}
                    </div>
                    <div className="lg:text-xl text-lg opacity-70 text-justify md:pt-0 pt-2">
                        {header?.description}
                    </div>
                </div>
                <Collapse isOpened={collapseForm}>
                    <div className="md:px-20 px-10 pb-10">
                        <div className="flex sm:flex-row flex-col justify-between">
                            <CInput
                                id="fullName"
                                value={fullName}
                                label={t("fullName")}
                                placeholder={t("fullName")}
                                error={getError("fullName")}
                                handleInputChange={(value) =>
                                    clearError("fullName", value, setFullName)
                                }
                                className="flex-1 sm:mr-5 sm:mb-0 mb-4"
                            />
                            <CInput
                                id="phoneNumber"
                                value={phoneNumber}
                                label={t("phoneNumber")}
                                placeholder="+355"
                                error={getError("phoneNumber")}
                                handleInputChange={(value) =>
                                    clearError("phoneNumber", value, setPhoneNumber)
                                }
                                className="flex-1 sm:ml-5"
                            />
                        </div>
                        <CInput
                            id="email"
                            value={email}
                            label="Email"
                            placeholder="Email"
                            error={getError("email")}
                            handleInputChange={(value) =>
                                clearError("email", value, setEmail)
                            }
                            className="my-4"
                        />
                        <div className="flex sm:flex-row flex-col justify-between">
                            <CInput
                                id="businessName"
                                value={businessName}
                                label={t("businessName")}
                                placeholder={t("businessNamePlaceholder")}
                                error={getError("businessName")}
                                handleInputChange={(value) =>
                                    clearError("businessName", value, setBusinessName)
                                }
                                className="flex-1 sm:mr-5 sm:mb-0 mb-4"
                            />
                            <CInput
                                id="nuis"
                                value={nuis}
                                label={t("nuis")}
                                placeholder={t("businessNuis")}
                                error={getError("nuis")}
                                handleInputChange={(value) =>
                                    clearError("nuis", value, setNuis)
                                }
                                className="flex-1 sm:ml-5"
                            />
                        </div>
                        <CTextarea
                            id="message"
                            value={message}
                            label={t("shortMessage")}
                            placeholder={t("message")}
                            error={getError("message")}
                            handleInputChange={(value) =>
                                clearError("message", value, setMessage)
                            }
                            rows={5}
                            className="my-4"
                        />
                        <div className="flex">
                            <CPrimaryButton
                                label={t("close")}
                                onClick={closeForm}
                                className="mr-2 flex-1"
                                isLoading={isLoading}
                            />
                            <CPrimaryButton
                                label={t("send")}
                                onClick={sendMessage}
                                className="ml-2 flex-1"
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default AreYouMerchantSection;
