import React, {useCallback, useEffect, useState} from 'react'
import Article from "@components/News/Article";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";

const NewsList = () => {
  const [news, setNews] = useState([]);

  const history = useHistory();
  const { i18n } = useTranslation();

  const getNews = useCallback(() => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/news?locale=${language}&sort=date:DESC&populate=deep`).then((response) => {
      setNews(_.get(response, 'data.data', []));
    })
  }, [i18n.language]);


  useEffect(() => {
    getNews();
  }, [getNews]);

  return news.map((article) =>
    <Article
      type="news"
      key={article.id}
      imageSrc={article.attributes?.photoUrl}
      date={article.attributes?.date}
      title={article.attributes?.title}
      description={article.attributes?.description}
      onClick={() => history.push(`/news/${article.id}`)}
      id={`read-news-${article.id}`}
    />)
}

export default NewsList