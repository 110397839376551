import React from "react";
import { useTranslation } from "react-i18next";
import InvoiceDetails from "@components/Utilities/WaterSupply/InvoiceDetails";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const MobileInvoice = ({ invoice, requestPayment }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-2xl border border-opacity-50 shadow utility-checkout-card bg-brand-dark-blue my-3 px-4 pb-4 text-white">
      <div className="flex flex-col mt-5 mb-2">
        <h1 className="text-sm font-medium leading-5">{invoice.monthHuman}</h1>
      </div>
      <InvoiceDetails invoice={invoice} />
      <div className="flex mr-4 w-full">
        <CPrimaryButton
          label={t("pay")}
          onClick={requestPayment}
          className="flex-1"
        />
      </div>
    </div>
  );
};

export default MobileInvoice;
