import { combineReducers } from 'redux';
import homePageReducer from 'redux/homepage/Reducer';
import appStateReducer from 'redux/appState/Reducer';

const Reducers = combineReducers({
  homePageReducer,
  appStateReducer,
});

export default Reducers;
