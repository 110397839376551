import React, { useEffect, useRef, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import Lottie from "react-lottie";
import Spinner from "@assets/animations/spinner";
import Success from "@assets/animations/success";
import Fail from "@assets/animations/fail";
import API from "@utils/plugins/API";
import AppStore from "@assets/svg/apple_store.svg";
import GooglePlay from "@assets/svg/google_play.svg";
import POKLogo from "@assets/svg/POK.svg";
import useMouseLight from "@utils/hooks/useMouseLight";

const AccountVerification = () => {
  const {id} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const accountVerificationRef = useRef(null);
  useMouseLight(accountVerificationRef);

  useEffect(() => {
    API.get(`/verify-email/${id}`).then(response => {
      setLoading(false);
      setSuccess(true);
      setMessage(response.data.message);
    }).catch(err => {
      setLoading(false);
      setSuccess(false);
      setMessage(err.response.data.message)
    })
  }, [id]);

  return (
    <div ref={accountVerificationRef} className="flex flex-col min-h-screen justify-center py-5">
      <img
        src={POKLogo}
        width={125}
        className="justify-self-center mx-auto mb-15 cursor-pointer"
        onClick={() => history.push('/')}
        alt=''
      />
      <Lottie
        isClickToPauseDisabled
        ariaRole="div"
        options={{
          loop: loading,
          autoplay: true,
          animationData: loading ? Spinner : success ? Success : Fail,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={200}
        width={200}
      />
      <div className="text-center text-2xl font-bold my-8 text-white font-montserrat">{message}</div>
      <div className="flex flex-row justify-center">
        <img src={AppStore} className="mr-5" alt='' />
        <img src={GooglePlay} alt='' />
      </div>
    </div>
  );
};

export default AccountVerification;
