import React, {useCallback, useState} from 'react'
import CInput from "@core/inputs/CInput";
import FileInput from "@core/inputs/FileInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import Modal from "@core/modal/Modal";
import {fileToBase64, showError, showMessage} from "@utils/helpers";
import useFormErrors from "@utils/hooks/useFormErrors";
import {useTranslation} from "react-i18next";
import vacantPositionApplicationValidator from "@utils/validators/VacantPositionApplicationValidator";
import CMSAPI from "@utils/plugins/CMSAPI";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

const ApplyModal = ({positionId, isOpen, setOpenModal, onSuccess}) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [githubUrl, setGithubUrl] = useState('');
    const [resume, setResume] = useState({});
    const [coverLetter, setCoverLetter] = useState({});
    const [resumeBase64, setResumeBase64] = useState(undefined);
    const [coverLetterBase64, setCoverLetterBase64] = useState(undefined);
    const [token, setToken] = useState('');
    const [generateToken, setGenerateToken] = useState(true);

    const {t} = useTranslation();
    const {clearError, getError, validateErrors} = useFormErrors();

    const handleVerify = useCallback((token) => {
        setToken(token);
        setGenerateToken(false);
    }, []);

    const handleResumeSelect = async (file) => {
        setResume(file || {});
        const base64 = await fileToBase64(file);
        setResumeBase64(base64);
    };

    const handleCoverLetterSelect = async (file) => {
        setCoverLetter(file || {});
        const base64 = await fileToBase64(file);
        setCoverLetterBase64(base64);
    };

    const clearFields = () => {
        setName('')
        setSurname('')
        setGithubUrl('')
        setPhone('')
        setEmail('')
        setResume({})
        setResumeBase64('')
        setCoverLetter({})
        setCoverLetterBase64('')
        setGenerateToken(true)
        setOpenModal(false)
    }

    const sendApplication = () => {
        const verificationPayload = {
            name, surname, email, phone, resumeBase64, coverLetterBase64
        }

        const errors = validateErrors(verificationPayload, vacantPositionApplicationValidator);
        if (errors) return;

        const formDataToSend = new FormData();
        formDataToSend.append('name', name);
        formDataToSend.append('surname', surname);
        formDataToSend.append('email', email);
        formDataToSend.append('phone', phone);
        formDataToSend.append('githubUrl', githubUrl);
        formDataToSend.append('token', token);
        formDataToSend.append('resume', resume);
        formDataToSend.append('coverLetter', coverLetter);
        CMSAPI.post(`/vacant-positions/${positionId}/apply`, formDataToSend).then((res) => {
            clearFields();
            setOpenModal(false);
            if (onSuccess) onSuccess();
            showMessage(res.data.message)
        }).catch(err => {
            showError(err.response.data.message)
        }).finally(() => {
            setGenerateToken(true);
        });
    };

    return <Modal isOpen={isOpen} setOpenModal={setOpenModal} onClose={clearFields}>
        <div className="md:px-10 px-5 pb-10">
            <div className="text-white text-center text-semibold text-3xl uppercase my-6 font-montserrat">About You
            </div>
            <div className="flex sm:flex-row flex-col justify-between">
                <CInput
                    id="name"
                    value={name}
                    label={t("name")}
                    placeholder={t("name")}
                    error={getError("name")}
                    handleInputChange={(value) =>
                        clearError("name", value, setName)
                    }
                    className="flex-1 sm:mr-5 mb-2 md:mb-4"
                />
                <CInput
                    id="surname"
                    value={surname}
                    label={t("surname")}
                    placeholder={t("surname")}
                    error={getError("surname")}
                    handleInputChange={(value) =>
                        clearError("surname", value, setSurname)
                    }
                    className="flex-1 mb-2 md:mb-4"
                />
            </div>
            <div className="flex sm:flex-row flex-col justify-between md:my-4">
                <CInput
                    id="email"
                    value={email}
                    label="Email"
                    placeholder="Email"
                    error={getError("email")}
                    handleInputChange={(value) =>
                        clearError("email", value, setEmail)
                    }
                    className="flex-1 sm:mr-5 mb-2 md:mb-4"
                />
                <CInput
                    id="phone"
                    value={phone}
                    label={t("phone")}
                    placeholder="+355"
                    error={getError("phone")}
                    handleInputChange={(value) =>
                        clearError("phone", value, setPhone)
                    }
                    className="flex-1 mb-2 md:mb-4"
                />
            </div>
            <CInput
                id="githubUrl"
                value={githubUrl}
                label={t("GitHub")}
                placeholder="https://github.com/..."
                error={getError("githubUrl")}
                handleInputChange={(value) =>
                    clearError("githubUrl", value, setGithubUrl)
                }
                className="flex-1 mb-2 md:mb-4"
            />
            <div className="flex flex-col md:flex-row my-2">
                <div className="flex-1">
                    <FileInput
                        title="Resume/CV"
                        error={getError('resumeBase64')}
                        handleFileSelect={handleResumeSelect}
                        fileName={resume?.name || ''}
                        removeError={() => clearError('resumeBase64')}
                    />
                </div>
                <div className="flex-1 md:ml-5">
                    <FileInput
                        title="Cover Letter"
                        error={getError('coverLetterBase64')}
                        handleFileSelect={handleCoverLetterSelect}
                        fileName={coverLetter?.name || ''}
                        removeError={() => clearError('coverLetterBase64')}
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <CPrimaryButton
                    label={t('submit')}
                    onClick={sendApplication}
                    className="w-full md:w-18"
                />
            </div>
            {generateToken && <GoogleReCaptcha onVerify={handleVerify}/>}
        </div>
    </Modal>
}

export default ApplyModal