import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import NewsList from "@components/News/NewsList"
import VacantPositionList from "@components/CareersPage/VacantPositionList";
import { useHistory } from "react-router-dom";
import useMouseLight from "@utils/hooks/useMouseLight";

const Tab = ({ tab, isSelected, onClick, ...props }) => {
  return (
    <div {...props}>
      <div
        onClick={!tab.disabled ? () => onClick(tab) : () => {
        }}
        className={`flex flex-row items-center justify-center rounded-full border border-white my-3 px-5 py-3 w-34 xl:h-16 xl:w-44
        ${isSelected ? 'bg-white text-black' : `text-white bg-brand-dark-blue ${!tab.disabled ? 'hover:bg-white hover:text-black cursor-pointer' : 'text-opacity-50 border-opacity-40'}`}`}
      >
        <span className="text-center uppercase font-montserrat font-semibold">
          {tab.name}
        </span>
      </div>
    </div>
  );
};
const NewsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const url = useMemo(() => new URL(window.location), []);
  const qsTab = url.searchParams.get('selectedTab');
  const newsPageRef = useRef(null);
  useMouseLight(newsPageRef);

  const tabs = useMemo(() => [
    {
      id: 'news',
      name: t('news'),
      title: t("latestNews"),
      description: t('postDescription'),
      content: <NewsList/>
    },
    {
      id: 'career',
      name: t('career'),
      title: t("vacancies"),
      description: t('postDescription'),
      content: <VacantPositionList/>
    },
  ], [t])

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const changeTab = useCallback(
    (tab) => {
      url.searchParams.set('selectedTab', tab.id);
      history.push({
        search: url.search
      });
      setSelectedTab(tab);
    },
    [history, url]
  );

  useEffect(() => {
    const selected = tabs.find((tab) => tab.id === qsTab);
    if (!selected) {
      changeTab(tabs[0]);
    } else {
      setSelectedTab(selected);
    }
  }, [qsTab, changeTab, tabs]);

  return (
    <Layout>
      <div className="flex flex-col items-center bg-brand-dark-blue">
        <div className="fixed" ref={newsPageRef}/>
        <div
          className="lg:h-screen lg:relative flex flex-col lg:flex-row w-full lg:justify-end py-10 md:pt-20 pb-10 md:mb-30 lg:mb-50 xl:mb-20">
          <div
            className="bg-transparent w-full lg:w-1/2 3xl:w-1/3 lg:absolute mt-10 lg:top-26 lg:left-10 flex flex-col align-left justify-center md:px-20 text-center mx-auto">
            <div
              className="text-center lg:text-left font-montserrat text-3xl md:text-4xl lg:text-6xl text-white font-bold pb-5 md:pb-10 uppercase mx-5">
              {selectedTab?.title}
            </div>
            <span className="font-montserrat text-white text-justify font-medium pb-5 mx-5 text-opacity-70">
              {selectedTab?.description}
          </span>
            <div className="flex items-center justify-center lg:justify-start mx-5">
              {
                tabs.map((tab) =>
                  <Tab
                    key={tab.id}
                    tab={tab}
                    isSelected={selectedTab.id === tab.id}
                    className="mr-5"
                    onClick={changeTab}
                  />
                )
              }
            </div>
          </div>
          <div className="w-full md:px-20 lg:px-0 lg:w-1/2 h-full overflow-scroll scrollbar-hidden my-5">
            {selectedTab?.content}
          </div>
        </div>
        <div className="footer-padding"/>
      </div>
    </Layout>
  );
};
export default NewsPage;