import React from "react";
import {dateFormatter} from "@utils/helpers";
import {useTranslation} from "react-i18next";
import moment from "moment";

const Tag = ({content, newsType = true, ...props}) => (
  <div {...props}>
    <div className="group">
      <button
        className={`flex ${newsType ? 'bg-white lg:group-hover:bg-black' : 'news-data'} items-center justify-center rounded-full border-0.5 px-3 h-6 cursor-default`}
      >
        <span
          className={`text-center ${newsType ? 'lg:group-hover:text-white text-black' : 'text-white'} whitespace-nowrap text-xxs md:text-xs uppercase font-montserrat font-semibold`}>
          {content}
        </span>
      </button>
    </div>
  </div>
);

const Article = ({key, type, imageSrc, id, date, title, description, onClick, newsType = true}) => {
  const {t} = useTranslation();

  return (
    <div
      id={id}
      key={key}
      onClick={onClick}
      className={`article-container flex object-cover lg:w-11/12 xl:w-4/5 rounded-4xl border-white mx-3 mb-7 px-1.5 pt-1.5 pb-4 cursor-pointer group bg-brand-dark-blue ${newsType ? 'lg:hover:bg-white lg:hover:-translate-x-4 border-dashed border-1' : 'lg:hover:translate-x-4 border-0.5'} transition duration-500 ease-in-out lg:transform mx-5`}
    >
      <div className="flex flex-col relative">
        {imageSrc && (
          <div className="news-image-container">
            <div className="news-image-container-image"
                 style={{backgroundImage: `url(${imageSrc?.data?.attributes?.url})`}}>
            </div>
          </div>
        )}
        <div className='flex justify-start mx-2 md:mx-5 mb-2 mt-3' style={{zIndex: 2}}>
          <Tag content={type === 'news' ? t('blog') : t('career')} newsType={newsType}/>
          <div className='ml-2'/>
          <Tag content={moment(date).format("D.M.YY")} newsType={newsType}/>
        </div>
        <div className={`flex flex-col md:flex-1 ${!imageSrc && 'mt-15'} mx-2 md:mx-5`}>
          <div
            className={`flex flex-1 text-white text-xl 3xl:text-2xl pb-1 font-bold line-clamp-2 ${newsType && 'lg:group-hover:text-black'}`}>
            {title}
          </div>
          <div
            className={`font-montserrat text-white text-sm 3xl:text-lg text-opacity-70 ${newsType && 'lg:group-hover:text-gray-600'} text-justify pb-5 leading-5 line-clamp-3`}>
            <div dangerouslySetInnerHTML={{__html: description}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;

