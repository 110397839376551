import React, { useRef } from "react";
import '@assets/css/style.css'
import useExpand from "@utils/hooks/useExpand";
import GetAppButton from "@components/GetPokApp/GetAppButton";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const OrderooSection = ({ data }) => {
  const containerRef = useRef();
  const imageRef = useRef();
  const buttonRef = useRef();
  useExpand(containerRef, imageRef, buttonRef);

  return (
    <div className="flex w-full justify-center py-10 lg:pt-26 lg:pb-12 px-5 lg:px-0">
      <div ref={containerRef}
           className={`relative md:w-2/3 bg-brand-dark-blue orderoo-component px-6 py-10 md:px-10 lg:px-5 xl:px-15 xl:py-13`}>
        <div className="flex flex-col-reverse lg:flex-row mouse-text">
          <div className="w-2/3 flex justify-center items-center self-center mt-5 lg:mt-0">
            {data ? <img ref={imageRef} src={data?.image?.data?.attributes?.url} alt="Orderoo" className="shine"/> :
              <SkeletonLoader variant='circular' width={300} height={300}/>}
          </div>
          <div className="flex flex-col w-11/12 3xl:w-1/2 md:mt-0 xl:px-6 text-left md:text-left 3xl:mt-24">
            <div
              className="font-montserrat 3xl:text-5xl md:text-4xl text-2xl font-semibold text-white leading-5 tracking-wide mt-5">
              {data ? data?.title : <SkeletonLoader/>}
            </div>
            <div
              className="font-montserrat 3xl:text-4xl md:text-3xl text-2xl font-semibold text-white leading-5 tracking-wide md:-mt-2 3xl:mt-0">
              {data ? data?.subtitle : <SkeletonLoader variant='text' count={3}/>}
            </div>
            <div
              className="ml-auto font-light text-white font-montserrat mt-5 mb-5 lg:mt-10 lg:mb-16 3xl:mt-20 3xl:mb-30 text-xs md:text-sm 3xl:text-lg">
              {data ? data?.description : <SkeletonLoader variant='text' count={2}/>}
            </div>
            {data ? <GetAppButton buttonRef={buttonRef} label={data?.button}/> :
              <SkeletonLoader variant='text' width={600}/>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default OrderooSection