import React, { useRef, useState } from "react";
import DigicomLogo from "@assets/svg/utilities/white/digicom-logo.svg";
import Layout from "@hoc/layout/Layout";
import TabRadioButtons from "@core/tabs/TabRadioButtons";
import useFormErrors from "@utils/hooks/useFormErrors";
import { useTranslation } from "react-i18next";
import DigicomService from "@utils/services/DigicomService";
import { showError, showMessage } from "@utils/helpers";
import { useHistory } from "react-router-dom";
import searchBySerialNumber from "@utils/validators/SearchBySerialNumber";
import searchByContractCode from "@utils/validators/SearchByContractCode";
import CBackButton from "@core/buttons/CBackButton";
import CInput from "@core/inputs/CInput";
import useMouseLight from "@utils/hooks/useMouseLight";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const DigicomPage = () => {
  const options = ["contract", "serial"];
  const [isLoading, setIsLoading] = useState(false);
  const [contractCode, setContractCode] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const digicomRef = useRef(null);
  useMouseLight(digicomRef);

  const { t } = useTranslation();
  const history = useHistory();
  const { clearError, getError, validateErrors } = useFormErrors();

  const goToInvoicePage = (invoice) => {
    history.push("/utilities/digicom/pay", { invoices: [invoice] });
  };

  const searchUsingContractCode = () => {
    setIsLoading(true);
    const errors = validateErrors({ contractCode }, searchByContractCode);
    if (errors) {
      setIsLoading(false);
      return;
    }

    DigicomService.searchByContractCode(contractCode)
      .then((response) => {
        showMessage(response.data.message);
        const { invoices } = response.data.data;
        goToInvoicePage(invoices);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchUsingSerialNumber = () => {
    setIsLoading(true);
    const errors = validateErrors({ serialNumber }, searchBySerialNumber);
    if (errors) {
      setIsLoading(false);
      return;
    }

    DigicomService.searchBySerialNumber(serialNumber)
      .then((response) => {
        showMessage(response.data.message);
        const { invoice } = response.data.data;
        const invoices = [invoice];
        goToInvoicePage(invoices);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <div ref={digicomRef} className='fixed'/>
      <div className="flex justify-center items-center px-3 md:px-20 py-32 bg-brand-dark-blue utility-container">
        <div
          className="relative flex flex-col mx-2 w-full lg:w-1/2 xl:w-2/5 2xl:w-1/3 border border-opacity-20 utility-card">
          <div className="flex flex-col my-8">
            <div>
              <CBackButton className="md:pl-8 pl-4"/>
            </div>
            <img src={DigicomLogo} alt="OneTelecom" className="h-14"/>
          </div>
          <div className="px-6 md:px-15 ">
            <div className="mx-2 w-full my-8 pb-5 3xl:px-15 rounded-lg">
              <div className="mt-10 w-full">
                <TabRadioButtons tabs={options}>
                  <div className="w-full">
                    <div className="flex flex-col justify-items-start items-start  my-10">
                      <CInput
                        id="contractCode"
                        label={t("contractCode")}
                        placeholder={t("contractCode")}
                        value={contractCode}
                        handleInputChange={(value) =>
                          clearError("contractCode", value, setContractCode)
                        }
                        error={getError("contractCode")}
                        className="mb-1 w-full"
                      />
                    </div>
                    <div className="flex justify-end my-5">
                      <CPrimaryButton
                        isLoading={isLoading}
                        label={t("next")}
                        className="w-40"
                        onClick={() => searchUsingContractCode()}
                      />
                    </div>
                  </div>
                  <>
                    <div className="flex flex-col justify-items-start items-start my-10">
                      <CInput
                        id="serialNumber"
                        label={t("serialNumber")}
                        placeholder={t("serialNumber")}
                        value={serialNumber}
                        handleInputChange={(value) =>
                          clearError("serialNumber", value, setSerialNumber)
                        }
                        error={getError("serialNumber")}
                        className="w-full"
                      />
                    </div>
                    <div className="flex justify-end my-5">
                      <CPrimaryButton
                        label={t("next")}
                        isLoading={isLoading}
                        className="w-40"
                        onClick={() => searchUsingSerialNumber()}
                      />
                    </div>
                  </>
                </TabRadioButtons>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  );
};

export default DigicomPage;