import React from "react";
import Layout from "@hoc/layout/Layout";
import { useTranslation } from "react-i18next";
import "@assets/css/wizard.css";
import Invoice from "@components/Utilities/WaterSupply/Invoice";
import { useLocation } from "react-router-dom";
import InvoiceAnimation from "@assets/animations/invoice.json";
import Lottie from "react-lottie";
import ErrorWrapper from "@hoc/partials/ErrorWrapper";
import ProviderHeader from "@pages/Utilities/WaterSupply/ProviderHeader";
import _ from "lodash";

const allowedProviders = ["ukt", "ukd", "ukv"];

const Invoices = () => {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const provider = url.searchParams.get("provider");

  const location = useLocation();
  const invoices = _.get(location, "state.invoices", []);

  return (
    <Layout>
      <ErrorWrapper
        error={!allowedProviders.includes(provider)}
        width={400}
        className="bg-brand-dark-blue py-32"
      >
        <div className="flex justify-center items-center my-auto py-44 bg-brand-dark-blue utility-container">
          <div className="flex flex-col mx-2 w-full sm:w-1/2 lg:w-2/5 border border-opacity-20 utility-card">
            <ProviderHeader provider={provider} />
            <div className="flex justify-center">
              <div className="mx-2 w-full mb-5">
                {invoices.length ? (
                  invoices.map((invoice) => (
                    <Invoice invoice={invoice} provider={provider} />
                  ))
                ) : (
                  <div className="w-1/2 mt-14 mb-18 mx-auto">
                    <Lottie
                      ariaRole="div"
                      isClickToPauseDisabled
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: InvoiceAnimation,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                        width: 200,
                      }}
                    />
                    <p className="text-center text-gray-400">
                      {t("noInvoices")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-padding bg-brand-dark-blue"/>
      </ErrorWrapper>
    </Layout>
  );
};

export default Invoices;
