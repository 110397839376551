import React, {useState} from 'react'
import Checkout from "@components/Utilities/Telephone/Checkout";
import _ from "lodash";
import {showError} from "@utils/helpers";
import VodafoneService from "@utils/services/VodafoneService";

const VodafoneCheckout = ({ onStepBackwards, phoneNumberAccount, amount }) => {
  const [isLoading, setIsLoading] = useState(false);

  const requestRefill = () => {
    setIsLoading(true);
    VodafoneService.requestRefill(phoneNumberAccount.id, amount)
      .then((response) => {
        const confirmUrl = _.get(
          response,
          "data.data.sdkOrder._self.confirmUrl",
          null
        );

        if (confirmUrl) {
          const url = `${confirmUrl}?phone=${phoneNumberAccount.phone}`;
          window.location.assign(url);
        }
      })
      .catch((err) => showError(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Checkout
      onStepBackwards={onStepBackwards}
      phoneNumberAccount={phoneNumberAccount}
      amount={amount}
      onRefillRequest={requestRefill}
      isLoading={isLoading}
    />
  )
}

export default VodafoneCheckout