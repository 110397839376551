import React, {useCallback, useEffect, useState} from 'react';
import API from "@utils/plugins/API";
import {useParams} from "react-router-dom";
import Logo from '@assets/svg/merchant_logo.svg';
import Bg from '@assets/images/qr-bg1.png';
import XsBg from '@assets/images/xs-qr-bg1.png';
import {useTranslation} from "react-i18next";
import {showError} from '@utils/helpers';
import DownloadButton from "@core/buttons/DownloadButton";
import POKMerchantPdf from 'pok-merchant-pdf';
import 'pok-merchant-pdf/dist/index.css';

const TransactionCode = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [logoBase64, setLogoBase64] = useState();

  const [transactionCode, setTransactionCode] = useState(null);
  const [merchantId, setMerchantId] = useState(null);

  const fetchTransaction = useCallback(() => {
    API.get(`/transaction-codes/${id}/business`)
      .then((response) => {
        setTransactionCode(response.data.data.transactionCode)
        setMerchantId(response.data.data.transactionCode.owner.merchantId)
      })
      .catch(() => {
        window.location.href = '/';
      })
  }, [id])

  useEffect(() => {
    if (merchantId) {
      API.get(`merchants/${merchantId}/qr-logo`).then((res) => {
        const {logo} = res.data.data;
        setLogoBase64(`data:image;base64,${logo}`);
      });
    }
  }, [merchantId]);


  useEffect(() => {
    fetchTransaction()
  }, [fetchTransaction])

  return (<div className='flex place-content-center mt-14 justify-center'>
    {transactionCode && (<div>
      <div className='flex justify-center'>
        <img alt='logo' className='text-center' src={Logo}/>
      </div>
      <div className='mt-6 relative md:mx-18.5 xs:mx-3.5'>
        <picture>
          <source media="(max-width: 639px)" width={348} height={364} srcSet={XsBg}/>
          <source media="(min-width: 640px)" width={776} height={546} srcSet={Bg}/>
          <img
            width={776} height={546}
            className='relative'
            alt='bg'
            src={Bg}
          />
        </picture>
        <div
          className='absolute w-full flex justify-center place-content-center flex-col text-center lg:top-60 sm:top-50 mm:top-35 top-28 xs:px-2'>
          <p className='xs:text-base sm:text-1.5xl text-brand-gray'>{t('merchantQrSubtitle')}</p>
          <p
            className='xs:xl-normal sm:text-5xl text-brand-black xs:font-bold sm:font-black mt-2'>{t('merchantQrTitle')}</p>
          <p className='xs:text-sm sm:text-lg text-brand-gray mt-4'>{t('merchantQrDescription')}</p>
          <div className='flex justify-center mt-6'>
            <POKMerchantPdf
              imageBase64={logoBase64}
              merchantName={transactionCode?.owner?.merchant ? transactionCode?.owner?.merchant?.name : transactionCode?.owner?.name}
              url={transactionCode?._qrData?.url}
              onSuccess={() => {
              }}
              onError={() => {
                showError('Qr Code cannot be downloaded for the moment.');
              }}>
              <DownloadButton/>
            </POKMerchantPdf>
          </div>
        </div>
      </div>
    </div>)}
  </div>)
}

export default TransactionCode;
