import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import CInput from "@core/inputs/CInput";
import useFormErrors from "@utils/hooks/useFormErrors";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import SuccessIcon from "@assets/svg/success.svg";
import {showError} from "@utils/helpers";
import API from "@utils/plugins/API";

const RequestDeletePage = () => {
  const {t} = useTranslation();
  const {clearError, getError, validateErrors} = useFormErrors();

  const contactRef = useRef(null);
  // useMouseLight(contactRef);

  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submit = () => {
    setIsLoading(true)
    API.post('/accounts/request-account-delete', {
      username,
      password,
    }).then(() => {
      setShowSuccess(true)
    }).catch((e) => {
      showError(e?.response?.data?.message || 'Something went wrong');
    }).finally(() => setIsLoading(false));
  }

  return (
    <div>
      <div ref={contactRef} className='fixed'/>
      <div className="contact-us-container sm:px-0 px-5 flex flex-col items-center justify-center min-h-screen py-10">
        <div
          className="relative rounded-3xl w-124 max-w-xl md:rounded-4xl lg:rounded-5xl contact-us-card flex lg:flex-row flex-col justify-center lg:py-14 py-8 px-14">
          {showSuccess ? (
            <div className={'flex flex-col justify-center items-center'}>
              <img src={SuccessIcon} className={'w-24 h-24'}/>
              <div className="text-white uppercase pt-5 text-2xl sm:text-3xl font-montserrat font-medium text-center">
                Submission Successful
              </div>
              <div className="text-white pt-4 opacity-70 text-sm text-center">
                Thank you for submitting your account suspension request. Our team is now reviewing your case. You will
                receive a notification with the outcome shortly. In the meantime, if you have any questions, please feel
                free to contact our support team.
              </div>
            </div>

          ) : (
            <div>
              <div
                className="text-white uppercase text-2xl sm:text-3xl font-montserrat font-medium lg:text-left text-center">
                Request Account Deletion
              </div>
              <div className="text-white pt-5 opacity-70 lg:text-left text-justify text-sm">
                Because we are bound by banking regulations, our staff members must verify that your request is
                compliant with applicable laws before deleting your account.
              </div>
              <div className="py-4">
                <CInput
                  id="username"
                  label={'Username'}
                  placeholder={'Username'}
                  value={username}
                  handleInputChange={(value) => clearError("username", value, setUsername)}
                  error={getError("username")}
                  className="flex-1 my-5"
                />
                <CInput
                  id="password"
                  label={'Password'}
                  placeholder={'Password'}
                  value={password}
                  handleInputChange={(value) => clearError("password", value, setPassword)}
                  error={getError("password")}
                  className="flex-1 my-5"
                />
                <div className={'flex'}>
                  <CPrimaryButton
                    label={'Submit request'}
                    onClick={submit}
                    className="pt-4 mx-auto"
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestDeletePage;
