import React from "react";
import Card from "@components/AboutUsPage/Card";

const CardsSection = ({ data }) => {
  return (
    <div className="relative flex flex-col justify-center items-center w-5/6 xl:w-2/3 text-white my-10 md:my-20 md:pb-20 lg:pb-0">
      <div className="grid md:grid-cols-2 gap-8">
        {data && data?.map((card) => (
          <Card
            icon={card?.icon?.data?.attributes?.url}
            title={card?.title}
            description={card?.description}
            vacancies={card?.vacancies}
          />
        ))}
      </div>
    </div>
  );
};

export default CardsSection;
