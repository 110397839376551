import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";

const Partner = ({ partner, ...props }) => {
  return (
    <a {...props} href={partner.website} target="_blank" rel="noreferrer" className="flex h-full">
      <img
        src={partner?.image?.data?.attributes?.url}
        alt={partner.name}
        className="w-auto object-contain w-40 lg:opacity-90 opacity-100 hover:opacity-100 duration-700"
      />
    </a>
  );
};

const Partners = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    CMSAPI.get(`/partners?populate=deep`).then(response => {
      setPartners(_.get(response, 'data.data', []));
    })
  }, [])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 2560, min: 1024 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 768, min: 500 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1
    }
  };

  return (
    <div className="3xl:mx-auto 3xl:px-60 align-items-center bg-[#000002] md:pb-10 flex mx-auto max-w-[1700px] flex-col xl:pt-10">
      <div className="px-15 py-10 md:pt-24 md:pb-32 xl:pb-16">
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          arrows={false}
          itemClass="xs:px-10 px-3">
          {partners.map((partner, key) => (
            <Partner partner={partner?.attributes} key={key} id={partner?.id}/>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Partners;
