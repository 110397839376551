import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useFormErrors from "@utils/hooks/useFormErrors";
import payTplValidator from "@utils/validators/PayTplValidator";
import Countries from "@utils/data/countries";
import SearchableSelect from "@core/dropdowns/SearchableSelect";
import {RiMapPin2Line} from "react-icons/ri";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import CInput from "@core/inputs/CInput";

const countryOptions = Object.values(Countries).map((item) => {
  return { value: item.flag, label: item.country };
});

const BillingInfo = ({ initialState, onNextStep, stepBackwards }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [administrativeArea, setAdministrativeArea] = useState('')
  const [locality, setLocality] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [address, setAddress] = useState('')

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  useEffect(() => {
    if (initialState) {
      const { firstName, lastName, address1, locality, administrativeArea, postalCode, countryCode } = initialState
      setFirstName(firstName)
      setLastName(lastName)
      setAddress(address1)
      setLocality(locality)
      setAdministrativeArea(administrativeArea)
      setPostalCode(postalCode)
      setCountryCode(countryCode)
    }
  }, [initialState])

  const goForward = () => {
    const billingInfo = {
      firstName,
      lastName,
      countryCode,
      administrativeArea,
      locality,
      postalCode,
      address1: address
    }
    const errors = validateErrors(billingInfo, payTplValidator.billingInfo)
    if (errors) return;

    onNextStep(billingInfo)
  }

  return <div className="flex flex-col px-10 md:px-15 rounded-lg shadow">
    <div className="my-8">
      <div className="flex mb-3">
        <CInput
          id="firstname"
          label={t('firstname')}
          placeholder={t('firstname')}
          value={firstName}
          handleInputChange={(value) => clearError('firstName', value, setFirstName)}
          error={getError('firstName')}
          className="mr-4"
          required
        />
        <CInput
          id="lastname"
          label={t('lastname')}
          placeholder={t('lastname')}
          value={lastName}
          handleInputChange={(value) => clearError('lastName', value, setLastName)}
          error={getError('lastName')}
          required
        />
      </div>
      <SearchableSelect
        label={t('country')}
        icon={<RiMapPin2Line/>}
        value={countryCode}
        placeholder={t('searchCountry')}
        options={countryOptions}
        error={getError('countryCode')}
        onFocus={() => {
          clearError('countryCode')
          clearError('administrativeArea')
        }}
        onSelect={setCountryCode}
        className="mb-3"
        required
      />
      {
        (countryCode === 'US' || countryCode === 'CA') && (
          <CInput
            id="administrativeArea"
            label={t('administrativeArea')}
            placeholder={t('administrativeArea')}
            value={administrativeArea}
            handleInputChange={(value) => clearError('administrativeArea', value, setAdministrativeArea)}
            error={getError('administrativeArea')}
          />
        )
      }
      <div className="flex mb-3">
        <CInput
          id="locality"
          label={t('locality')}
          placeholder={t('locality')}
          value={locality}
          handleInputChange={(value) => clearError('locality', value, setLocality)}
          error={getError('locality')}
          className="mr-4"
          required
        />
        <CInput
          id="postalCode"
          label={t('postalCode') + ` (${t('optional')})`}
          placeholder={t('postalCode')}
          value={postalCode}
          handleInputChange={(value) => clearError('postalCode', value, setPostalCode)}
          error={getError('postalCode')}
        />
      </div>
      <CInput
        id="address"
        label={t('address')}
        placeholder={t('address')}
        value={address}
        handleInputChange={(value) => clearError('address1', value, setAddress)}
        error={getError('address1')}
        className="mb-3"
        required
      />
    </div>
    <div className="flex justify-end mt-5 mb-10">
      <CPrimaryButton label={t('next')} onClick={goForward} className="mr-1"/>
    </div>
  </div>
}

export default BillingInfo