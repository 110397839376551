import React from "react";

const ContactDetails = () => (
  <div className="flex-col uppercase text-sm">
    <div className="pt-1">
      <span className="text-opacity-70 text-white mr-2">
        Tel:
      </span>
      <span className="text-white">
        +355 69 70 47 231
      </span>
    </div>
    <div className="py-1">
      <span className="text-opacity-70 text-white mr-2">
        E-mail:
      </span>
      <span className="text-white">
        support@rpay.ai
      </span>
    </div>
    <div className="text-white">Tirana, Albania</div>
  </div>
);

export default ContactDetails;
