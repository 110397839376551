import React, { useEffect, useRef, useState } from 'react'
import Layout from "@hoc/layout/Layout";
import AboutUsFooter from "@components/AboutUsPage/AboutUsFooter";
import { useTranslation, getI18n } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import samplePDFEn from '@assets/termsAndConditions/terms_and_conditions-en.pdf';
import samplePDFAl from '@assets/termsAndConditions/terms_and_conditions-al.pdf';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Spinner from "@assets/animations/spinner.json";
import Lottie from "react-lottie";
import useMouseLight from "@utils/hooks/useMouseLight";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Loader = () => {
  return (
    <div>
      <Lottie
        ariaRole="div"
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData: Spinner,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
      />
    </div>
  )
}

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const { language } = getI18n();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [samplePDF, setSamplePDF] = useState('');
  const termsAndConditionsRef = useRef(null);
  useMouseLight(termsAndConditionsRef);

  useEffect(() => {
    if (language === 'al') {
      setSamplePDF(samplePDFAl);
    } else {
      setSamplePDF(samplePDFEn);
    }
  }, [language]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handlePrevButton = () => {
    if (pageNumber === 1) {
      return
    }
    setPageNumber((prevState => prevState - 1))
  }

  const handleNextButton = () => {
    if (pageNumber === numPages) {
      return
    }
    setPageNumber((prevState => prevState + 1))
  }

  const nextPrevButtons = () => (
    <>
      <button
        id="terms-and-conditions-prev-page"
        onClick={handlePrevButton}
        disabled={pageNumber === 1}
        type="button"
        title={t('back')}
        className="p-2 bg-gray-700 bg-opacity-80 focus:outline-none disabled:opacity-50 transform hover:-translate-x-0.5 text-white rounded-full"
      >
        <FaChevronLeft className="h-3 w-3"/>
      </button>

      <p className={'px-10 text-white font-montserrat'}>{pageNumber}/{numPages}</p>

      <button
        id="terms-and-conditions-next-page"
        onClick={handleNextButton}
        disabled={pageNumber === numPages}
        type="button"
        title={t('back')}
        className="p-2 bg-gray-700 bg-opacity-80 focus:outline-none disabled:opacity-50 transform hover:-translate-x-0.5 text-white rounded-full"
      >
        <FaChevronRight className="h-3 w-3"/>
      </button>
    </>
  )

  return (
    <div className="flex flex-col">
      <Layout>
        <div className="fixed" ref={termsAndConditionsRef}/>
        <div className="text-white font-montserrat relative">
          <div className="font-medium uppercase pt-32 text-center text-4xl pb-10">{t('termsAndConditions')}</div>
          <div className="whitespace-pre-line lg:px-50 md:px-20 px-0 pt-5 text-dark-blue text-lg">
            <div className={'flex justify-center items-center mb-5 px-10'}>
              {nextPrevButtons()}
            </div>
            <div className="flex justify-center items-center">
              <Document
                file={samplePDF}
                loading={<Loader/>}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
              >
                <Page className={'border'} pageNumber={pageNumber} loading={<Loader/>}/>
              </Document>
            </div>
            <div className={'flex justify-center items-center mt-5 px-10'}>
              {nextPrevButtons()}
            </div>
          </div>
          <hr className={'mt-10'}/>
          <AboutUsFooter/>
        </div>
        <div className="footer-padding"/>
      </Layout>
    </div>
  )
}

export default TermsAndConditions
