import React, {useEffect, useRef} from 'react';
import {AiOutlineClose} from "react-icons/ai";
import {useScrollLock} from "@utils/hooks/useScrollLock";
import useMouseLight from "@utils/hooks/useMouseLight";

const Modal = ({
                   isOpen,
                   setOpenModal,
                   children,
                   maxW = '2xl',
                   onClose,
                   customStyling = 'modal',
                   showCloseButton = true,
               }) => {
    const {unlockScroll, lockScroll} = useScrollLock();

    const close = () => {
        if (setOpenModal) {
            setOpenModal(false);
        }
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        isOpen ? lockScroll() : unlockScroll();
    }, [isOpen, lockScroll, unlockScroll]);

    return (
        isOpen && (
            <div
                className="fixed inset-0 overflow-y-auto md:pt-8 3xl:pt-0 modal-depth"
                aria-labelledby="modal-title"
                role="dialog"
                data-testid="blank-modal"
                aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-3xl transition-opacity cursor-pointer"
                        aria-hidden="true" onClick={close}
                    />
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
          </span>
                    <div
                        className={`modal-depth duration-300 inline-block self-center align-bottom bg-brand-dark-blue rounded-6xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-${maxW} sm:w-full`}>
                        <div className={`${customStyling} modal-depth px-4 pt-5 pb-4 sm:p-6 sm:pb-4 modal-depth`}>
                            {showCloseButton && <div className="flex justify-end">
                                <AiOutlineClose
                                    onClick={close}
                                    className="text-white rounded-full bg-light-gray bg-opacity-50 h-5 p-1 w-5 cursor-pointer transform hover:scale-105"
                                />
                            </div>}
                            <div className="mt-2 justify-center cursor-pointer" data-testid="modal-children">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default Modal;
