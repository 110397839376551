import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormErrors from "@utils/hooks/useFormErrors";
import RoundedCheckbox from "@core/checkbox/RoundedCheckbox";
import selectAmountValidator from "@utils/validators/SelectAmountValidator";
import PhoneNumberService from "@utils/services/PhoneNumberService";
import { showError } from "@utils/helpers";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const AmountRow = ({ amount, onSelect, selectedAmount }) => (
  <div
    className="flex justify-between items-center border-b border-opacity-30 border-gray-100 py-1 px-3 cursor-pointer hover:bg-gray-600 rounded-md"
    onClick={onSelect}
  >
    <div
      className={`${
        selectedAmount === amount && "text-brand-light-blue"
      } font-medium text-white`}
    >
      {amount} Lekë
    </div>
    <RoundedCheckbox isChecked={selectedAmount === amount} value={amount} />
  </div>
);

const SelectRefillAmount = ({
  operator,
  onNextStep,
  onStepBackwards,
  initialState,
}) => {
  const [refillAmounts, setRefillAmounts] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState("");

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  const requestRefill = () => {
    const errors = validateErrors({ selectedAmount }, selectAmountValidator);
    if (errors) return;

    onNextStep(selectedAmount);
  };

  useEffect(() => {
    if (!!initialState) {
      setSelectedAmount(initialState);
    }
  }, [initialState]);

  useEffect(() => {
    PhoneNumberService.getRefillAmounts(operator)
      .then((response) => {
        const { amounts } = response.data.data;
        setRefillAmounts(amounts);
      })
      .catch((err) => showError(err.response.data.message));
  }, [operator]);

  return (
    <div className="flex flex-col my-2">
      <div className="mb-4">
        {refillAmounts.map((amount) => (
          <AmountRow
            key={amount}
            amount={amount}
            selectedAmount={selectedAmount}
            onSelect={() => {
              setSelectedAmount(amount);
              clearError("selectedAmount");
            }}
          />
        ))}
      </div>
      <div className="flex justify-center text-xs text-red-400 mb-8">
        {getError("selectedAmount")}
      </div>
      <div className="flex justify-between mb-10">
        <CPrimaryButton
          label={t("back")}
          onClick={onStepBackwards}
          className="sm:w-1/3 sm:flex-none flex-1 mr-2 sm:md-0"
        />
        <CPrimaryButton
          label={t("next")}
          onClick={requestRefill}
          className="sm:w-1/3 sm:flex-none flex-1"
        />
      </div>
    </div>
  );
};

export default SelectRefillAmount;
