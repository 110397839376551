import React, { useCallback, useState } from 'react'
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import API from "@utils/plugins/API";
import { showError, showMessage } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import CInput from "@core/inputs/CInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import CTextarea from "@core/inputs/CTextarea";
import useFormErrors from "@utils/hooks/useFormErrors";
import disputesValidator from "@utils/validators/DisputesValidator";

const DisputesForm = () => {
  const [sdkOrderId, setSdkOrderId] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [generateToken, setGenerateToken] = useState(true);

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  const disputeTransaction = () => {
    const errors = validateErrors(
      { sdkOrderId, fullName, phone, email, message },
      disputesValidator
    );
    if (errors) {
      return;
    }

    API.post('/webpage-contacts', {
      fullName, phone, email, message, token, sdkOrderId: sdkOrderId ? sdkOrderId : null,
    }).then(response => {
      showMessage(response.data.message);
      setSdkOrderId('');
      setFullName('');
      setPhone('');
      setEmail('');
      setMessage('');
    }).catch((err) => {
      showError(err.response.data.message);
    })
    setGenerateToken(true);
  }

  const handleVerify = useCallback((token) => {
    setToken(token);
    setGenerateToken(false);
  }, []);

  return (
    <div className="flex flex-col pt-4">
      <div className="flex justify-between flex-col">
        <div className="flex justify-between pt-6 sm:flex-row xs:flex-col">
          <div className="flex-1">
            <CInput
              label={t('orderId')}
              placeholder={t('orderId')}
              id="sdkOrderId"
              value={sdkOrderId}
              handleInputChange={(value) =>
                clearError("sdkOrderId", value, setSdkOrderId)
              }
              error={getError("sdkOrderId")}
            />
          </div>
        </div>
        <div className="flex justify-between pt-6 sm:flex-row xs:flex-col">
          <div className="sm:pr-3 flex-1">
            <CInput
              label={t('nameSurname')}
              placeholder={t('nameSurname')}
              id="fullName"
              value={fullName}
              handleInputChange={(value) =>
                clearError("fullName", value, setFullName)
              }
              error={getError("fullName")}
            />
          </div>
          <div className="sm:pl-3 sm:pt-0 xs:pt-6 flex-1">
            <CInput
              label={t('phone')}
              placeholder={t('phone')}
              id="phone"
              value={phone}
              handleInputChange={(value) =>
                clearError("phone", value, setPhone)
              }
              error={getError("phone")}
            />
          </div>
        </div>
        <div className="flex justify-between pt-6 sm:flex-row xs:flex-col">
          <div className="flex-1">
            <CInput
              label={t('email')}
              placeholder={t('email')}
              id="email"
              value={email}
              handleInputChange={(value) =>
                clearError("email", value, setEmail)
              }
              error={getError("fullName")}
            />
          </div>
        </div>
        <div className="flex-1 pt-6">
          <CTextarea
            label={t('description')}
            placeholder={t('description')}
            id="message"
            value={message}
            handleInputChange={(value) =>
              clearError("message", value, setMessage)
            }
            error={getError("message")}
          />
        </div>
        <div className="pt-8 flex xl:justify-end md:justify-start xs:flex-1 md:flex-none">
          <CPrimaryButton
            id="send-contact-us-message"
            label={t('send')}
            rounded="rounded-8xl"
            onClick={disputeTransaction}
            width="md:w-auto xs:w-full"/>
        </div>
        {generateToken && <GoogleReCaptcha onVerify={handleVerify}/>}
      </div>
    </div>
  )
}

export default DisputesForm
