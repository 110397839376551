import React from 'react';
import ReactDOM from 'react-dom';
import '@assets/css/tailwind.css';
import '@assets/css/style.css';
import '@assets/css/unreset.css';
import App from '@core/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import '@i18n/index';
import Notification from 'react-notify-toast';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Notification options={{zIndex: 2000, top: 10}} />
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
