import FaqSection from "@components/HomePage/FAQ/FaqSection";
import Layout from "@hoc/layout/Layout";
import React, { useRef } from "react";
import useMouseLight from "@utils/hooks/useMouseLight";

const FaqPage = () => {
  const faqRef = useRef(null);
  useMouseLight(faqRef);

  return (
    <div className="flex flex-col">
      <Layout>
        <div className="fixed" ref={faqRef}/>
        <div className="flex w-full items-center justify-center py-20 relative">
          <FaqSection pageSize={100}/>
        </div>
        <div className="footer-padding"/>
      </Layout>
    </div>
  )
}
export default FaqPage