import React, { useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import vodafone from "@assets/svg/utilities/vodafone.png";
import { useTranslation } from "react-i18next";
import {
  Step,
  StepProgressBar,
  useStepProgress,
  withStepProgress,
} from "react-stepz";
import SearchNumber from "@components/Utilities/Telephone/SearchNumber";
import SelectRefillAmount from "@components/Utilities/Telephone/SelectRefillAmount";
import CBackButton from "@core/buttons/CBackButton";
import VodafoneCheckout from "@components/Utilities/Telephone/Vodafone/VodafoneCheckout";
import useMouseLight from "@utils/hooks/useMouseLight";

const VodafonePage = () => {
  const [phoneNumberAccount, setPhoneNumberAccount] = useState("");
  const [amount, setAmount] = useState("");
  const vodafoneRef = useRef(null);
  useMouseLight(vodafoneRef);

  const { t } = useTranslation();

  const steps = [
    {
      name: "search",
      nextStep: (phoneNumberAccount) => {
        setPhoneNumberAccount(phoneNumberAccount);
        stepForward();
      },
    },
    {
      name: "selectAmount",
      nextStep: (selectedAmount) => {
        setAmount(selectedAmount);
        stepForward();
      },
    },
    {
      name: "reviewData",
    },
  ];

  const { stepForward, stepBackwards } = useStepProgress({
    steps,
    startingStep: 0,
  });

  return (
    <Layout>
      <div ref={vodafoneRef} className='fixed'/>
      <div className="flex justify-center items-center py-32 bg-brand-dark-blue utility-container">
        <div className="relative flex flex-col mx-2 w-full sm:w-1/2 lg:w-2/5 2xl:w-1/3 border border-opacity-20 utility-card">
          <div className="flex flex-col my-8">
            <div>
              <CBackButton className="md:pl-8 pl-4" />
            </div>
            <div className="flex justify-center">
              <img src={vodafone} alt="Vodafone" className="h-18 object-contain" />
            </div>
          </div>
          <div className="px-8 md:px-15">
            <div className="font-montserrat text-center text-white uppercase font-medium my-4">
              {t("recharge")}
            </div>
            <div className="flex justify-center md:pb-5">
              <StepProgressBar className="md:w-2/3" steps={steps} />
            </div>
            <Step step={0}>
              <SearchNumber
                operator="VODAFONE"
                onNextStep={steps[0].nextStep}
                initialState={phoneNumberAccount.phone}
              />
            </Step>
            <Step step={1}>
              <SelectRefillAmount
                operator="VODAFONE"
                onNextStep={steps[1].nextStep}
                onStepBackwards={() => {
                  stepBackwards();
                  setAmount("");
                }}
                initialState={amount}
              />
            </Step>
            <Step step={2}>
              <VodafoneCheckout
                onStepBackwards={stepBackwards}
                phoneNumberAccount={phoneNumberAccount}
                amount={amount}
              />
            </Step>
          </div>
        </div>
      </div>
      <div className="footer-padding"/>
    </Layout>
  );
};

export default withStepProgress(VodafonePage);
