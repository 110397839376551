import React, { useRef } from "react";
import Layout from "@hoc/layout/Layout";
import { withStepProgress } from "react-stepz";
import "@assets/css/wizard.css";
import ErrorWrapper from "@hoc/partials/ErrorWrapper";
import TabRadioButtons from "@core/tabs/TabRadioButtons";
import SearchByContractCodeForm from "@components/Utilities/WaterSupply/SearchByContractCodeForm";
import SearchBySerialNumberForm from "@components/Utilities/WaterSupply/SearchBySerialNumberForm";
import ProviderHeader from "@pages/Utilities/WaterSupply/ProviderHeader";
import useMouseLight from "@utils/hooks/useMouseLight";

const allowedProviders = ["ukt", "ukd", "ukv"];

const PayWaterSupplyPage = () => {
  const url = new URL(window.location.href);
  const provider = url.searchParams.get("provider");
  const waterSupplyRef = useRef(null);
  useMouseLight(waterSupplyRef);

  return (
    <Layout>
      <ErrorWrapper
        error={!allowedProviders.includes(provider)}
        width={400}
        className="bg-gray-100 py-32"
      >
        <div ref={waterSupplyRef} className='fixed'/>
        <div className="px-5 pt-22 pb-32 3xl:pt-32 bg-brand-dark-blue utility-container flex justify-center">
          <div className="relative utility-card w-full md:w-1/2 lg:w-2/5 2xl:w-1/3">
            <ProviderHeader provider={provider} />
            <div className="flex justify-center">
              <div className="flex flex-col items-center w-full lg:mx-10 md:mx-5 pt-10 pb-5 rounded-lg">
                <TabRadioButtons tabs={["contract", "serialNumber"]} className="w-full px-5">
                  <SearchByContractCodeForm provider={provider} />
                  <SearchBySerialNumberForm provider={provider} />
                </TabRadioButtons>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-padding bg-brand-dark-blue"/>
      </ErrorWrapper>
    </Layout>
  );
};

export default withStepProgress(PayWaterSupplyPage);
