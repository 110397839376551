import React from "react";
import AppStore from "@assets/svg/apple_store.svg";
import PlayStore from "@assets/svg/google_play.svg";
import {useTranslation} from "react-i18next";
import {APPSTORE_URL, PLAYSTORE_URL} from "@utils/data/mobile_app_urls";

const AboutUsFooter = () => {
  const {t} = useTranslation()

  return (
    <div
      className="3xl:max-w-7xl 3xl:mx-auto 3xl:px-0 flex flex-col items-center xs:pt-20 sm:pt-40 xl:pb-37.5 sm:pb-20 xs:pb-8">
      <div className="text-center text-white font-semibold text-base pb-4">
        {t('becomeAMember')}
      </div>
      <div
        className="xl:w-150 md:w-131 sm:w-92 xs:px-6 sm:px-0 xl:text-5xl md:text-xl-normal xs:text-2xl text-center font-inter font-bold xl:tracking-wide pb-10">
        {t('downloadPOK')}
      </div>
      {/*<AppsComingSoon/>*/}
      <div className="flex flex-row pt-4">
        <a id="app-store" href={APPSTORE_URL} className="pr-2" target="_blank" rel="noreferrer">
          <img src={AppStore} alt=""/>
        </a>
        <a id="play-store" href={PLAYSTORE_URL} className="pl-2" target="_blank" rel="noreferrer">
          <img src={PlayStore} alt=""/>
        </a>
      </div>
    </div>
  );
};

export default AboutUsFooter;
