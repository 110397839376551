import React, {useState} from "react";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import GetAppButton from "@components/GetPokApp/GetAppButton";
import {useSelector} from "react-redux";
import _ from "lodash";
import Tilt from "react-parallax-tilt";

const Header = ({data, sectionRef}) => {
    const modalInitialState = useSelector((state) =>
        _.get(state, 'appStateReducer.isOpen', false)
    );
    const [mobileMove, setMobileMove] = useState(!modalInitialState);

    return (
        <div ref={sectionRef} className="relative w-full h-full overflow-hidden">
            <div
                className="flex w-full justify-center items-center px-5 md:px-20 xl:px-26 3xl:px-20 pb-18 md:pb-24 pt-24">
                <div
                    className="flex flex-col items-center md:flex-row xl:w-4/5 [@media(min-width:1510px)]:w-3/4 3xl:w-2/3">
                    <div className="flex-1 xl:pl-5 [@media(min-width:1510px)]:pl-10">
                        <div
                            className={`text-center md:text-left tracking-wide  text-white text-3xl xl:text-5xl 2xl:text-6xl font-semibold font-montserrat xl:w-5/6`}>
                            {data ? data?.title : <SkeletonLoader count={2}/>}
                        </div>
                        <div className="hidden md:block w-2/3">
                            <div
                                className="text-sm 2xl:text-base font-inter font-light text-white text-opacity-70 my-10 w-full">
                                {data ? data?.description : <SkeletonLoader count={2} variant='text'/>}
                            </div>
                            <div className="flex justify-center md:justify-start">
                                {data?.button ? <GetAppButton onOpen={setMobileMove} label={data?.button}
                                                              initialState={modalInitialState}/> :
                                    <SkeletonLoader variant='text' width={400}/>}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center mt-10 xl:pr-10 [@media(min-width:1510px)]:pr-20">
                        <Tilt
                            tiltEnable={mobileMove}
                            className="tilt"
                            tiltMaxAngleX={10}
                            tiltMaxAngleY={30}
                            perspective={800}
                            transitionSpeed={1000}
                            scale={1}
                            glareReverse
                            gyroscope
                        >
                            <div className="iphone">
                                <div className="iphone-14"
                                     style={{backgroundImage: `url(${data?.headerImage?.data?.attributes?.url})`}}>
                                    <div className="dynamic-island"></div>
                                    <div className="dynamic-island-camera"></div>
                                </div>
                                <span
                                    className="absolute -right-0.5 top-34 border-1 border-gray-700 h-12 rounded-md"></span>

                                <span
                                    className="absolute -left-0.5 top-32 border-1 border-gray-700 h-10 rounded-md"></span>
                                <span
                                    className="absolute -left-0.5 top-48 border-1 border-gray-700 h-10 rounded-md"></span>
                            </div>
                        </Tilt>
                        <div className="block md:hidden">
                            <div
                                className="text-justify font-inter font-light text-white text-opacity-70 my-5 w-full 2xl:text-xl"
                            >
                                {data ? data?.description : <SkeletonLoader count={3} variant='text'/>}
                            </div>
                            <div className="flex justify-center md:justify-start">
                                {data?.button ? <GetAppButton onOpen={setMobileMove} label={data?.button}
                                                              initialState={modalInitialState}/> :
                                    <SkeletonLoader variant='text' width={400}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
