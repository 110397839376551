const al = {
  home: "Kreu",
  getStarted: "Shkarko Tani",
  aboutUs: "Rreth Nesh",
  contact: "Kontakt",
  contactUs: "Get in touch",
  contactUsDescription:
    "Na kontaktoni për çdo informacion apo pyetje në lidhje me POK duke plotësuar formularin",
  send: "Dërgo",
  blog: "Blog",
  message: "Mesazhi",
  shortMessage: "Mesazh i shkurtër",
  sendMessage: "Dërgo mesazhin",
  writeYourMessage: "Shkruani mesazhin tuaj...",
  nameSurname: "Emër Mbiemër",
  phone: "Telefon",
  phoneNumber: "Numri i telefonit",
  termsAndConditions: "Kushtet e përdorimit",
  frequentlyAskedQuestions: "Pyetje të shpeshta",
  privacyData: "Të dhëna mbi privatësinë",
  superEasy: "Super e thjeshtë!",
  homeHeaderSubtitle: "TANI MUNDESH",
  homeHeaderTitle: "Pagesa dhe dërgesa të shpejta",
  sendMoneyTitle: "Dërgoni para në kohë reale me POK",
  sendMoneyBody:
    "Ju mund të dergoni para drejt miqve tuaj në kohë reale dhe pa kosto shtesë. Mjafton që edhe miqtë tuaj të kenë një llogari në POK.",
  utilityPaymentsTitle: "Kryeni pagesat utilitare shpejt",
  utilityPaymentsDescription:
    "Ju mund të kryeni pagesat utilitare nga telefoni juaj në kohë rekord dhe pa kosto shtesë. Paguaj faturat e energjise elektrike dhe ujit nga kudo. Paguaj telefoninë, internetin në pak çaste, shmang rradhët e gjata duke kryer çdo veprim nga celulari yt.",
  utilityPaymentsElectricity: "Paguaj faturat e Energjisë Elektrike",
  utilityPaymentsWaterSupply: "Paguaj faturat e Ujit",
  utilityPaymentsElectricityDescription:
    "Paguaj faturat e OSHEE apo ato të ujit në mënyrë shumë të shpejtë dhe të thjeshtë.",
  utilityPaymentsPhoneTitle: "Rimbush telefonin celular",
  utilityPaymentsPhoneDescription:
    "Mund të rimbushni shumë thjeshtë numrin tuaj Vodafone, Albtelecom, One.",
  utilityPaymentsFinesTitle: "Paguaj gjobat",
  utilityPaymentsFinesDescription:
    "Paguani gjobat e policisë rrugore në kohë reale dhe pa kosto.",
  billPaymentsTitle: "Paguaj faturat në dyqane, bar-e dhe restorante",
  billPaymentsSubtitle:
    "Ju vetëm duhet të skanoni Kodin QR në faturë dhe autorizoni pagesën.",
  billPaymentsDescription:
    "Mënyra më e lehtë dhe e shpejtë për të bërë të gjitha blerjet tuaja në vetëm pak sekonda duke skanuar QR kodin e biznesit. POK ju mundëson kryerjen e pagesave në mënyrë të shpejtë dhe të sigurt nepermjet celularit tuaj.",
  aboutUsHeaderTitle:
    "Aplikacioni më i mirë për pagesa të shpejta dhe të sigurta. Mënyra më e mirë për të menaxhuar dhe përdorur paratë tuaja.",
  aboutUsHeaderDescription:
    "Aplikacioni më i mirë për pagesa të shpejta dhe të sigurta. Mënyra më e mirë për të menaxhuar dhe përdorur paratë tuaja.",
  merchant: "Tregtari",
  nuis: "NIPT",
  businessNuis: "NIPT i biznesit",
  operator: "Operator/e",
  appsComingSoon: "Së shpejti në App Store dhe Play Store",
  merchantQrSubtitle: "Space Bar",
  merchantQrTitle: "Shkarko QR Kodin",
  merchantQrDescription:
    "Ju lutemi ta printoni kodin tuaj statik dhe ta vendosni pranë kasës në biznesin tuaj",
  download: "Shkarko",
  somethingWentWrong: "Ndodhi një gabim",
  careerAtPOK: "Karriera në POK",
  careersSubtitle: "Së bashku për një super shërbim",
  careersIntroductionFirst:
    "Ne morëm një nismë për të krijuar një kompani që lehtëson përdorimin e parasë.",
  careersIntroductionSecond:
    "Ne krijuam një zgjidhje unike me qëllim të optimizimit të pagesave.",
  careersIntroductionThird:
    " Për të përmbushur vizionin tonë, ne kemi nevojë që ju të na bashkoheni në gjetjen e mënyrave të reja për zgjidhjen e sfidave tona unike.",
  currentVacancies: "Aktualisht janë {{recordCount}} pozicione të lira pune",
  applicationSuccess: "Aplikuat me sukses",
  vacancies: "Pozicionet e lira",
  boardMembers: "Anëtarët e bordit",
  pokBoard: "Anëtarët e bordit",
  partners: "Partnerët:",
  aboutUsDescriptionFirstParagraph:
    "POK ofron shërbime të pagesave elektronike dhe transfertës së parave dhe përmirëson vazhdimisht shërbimet me qëllim që klientët tanë të kenë zhvillimet më të fundit të teknologjisë në duart e tyre",
  aboutUsDescriptionSecondParagraph:
    "Nëse je biznes i vogël, korporatë, një dyqan apo individ që do të pranojë pagesa në kasë apo online, ne kemi zgjidhjen ideale për ju. Kemi dedikuar shumë orë pune dhe jemi lidhur me partnerë ndërkombëtarë me qëllim që t'ju ofrojmë një zgjidhje sa më të thjeshtë dhe efikase ",
  aboutUsFirstPointTitle: "Ne sjellim inovacion",
  aboutUsFirstPointSubtitle:
    "Departamenti ynë i zhvillimit punon në mënyrë të vazhdueshme për përmirësimin e shërbimeve aktuale dhe shtimin e funksionaliteteve të reja në sistemin tonë",
  aboutUsSecondPointTitle: "Lehtësi në mënyrën e pagesave",
  aboutUsSecondPointSubtitle:
    "Shërbimi ynë i pagesave bën të mundur thjeshtësimin e procesit të pagesave online nëpermjet një sistemi i cili mund të përdoret lehtësisht nga përdoruesit e të gjitha grupmoshave.",
  aboutUsThirdPointTitle: "Komuniteti ynë vazhdon të rritet",
  aboutUsThirdPointSubtitle:
    "Ne shtojmë funksionalitete të reja çdo ditë me qëllim që të përmbushim kërkesën e tregut për sisteme pagesash të shpejta dhe të thjeshta",
  aboutUsFourthPointTitle: "Jemi nje ekip me bazë në Tiranë",
  aboutUsFourthPointSubtitle:
    "Nëse doni të bëheni pjesë e projektit tonë dhe ju intrigon sadopak sektori fintech aplikoni për një nga pozicionet tona të lira",
  news: "Lajme",
  read: "Lexo",
  emptyNews: "Nuk ka asnjë lajm",
  back: "Kthehu",
  latestNews: "Lajmet e fundit",
  emptyArticle: "Artikulli nuk u gjet",
  emptyFaq: "Për momentin nuk ka asnjë pyetje",
  career: "Karriera",
  applicationDeadline: "Data e fundit e aplikimeve:",
  applyForThisPosition: "Apliko për këtë pozicion",
  name: "Emri",
  surname: "Mbiemri",
  email: "Email",
  uploadCV: "Ngarkoni CV tuaj në format PDF",
  uploadCoverLetter: "Ngarkoni një letër motivimi në formatin PDF",
  sendApplication: "Dergo Aplikimin",
  becomeAMember: "BËHU PJESË E KOMUNITETIT TONË",
  downloadPOK: "Shkarkoni POK në telefonin tuaj",
  whereToPayWithPOK: "Ku mund të paguani me POK",
  whereToDescription:
    "Biznese të reja shtohen çdo ditë në familjen tonë të madhe!",
  whereToSubDescription:
    "Përjetoni kënaqësinë e pagesave digjitale me POK dhe shijo blerjet më lehtë se kurrë.",
  areYouMerchant: "Jeni biznes?",
  contactWithUs:
    "Kliko këtu për hapjen e llogarisë suaj për të pranuar pagesa me POK",
  contactUsMerchant: "Kontakto",
  contactForMerchant: "Kontakt për llogari biznesi",
  businessName: "Emër Biznesi",
  businessNamePlaceholder: "Emri i kompanisë/biznesit",
  messageSuccess: "Mesazhi u dërgua me sukses",
  contactSoon: "Ne do t'ju kontakojmë shumë shpejt për hapat e mëtejshëm",
  close: "Mbyll",
  fieldNotEmpty: "Ju lutem plotësoni vendin bosh",
  emailValidation: "Email duhet të jetë në formën: test@test.test",
  phoneValidation: "Numri i telefonit duhet të jetë në formën: +355676767677",
  nuisValidation: "NIPT duhet të jetë në formën: A12345678A",
  scanAndPay: "Skano dhe paguaj",
  downloadPOKApp: "Shkarko aplikacionin POK për pagesa të shpejta",
  disputes: "Kundërshtime",
  disputesDescription:
    "Plotësoni të dhënat për porosinë që doni të kundërshtoni",
  orderId: "ID e porosisë",
  title: "Titulli",
  description: "Përshkrimi",
  plateNumber: "Targa e makinës",
  vinNumber: "Numri i shasisë",
  payTpl: "Paguaj TPL",
  next: "Vazhdo",
  nid: "NID (Numri personal)",
  firstname: "Emri",
  lastname: "Mbiemri",
  address: "Adresa",
  gender: "Gjinia",
  male: "Mashkull",
  female: "Femer",
  skip: "Tejkalo",
  secondUser: "Përdoruesi i dytë (Opsional)",
  secondUserText: "Vendosni të dhënat e përdoruesit të dytë",
  searchVehicle: "Kërko për automjet",
  enterVehicleData: "Vendosni të dhënat e automjetit",
  tplInsurance: "Siguracioni TPL",
  insuranceDuration: "Periudha e siguracionit",
  startDate: "Data e fillimit",
  endDate: "Data e mbarimit",
  birthday: "Ditëlindja",
  insuranceDurationText: "Vendosni datën e fillimit të siguracionit TPL",
  billingInfo: "Adresa e faturimit",
  billingInfoText: "Ju lutem plotësoni fushat e kërkuara",
  locality: "Qyteti",
  administrativeArea: "Rajoni",
  country: "Shteti",
  postalCode: "Kodi ZIP",
  vehicleData: "Të dhënat e mjetit",
  ownerData: "Të dhënat e pronarit",
  optional: "Opsionale",
  errors: {
    required: "Plotësojeni",
    emptyPlate: "Vendosni targën e automjetit",
    emptyVin: "Vendosni numrin e shasisë",
    emptyNid: "Ju lutem vendosni numrin personal",
    invalidNid: "Numri personal nuk është në formatin e duhur",
    emptyPhone: "Ju lutem vendosni numrin e telefonit",
    emptyEmail: "Ju lutem vendosni adresën e E-mail",
    emptyFirstName: "Vendosni emrin e përdoruesit",
    emptyLastName: "Vendosni mbiemrin e përdoruesit",
    emptyFirstAndLastName: "Vendosni emrin dhe mbiemrin e përdoruesit",
    emptyBirthday: "Zgjidhni datën e lindjes",
    selectGender: "Zgjidhni gjininë",
    countryCode: "Shteti nuk mund të jetë bosh",
    requiredState: "Rajoni nuk mund të jetë bosh",
    emptyLocality: "Qyteti nuk mund të jetë bosh",
    emptyAddress: "Ju lutem vendosni adresën",
    incorrectPhoneFormat: "Numri nuk është në formatin e duhur",
    incorrectEmailFormat: "Email nuk është në formatin e duhur",
    chooseAmount: "Ju lutem zgjidhni një shumë rimbushjeje",
    emptyContractCode: "Vendosni kodin e kontratës",
    emptySerialNumber: "Vendosni numrin serial të faturës",
    emptyMessage: "Ju lutem vendosni mesazhin tuaj",
    invalidNuis: "Ky NIPT nuk është i vlefshëm",
    notEmpty: "Nuk mund të jetë bosh",
  },
  fullName: "Emër Mbiemër",
  type: "Tipi",
  utilities: "Utilitete",
  services: "Shërbime",
  waterSupply: "Ujësjellës",
  vehicleFines: "Gjobat e automjetit",
  internet: "Interneti/Telefoni",
  insurances: "Siguracione",
  insuranceTypes: "Tipet e siguracioneve",
  tplDescription: "Sigurimi i autopërgjegjësisë ndaj palëve të treta",
  travelInsurance: "Sigurimi i shëndetit në udhëtim",
  travelInsuranceDescription: "Sigurimi i shëndetit në udhëtim",
  studentInsurance: "Sigurimi i shëndetit në udhëtim",
  albsigMed: "Sigurimi i shëndetit në udhëtim",
  vehicleOwnership: "Automjeti në pronësi të",
  searchCountry: "Kërko Shtetin ...",
  person: "Person",
  business: "Biznes",
  toCheckout: "Vazhdo me pagesën",
  requestedPageNotFound: "Faqja e kërkuar nuk u gjet",
  tplAmount: "Vlera e TPL",
  checkYourData:
    "Ju lutem kontrolloni të dhënat e plotësuara përpara se të vazhdoni me pagesën",
  date: {
    day: "Dita",
    month: "Muaji",
    year: "Viti",
  },
  months: {
    january: "Janar",
    february: "Shkurt",
    march: "Mars",
    april: "Prill",
    may: "Maj",
    june: "Qershor",
    july: "Korrik",
    august: "Gusht",
    september: "Shtator",
    october: "Tetor",
    november: "Nëntor",
    december: "Dhjetor",
  },
  recharge: "Rimbush telefonin",
  ourServices: "Shërbimet tona",
  whatWe: "çfarë shërbimesh",
  actuallyDo: "ofron POK?",
  qrPayments: "QR Payments",
  qrPaymentsDescription:
    "POK ju lejon të bëni pagesa te shpejta dhe me lehtësi duke kryer skanimin e kodit QR.",
  qrPaymentsFirstParagraph:
    "POK ju lejon të bëni pagesa te shpejta dhe me lehtësi duke kryer skanimin e kodit QR. Ne kemi punuar ngushtë me klientët tanë dhe kemi investuar në shumë burime e kërkime në mënyrë që zgjidhja jonë e pagesave të jetë e sigurt, e shpejtë dhe, më e rëndësishmja, e lehtë për t'u përdorur.",
  qrPaymentsSecondParagraph:
    "Opsioni ynë Pay-by-Scanning mund të përdoret në disa skenarë të ndryshëm, duke përfshirë skanimin e një kodi QR në arkë, skanimin e një fature fiskale, skanimin e kodit QR të një kamarieri, skanimin e një kodi QR në një tavolinë bari ose restoranti ose skanimin e një Kodi QR të një biznesi dixhital. Për më tepër, mund të lini një bakshish dixhital nëse jeni të kënaqur me shërbimin.",
  payByLinkTitle: "Si të përdor Pay by Link",
  payByLinkDescription:
    "Keni një biznes të vogël në Instagram që nuk ka një website e-commerce, apo dëshironi të pranoni pagesa online për shërbime të tjera që u ofroni klientëve tuaj ?",
  payByLinkParagraph:
    "A keni një biznes të vogël në Instagram që nuk ka një website e-commerce, apo dëshironi të pranoni pagesa online për shërbime të tjera që u ofroni klientëve tuaj? Nuk është e nevojshme të shqetësoheni, ju nuk keni nevojë të investoni në zhvillimin e softuerit, ju keni nevojë për zgjidhjen tonë Pay-By-Link. Është shumë e lehtë për t'u përdorur. Ajo çfarë ju duhet të bëni është të hyni në panelin tuaj të tregtarit, të krijoni një faturë, të krijoni lidhjen e pagesës dhe t'ia dërgoni klientit tuaj. Ne kemi përgatitur gjithashtu udhëzime të hollësishme me video se si të përdorni funksionin në mënyrë që të mund të filloni të pranoni pagesa që sot.",
  payByLinkVideoTutorial: "Shikoni Tutorialin Ketu",
  paymentGatewayTitle: "Payment Gateway",
  paymentGatewayDescription:
    "Keni nje website e-commerce dhe po kërkoni një zgjidhje që do t'ju lejojë të filloni të pranoni pagesa online ?",
  paymentGatewayParagraph:
    "A keni nje website e-commerce dhe po kërkoni një zgjidhje që do t'ju lejojë të filloni të pranoni pagesa online? Mos kërkoni më tej. Me zgjidhjen tonë të Payment Gateway, mund të filloni shpejt të pranoni pagesa online. Me Payment Gateway ju mund të integroheni në website-in e-commerce përmes REST API, ose mund të instaloni lehtësisht plugin-et tona Woocomerce ose Prestashop. Përveç kësaj, nëse keni një website e-commerce , por nuk keni akses të zhvilluesit të softuerit për integrimin, ne mund ta ofrojmë edhe atë shërbim për ju. Gjithçka që duhet të bëni është të krijoni një llogari POK. Klientët tuaj nuk kanë nevojë të kenë një llogari POK. Ne mund të procesojmë edhe kartat e kreditit dhe të debitit VISA dhe MasterCard.",
  paymentGatewayDoc: "Lexoni Dokumentimin e Plotë",
  availableECommercePlugins:
    "Plugin-et dhe SDK-të e disponueshme për e-commerce",
  availableECommercePluginsDescription:
    "A jeni një zhvillues softuerësh i gatshëm të integroni payment gateway të një sistemi që po ndërtoni apo zhvilluesi i një aplikacioni celular dhe dëshironi të filloni të pranoni pagesa në aplikacion tuaj? ",
  phpSdkParagraph:
    "Po zhvilloni nje aplikacion dhe keni nevoje te pranoni pagesa? Ne kemi zhvilluar REST API për ju që të testoni zgjidhjen tonë të pagesës. Nëse po përdorni PHP, kemi zhvilluar PHP SDK që mund ta përfshini shpejt në projektin tuaj duke përdorur paketën tonë ‘composer package’.",
  mobileSdkParagraph:
    "Ju nevojitet te pranoni pagesa ne aplikacionin tuaj celular? SDK-te tona per iOS dhe Android jane zgjidhja e duhur. të cilat mund të integrohen lehtësisht në aplikacionin tuaj ekzistues dhe mund të konfigurohen brenda një kohe të shkurtër.",
  here: "këtu",
  uktDescription: "Ujësjellës Kanalizime Tiranë",
  ukdDescription: "Ujësjellës Kanalizime Durrës",
  ukvDescription: "Ujësjellës Kanalizime Vlorë",
  contractCode: "Kodi i kontratës",
  contractCodeText: "Vendosni kodin e kontratës",
  serialNumber: "Numër serial",
  enterSerialNumberText:
    "Kërkoni faturën tuaj duke vendosur saktë numrin serial të saj",
  enterContractCodeText:
    "Kërkoni faturat tuaja duke vendosur saktë kodin e kontratës",
  invoiceNumber: "Numri i faturës",
  invoiceDate: "Data e faturës",
  month: "Muaji i faturimit",
  contractNumber: "Numri i kontratës",
  totalNoVat: "Vlera pa TVSH",
  vat: "TVSH",
  invoiceValue: "Vlera e faturës",
  interestOverdue: "Kamatvonesa",
  paidAmount: "Vlera e paguar",
  pay: "Paguaj",
  details: "Detaje",
  noInvoices: "Nuk ka fatura për të paguar",
  clientCode: "Kodi i klientit",
  client: "Klienti",
  amountToBePaid: "Shuma për t'u paguar",
  bankCommission: "Komisione bankare",
  total: "Totali",
  contract: "Kontratë",
  serial: "Serial",
  pokServices: "Shërbimet POK",
  active: "Aktive",
  unavailable: "E padisponueshme",
  underMaintenance: "Në mirëmbajtje",
  pokServicesText:
    "Shërbimet e POK kontrollohen në kohe reale. Cdo problem në performancën e këtyre shërbimeve, do të postohet këtu.",
  period: "Periudha",
  pay_with_pok: "Paguaj me POK",
  view_menu: "Shiko menunë",
  purposeOfUsingPok: "Përse duhet të përdorni POK?",
  sendMoneyInstantly: "Dërgoni para në kohë reale",
  sendMoneyInstantlyText:
    "Ju mund të dergoni para drejt miqve tuaj në kohë reale dhe pa kosto shtesë. Mjafton që edhe miqtë tuaj të kenë një llogari në POK",
  learnMore: "Mëso më shumë",
  exploreEasyPayments: "Zbulo pagesa të lehta",
  downloadNow: "Shkarko Tani",
  fines: "Gjoba",
  utilitiesDescription:
    "Thjeshtësoni jetën tuaj me pagesat e utiliteteve pa stres direkt nga smartphone-i juaj. Paguani faturat tuaja në vetëm 15 sekonda dhe shijoni lehtësinë e transaksioneve të thjeshta dhe të shpejta.",
  ourBestFeaturesText: "Me POK i ke të gjitha në një!",
  ourBestFeaturesSecondText: "Paguaj faturat e tua me 0 komision.",
  ourBestFeaturesSubText: " Shtojmë shërbimet, shtojmë përfitimet!",
  aboutUsTitle: "I shpejtë, i sigurt, pagesa zero komision",
  aboutUsSubTitle:
    "POK është aplikacioni i cili ju lejon të transferoni para në kohë reale, të kryeni pagesa në rrjetin e gjerë te dyqaneve që pranojnë pagesa me POK, të kryeni pagesa online në faqet e-commerce më të mëdha në Shqipëri apo të paguani faturat e utiliteteve.",
  freePositions: "Pozicionet e lira",
  designedTo: "Dizenjuar për t’ju lehtësuar",
  review: "Kontrolloni të dhënat",
  postDescription:
    "Lajmet tona me te reja perfshijne te reja ne POK, dyqanet me te reja, dhe mundesi per karriere.Qendroni te informuar dhe zbuloni me shume per zhvillimet me te reja te kompanise sone.",
  comingSoon: 'Së shpejti',
  viewMore: 'Shiko më shumë',
  search: "Kërko",
  getPokApp: 'Merr aplikacionin POK',
  scanTheQRToDownloadTheApp: 'Skanoni kodin QR për të shkarkuar aplikacionin',
  orGetADownloadLinkViaSMS: 'ose merrni me SMS link-un për t`a shkarkuar',
  clickToDownLoad: 'Kliko për të shkarkuar'
};
export default al;
