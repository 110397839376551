const disputesValidator = {
  sdkOrderId: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  fullName: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: 'errors.notEmpty'
    },
    format: {
      pattern: /^(\+355|0)6[0789]\d{7}$/,
      message: 'errors.incorrectPhoneFormat'
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.notEmpty',
    },
    email: {
      message: 'errors.incorrectEmailFormat'
    }
  },
  message: {
    presence: {
      allowEmpty: false,
      message: "errors.emptyMessage",
    }
  },
};

export default disputesValidator;
