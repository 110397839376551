import React, { useRef } from "react";
import useExpand from "@utils/hooks/useExpand";
import GetAppButton from "@components/GetPokApp/GetAppButton";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const Card = ({ image, title, description, button, reverse = false, width = "w-full lg:w-11/12" }) => {
  const containerRef = useRef();
  const imageRef = useRef();
  const buttonRef = useRef();
  useExpand(containerRef, imageRef, buttonRef);

  return (
    <div
      className="md:w-2/3 3xl:w-3/5 relative card-component px-6 py-10 md:px-10 lg:px-10 3xl:py-20 xl:px-15 justify-center mx-auto"
      ref={containerRef}>
      <div className={`flex ${reverse ? "flex-col-reverse lg:flex-row-reverse" : "flex-col lg:flex-row"}`}>
        <div className="'w-full lg:w-11/12 3xl:w-2/3 flex justify-center items-center self-center mt-5">
          {image ? <img src={image} alt="image" className="shine" ref={imageRef}/> : <SkeletonLoader variant='circular' width={250} height={250}/>}
        </div>
        <div className={`flex flex-col ${width} md:mt-0 xl:mt-6 xl:px-6 text-left md:text-left`}>
          <div
            className="font-montserrat xl:text-3xl md:text-2xl text-xl font-semibold text-white leading-5 tracking-wide mt-5">
            {title ? title : <SkeletonLoader />}
          </div>
          <div
            className={`ml-auto font-light text-white font-montserrat mb-5 lg:mb-26 3xl:mb-35 mt-5 lg:mt-10 text-xs md:text-sm`}>
            {description ? description : <SkeletonLoader count={3} />}
          </div>
          <GetAppButton buttonRef={buttonRef} label={button}/>
        </div>
      </div>
    </div>
  );
}

export default Card;