import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import AboutUsPage from "@pages/AboutUsPage";
import ContactUs from "@pages/ContactUs";
import TransactionCode from "@pages/TransactionCode";
import ScrollToTop from "@utils/ScrollToTop";
import TermsAndConditions from "@pages/TermsAndConditions";
import AccountVerification from "@pages/AccountVerification";
import JobApplication from "@pages/Careers/JobPostPage";
import Deeplink from "@core/Deeplink";
import Disputes from "@pages/Disputes";
import PayTPLPage from "@pages/Utilities/Insurances/Tpl/PayTPLPage";
import AlbsigPage from "@pages/Utilities/Insurances/AlbsigPage";
import AnsigPage from "@pages/Utilities/Insurances/AnsigPage";
import IntersigPage from "@pages/Utilities/Insurances/IntersigPage";
import OurServices from "@pages/OurServices";
import DigicomPage from "@pages/Utilities/Digicom/DigicomPage";
import WaterSupplyProvidersPage from "@pages/Utilities/WaterSupply/WaterSupplyProvidersPage";
import PayWaterSupplyPage from "@pages/Utilities/WaterSupply/PayWaterSupplyPage";
import Invoices from "@pages/Utilities/WaterSupply/Invoices";
import ServiceStatuses from "@pages/ServiceStatuses";
import VodafonePage from "@pages/Utilities/Telephone/VodafonePage";
import OneTelecomPage from "@pages/Utilities/Telephone/OneTelecomPage";
import DigicomInvoices from "@pages/Utilities/Digicom/DigicomInvoices";
import HomePage from "@pages/HomePage";
import NewsPage from "@pages/News/NewsPage";
import NewsArticlePage from "@pages/News/NewsArticlePage";
import FaqPage from "@components/HomePage/FaqPage";
import PayAndGetPayedPage from "@components/MarketplacePages/PayAndGetPayedPage";
import MarketplacePage from "@components/MarketplacePages/MarketplacePage";
import CashbackPage from "@components/MarketplacePages/CashbackPage";
import RequestDelete from "@pages/RequestDelete";

const Routes = () => {
  let Tawk_API = window.Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    let s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/61dd52bff7cf527e84d183d9/1fp48mf3j";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/pay-and-get-paid" component={PayAndGetPayedPage} />
        <Route exact path="/marketplace" component={MarketplacePage} />
        <Route exact path="/cashback" component={CashbackPage} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/news" component={NewsPage} />
        <Route exact path="/news/:id" component={NewsArticlePage} />
        <Route exact path="/verify-email/:id" component={AccountVerification} />
        <Route
          exact
          path="/transaction-codes/:id/business"
          component={TransactionCode}
        />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/careers/:id" component={JobApplication} />
        <Route exact path="/transaction-codes/:id" component={Deeplink} />
        <Route exact path="/transactions/:id" component={Deeplink} />
        <Route exact path="/disputes" component={Disputes} />
        <Route exact path="/orderoo" component={Deeplink} />
        <Route
          exact
          path="/utilities/water-supply"
          component={WaterSupplyProvidersPage}
        />
        <Route
          exact
          path="/utilities/water-supply/search"
          component={PayWaterSupplyPage}
        />
        <Route exact path="/utilities/water-supply/pay" component={Invoices} />
        <Route exact path="/utilities/albsig" component={AlbsigPage} />
        <Route exact path="/utilities/ansig" component={AnsigPage} />
        <Route exact path="/utilities/digicom" component={DigicomPage} />
        <Route
          exact
          path="/utilities/digicom/pay"
          component={DigicomInvoices}
        />
        <Route exact path="/utilities/intersig" component={IntersigPage} />
        <Route exact path="/utilities/vodafone" component={VodafonePage} />
        <Route exact path="/utilities/pay-tpl" component={PayTPLPage} />
        <Route exact path="/utilities/one-telecom" component={OneTelecomPage} />
        <Route exact path="/our-services" component={OurServices} />
        <Route exact path="/services-status" component={ServiceStatuses} />
        <Route exact path="/services-status" component={ServiceStatuses} />
        <Route exact path="/request-account-delete" component={RequestDelete} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
