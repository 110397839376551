const payTplValidator = {
    ownerData: {
        phone: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyPhone'
            },
            format: {
                pattern: /^(\+355|0)6[0789]\d{7}$/,
                message: 'errors.incorrectPhoneFormat'
            }
        },
        email: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyEmail'
            },
            email: {
                message: 'errors.incorrectEmailFormat'
            }
        },
        nid: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyNid'
            },
            format: {
                pattern: /^[A-Z][0-9]\d{7}[A-Z]$/,
                message: 'errors.invalidNid'
            }
        },
    },
    secondUser: {
        firstName: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyFirstName'
            }
        },
        lastName: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyLastName'
            }
        },
        nid: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyNid'
            },
            format: {
                pattern: /^[A-Z][0-9]\d{7}[A-Z]$/,
                message: 'errors.invalidNid'
            }
        },
        birthday: {
            presence: {
                allowEmpty: false,
                message: 'errors.emptyBirthday'
            }
        },
        gender: {
            presence: {
                allowEmpty: false,
                message: 'errors.selectGender'
            }
        }
    },
    duration: {
        startDate: {
            presence: {
                allowEmpty: false,
                message: 'errors.startDate'
            }
        },
    },
    billingInfo: {
        firstName: {
            presence: {
                allowEmpty: false,
                message: 'errors.required'
            }
        },
        lastName: {
            presence: {
                allowEmpty: false,
                message: 'errors.required'
            }
        },
        countryCode: {
            presence: {
                allowEmpty: false,
                message: 'errors.required'
            }
        },
        address1: {
            presence: {
                allowEmpty: false,
                message: 'errors.required'
            }
        },
        locality: {
            presence: {
                allowEmpty: false,
                message: 'errors.required'
            }
        },
        administrativeArea: {
            equality: {
                attribute: 'countryCode',
                message: 'errors.requiredState',
                comparator: (administrativeArea, countryCode) => {
                    return !['US', 'CA'].includes(countryCode) ? true : !!administrativeArea;
                }
            }
        },

    }
};

export default payTplValidator;
