import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {amountFormatter} from "@utils/helpers";
import useFormErrors from "@utils/hooks/useFormErrors";
import payTplValidator from "@utils/validators/PayTplValidator";
import CInput from "@core/inputs/CInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const OwnerInfo = ({
                     premium,
                     owner = "Emer mbiemer",
                     initialState,
                     onNextStep,
                   }) => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [nid, setNid] = useState('');

  const { currencyCode, grossAmount } = premium;

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  useEffect(() => {
    if (initialState) {
      setPhone(initialState.phone)
      setEmail(initialState.email)
      setNid(initialState.nid)
    }
  }, [initialState])

  const goForward = () => {
    const ownerData = { phone, email, nid }

    const errors = validateErrors(ownerData, payTplValidator.ownerData)
    if (errors) return;

    onNextStep(ownerData)
  }

  return <div className="flex flex-col px-10 md:px-15 rounded-lg shadow">
    <div className="flex font-montserrat uppercase mb-10">
      <div className="flex-1 flex-col justify-center border-r">
        <div style={{ fontSize: '9px' }}
             className="flex justify-center text-white font-light">{t('vehicleOwnership')}</div>
        <span className="flex justify-center flex-wrap text-white">{owner}</span>
      </div>
      <div className="flex-1 flex-col justify-center">
        <div style={{ fontSize: '9px' }} className="flex justify-center text-white font-light">{t('tplAmount')}</div>
        <div className="flex justify-center text-white">{amountFormatter(grossAmount, currencyCode)}</div>
      </div>
    </div>
    <CInput
      id="nid"
      label={t('nid')}
      placeholder={t('nid')}
      value={nid}
      handleInputChange={(value) => clearError('nid', value, setNid)}
      error={getError('nid')}
      className="mb-3"
    />
    <CInput
      id="phone"
      label={t('phone')}
      placeholder={t('phone')}
      value={phone}
      handleInputChange={(value) => clearError('phone', value, setPhone)}
      error={getError('phone')}
      className="mb-3"
    />
    <CInput
      id="email"
      label={t('email')}
      placeholder={t('email')}
      value={email}
      handleInputChange={(value) => clearError('email', value, setEmail)}
      error={getError('email')}
      className="mb-3"
    />
    <div className="flex justify-end mt-5 mb-10">
      <CPrimaryButton label={t('next')} onClick={goForward} className="flex w-full md:flex-none md:w-22" />
    </div>
  </div>
}

export default OwnerInfo