import React from "react";

const CInput = ({
  id,
  label,
  placeholder,
  handleInputChange,
  error,
  onFocus,
  value,
  disabled,
  required,
  ...props
}) => {
  return (
    <div {...props}>
      <div
        className={`w-full text-white ${
          error
            ? "text-red-500 text-opacity-100"
            : "focus-within:text-brand-light-blue text-opacity-40 focus-within:text-opacity-100"
        }`}
      >
        {label && (
          <label className="flex w-full font-inter text-xs" htmlFor={id}>
            {label}
            {error && <div className="ml-1">({error})</div>}
          </label>
        )}
        <input
          id={id}
          value={value}
          autoComplete="off"
          className={`border px-4 py-3 text-white text-sm leading-tight placeholder-white
          placeholder-opacity-40 placeholder-text-xs rounded-lg appearance-none bg-transparent w-full focus:outline-none mt-1 
          ${error ? "border-red-700" : "border-white border-opacity-40 focus:border-brand-light-blue"}`}
          onChange={(e) => handleInputChange(e.target.value)}
          onClick={onFocus}
          type="text"
          placeholder={placeholder}
          aria-label={label}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CInput;
