import { useTranslation } from 'react-i18next';
import Lottie from "react-lottie";
import React from "react";

const ErrorWrapper = ({ error, errorText, children, width, ...props }) => {
  const { t } = useTranslation();

  return error ? (
    <div {...props}>
      <div className="grid place-items-center bg-gray-100 py-24 rounded-lg">
        <Lottie
            ariaRole="div"
            isClickToPauseDisabled
            width={width}
            options={{
              loop: true,
              autoplay: true,
              animationData: require('@assets/animations/error.json'),
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
        />
        <h3 className="text-gray-500 text-center my-3">
          {errorText || t('requestedPageNotFound')}
        </h3>
      </div>
    </div>
  ) : (
    children
  );
};

export default ErrorWrapper;
