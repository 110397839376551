import React from 'react';
import uniqid from 'uniqid';

const FileInput = ({
  title = '',
  subtitle = '',
  fileName,
  handleFileSelect,
  error,
  removeError,
}) => {
  const id = uniqid();

  return (
    <div className="my-3">
      <div className="text-sm text-white focus-within:text-brand-light-blue text-opacity-40 focus-within:text-opacity-100">{title}</div>
      <div className="my-1">
        <label
          htmlFor={id}
          className={`flex flex-col items-center justify-center ${error ? 'border-red-500 text-red-500' : 'border-white border-opacity-50 text-white text-opacity-50 hover:border-brand-light-blue hover:text-brand-light-blue'}
                   font-normal focus:outline-none py-6 px-10 border 
                   active:opacity-60 rounded-xl text-lg cursor-pointer`}
        >
          <span>Drag & Drop files</span>
          <span className="text-sm">or</span>
          <span className="text-sm">Browse Files</span>
        </label>
        <div className="text-sm text-brand-gray mt-4">{fileName}</div>
        <input
          className="hidden"
          id={id}
          type="file"
          accept=".pdf"
          onChange={(event) => {
            removeError();
            handleFileSelect(event.target.files[0]);
          }}
        />
      </div>
    </div>
  );
};

export default FileInput;
