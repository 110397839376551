import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CDatePicker from "@core/inputs/CDatePicker";
import moment from "moment";
import payTplValidator from "@utils/validators/PayTplValidator";
import useFormErrors from "@utils/hooks/useFormErrors";
import {Collapse} from 'react-collapse';
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const TplDuration = ({ initialState, stepBackwards, onNextStep }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState()

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  useEffect(() => {
    if (initialState) {
      setStartDate(initialState.startDate)
      setEndDate(initialState.endDate)
    }
  }, [initialState])

  const goForward = () => {
    const errors = validateErrors({ startDate }, payTplValidator.duration)
    if (errors) return;

    onNextStep({ startDate, endDate })
  }

  const changeStartDate = (date) => {
    clearError('startDate', date, setStartDate)
    const newDate = moment(date).add(1, 'y').format('DD/MM/YYYY');
    setEndDate(newDate);
  }

  return <div className="flex flex-col px-10 md:px-15 rounded-lg shadow">
    <div className="my-5">
      <div className="flex flex-col justify-center">
        <label className="w-full font-inter text-xs text-white text-opacity-40 mb-1 mt-2">
          {t('startDate')}
        </label>
        <CDatePicker id="startDate" label={t('startDate')} value={startDate} onChange={changeStartDate}
                     error={getError('startDate')}/>
      </div>
      <Collapse
        isOpened={endDate}
        className="my-3">
        <div className="flex justify-between items-center">
          <label className="w-full font-inter text-xs text-white text-opacity-40 mb-1 mt-2">
            {t('endDate')}
          </label>
          <span className="text-sm text-white">{endDate}</span>
        </div>
      </Collapse>
    </div>
    <div className="flex justify-end mb-10">
      <CPrimaryButton label={t('next')} onClick={goForward} className="flex w-full md:flex-none md:w-22"/>
    </div>
  </div>
}

export default TplDuration