import React, {useCallback, useEffect, useState} from 'react'
import Modal from "@core/modal/Modal";
import {useTranslation} from "react-i18next";
import {QRCode} from "react-qrcode-logo";
import {showError} from "@utils/helpers";
import GetAppService from "@utils/services/GetAppService";
import {APPSTORE_URL, PLAYSTORE_URL} from "@utils/data/mobile_app_urls";
import appleStore from "@assets/svg/apple_store.svg";
import googlePlay from "@assets/svg/google_play.svg";
import logo from "@assets/svg/POK.svg";
import {addAppState} from "@redux/appState/Action";
import {useDispatch} from "react-redux";
import {isAndroid, isIOS, isMobile, isMobileSafari} from "react-device-detect";

const GetPokAppModal = ({isOpen, setOpenModal, onOpen}) => {
    const dispatch = useDispatch();
    const [qr, setQr] = useState('');
    const {t, i18n} = useTranslation();

    const closeModal = () => {
        setOpenModal(false);
        onOpen(true);
        dispatch(addAppState({isOpen: false}))
    }

    useEffect(() => {
        if (isOpen) {
            GetAppService.downLoadLink()
                .then(response => {
                    setQr(response.data.data?.onelinkUrl)
                })
                .catch((err) => showError(err.response.data.message))
        }
    }, [isOpen])

    const redirect = useCallback(() => {
        if (isMobileSafari) {
            window.location.replace("/");
        } else if (isAndroid) {
            window.location = "https://play.google.com/store/apps/details?id=io.pokpay";
        } else if (isIOS) {
            window.location = "https://apps.apple.com/app/id1590835547";
        } else {
            window.location.replace("/");
        }
    }, [])


    return <Modal showCloseButton={false} maxW='xl' customStyling='get-app-modal' isOpen={isOpen}
                  setOpenModal={closeModal}>
        <div className="md:px-10 px-5 pb-11">
            <div className="flex flex-col align-middle justify-center items-center">
                <div
                    className={`font-montserrat ${i18n.language === 'al' ? 'xl:text-3xl md:text-2xl text-xl' : 'xl:text-4xl md:text-3xl text-2xl'} text-white leading-5 font-medium tracking-wider mt-5 uppercase whitespace-nowrap`}>
                    {t('getPokApp')}
                </div>
                <div
                    className={`font-montserrat xl:text-xl md:text-lg ${i18n.language === 'al' ? 'text-sm' : 'text-base '} text-white leading-5 tracking-wide font-light mt-3 mb-7`}>
                    {t('scanTheQRToDownloadTheApp')}
                </div>
                {qr ? <div
                        className="text-white uppercase font-montserrat mt-5 mb-5 lg:my-6">
                        <QRCode value={qr} size={220} fgColor='#000127FF' ecLevel={'H'} quietZone={0}/>
                    </div> :
                    <span
                        className="text-white flex flex-col items-center relative text-center align-center justify-center h-58">
        <div className="animate-bounce">
          <img
              src={logo}
              alt="Pok"
              className="text-white w-40 h-40 animate-pulse relative"
          />
        </div>
      </span>}
                <div onClick={redirect}
                     className="hover:cursor-pointer font-montserrat xl:text-xl md:text-lg text-base text-white leading-5 tracking-wide font-light mt-3">
                    {t('clickToDownLoad')}
                </div>
                <div className="flex justify-between items-center mt-3">
                    <div className="pt-2 flex justify-center">
                        <a
                            href={APPSTORE_URL}
                            className="xs:mr-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={appleStore} alt="appstore" className="h-9"/>
                        </a>
                        <a
                            href={PLAYSTORE_URL}
                            className="xs:ml-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={googlePlay} alt="playstore" className="h-9"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}

export default GetPokAppModal