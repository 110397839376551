import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import { useTranslation } from "react-i18next";
import StatusService from "@utils/services/StatusService";
import ServiceStatusItem from "@components/Statuses/ServiceStatusItem";
import useMouseLight from "@utils/hooks/useMouseLight";

const ServiceStatuses = () => {
  const [services, setServices] = useState([])
  const { t } = useTranslation();
  const serviceStatusPage = useRef(null);
  useMouseLight(serviceStatusPage);

  const getServices = useCallback(() => {
    StatusService.all().then((response) => {
      setServices(response.data.data.services)
    })
  }, [])

  useEffect(() => {
    getServices()
  }, [getServices])

  return (
    <Layout>
      <div ref={serviceStatusPage} className='fixed'/>
      <div className="flex flex-col items-center py-32 px-5 md:px-0 utility-container">
        <div
          className="relative flex flex-col mx-2 w-full sm:w-1/2 lg:w-2/5 border border-opacity-20 utility-card py-10 px-24">
          <div className="text-center text-lg uppercase text-white font-montserrat font-medium my-3">
            {t('pokServices')}
          </div>
          <div className="text-center uppercase text-sm text-white text-opacity-50 font-montserrat mb-6">
            {t('pokServicesText')}
          </div>
          <div>
            {
              services.map((service) => <ServiceStatusItem service={service}/>)
            }
          </div>
        </div>
      </div>
      <div className="footer-padding"/>
    </Layout>
  );
};

export default ServiceStatuses;
