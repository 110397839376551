import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import ReactCardFlip from "react-card-flip";

const ServiceCard = ({ id, title, description, expandedDescription, image }) => {
  const [flipped, setFlipped] = useState(false);
  const [backSideShowing, setBackSideShowing] = useState(false);

  const { i18n } = useTranslation();

  const toggleFlip = (e) => {
    e.preventDefault()
    setFlipped(prev => !prev)
    setTimeout(() => {
      setBackSideShowing(prev => !prev)
    }, 200)
  }

  return (
    <div>
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        <div
          onClick={toggleFlip}
          className={`${backSideShowing ? "hidden" : "" } cursor-pointer service-card-front flex flex-col border border-white border-opacity-40 rounded-xl pr-15 transform 2s ease-in-out hover:border-transparent service-card mouse-text`}>
          <div
            className="text-white flex flex-1 flex-col justify-between py-5 pl-7">
            <div>
              <div className="text-opacity-60 font-semibold">0{id}/</div>
              <div className="py-6 text-lg font-montserrat font-semibold text-xl">{title}</div>
              <div className="font-inter text-xs md:text-sm text-opacity-50 text-justify">{description}</div>
            </div>
            <div className="">
              <img src={image} alt="" className="h-22 mb-5"/>
            </div>
          </div>
        </div>

        <div onClick={toggleFlip} className={`${backSideShowing ? "" : "hidden" } flex flex-col h-full w-full bg-brand-light-blue rounded-xl py-5 pl-7 pr-12 cursor-pointer service-card`}>
          <div className="text-brand-dark-blue font-semibold pb-2">0{id}/</div>
          <div
            className="flex-1 font-inter text-xs md:text-sm text-justify overflow-auto scrollbar-hidden pt-4">
            {expandedDescription}
          </div>
        </div>
      </ReactCardFlip>
    </div>
  )
}

export default ServiceCard