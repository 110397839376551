import React from "react";
import { useTranslation } from "react-i18next";
import { amountFormatter } from "@utils/helpers";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const DetailItem = ({ title, value, ...props }) => (
  <div {...props}>
    <div className="flex justify-between items-center pt-3 px-1 text-white opacity-100">
      <span className="pr-3">{title}</span>
      <span className="text-sm">{value}</span>
    </div>
  </div>
);

const Checkout = ({
  onStepBackwards,
  phoneNumberAccount,
  amount,
  onRefillRequest,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="mb-12 rounded-2xl border border-opacity-50 shadow md:p-8 p-5 utility-checkout-card">
        <DetailItem
          title={t("phoneNumber")}
          value={phoneNumberAccount.phone}
          className="border-b pb-3"
        />
        <DetailItem
          title="Total"
          value={<div>{amountFormatter(amount)}</div>}
          className="font-bold"
        />
      </div>
      <div className="flex justify-between mb-10">
        <CPrimaryButton
          label={t("back")}
          onClick={onStepBackwards}
          className="sm:w-1/3 sm:flex-none flex-1 mr-2 sm:md-0"
        />
        <CPrimaryButton
          label={t("toCheckout")}
          onClick={onRefillRequest}
          isLoading={isLoading}
          className="sm:w-1/3 sm:flex-none flex-1"
        />
      </div>
    </div>
  );
};

export default Checkout;
