import React, {useState} from 'react'
import useFormErrors from '@utils/hooks/useFormErrors';
import {useTranslation} from 'react-i18next';
import searchBySerialNumber from '@utils/validators/SearchBySerialNumber';
import UKService from "@utils/services/UKService";
import {showError} from "@utils/helpers";
import {useHistory} from "react-router-dom";
import CInput from "@core/inputs/CInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const SearchBySerialNumberForm = ({ provider }) => {
  const [serialNumber, setSerialNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { clearError, getError, validateErrors } = useFormErrors();
  const { t } = useTranslation();
  const history = useHistory();

  const search = () => {
    const errors = validateErrors({serialNumber}, searchBySerialNumber)
    if (errors) return;

    setIsLoading(true)
    UKService.searchBySerialNumber({ serialNumber, ukProvider: provider.toUpperCase() })
    .then((response) => {
      const { invoice } = response.data.data
      history.push( `/utilities/water-supply/pay?provider=${provider}`,{invoices: [invoice]})
    }).catch((err) => showError(err.response.data.message))
    .finally(() => setIsLoading(false))
  }

  return (
    <div className="my-10">
      {/*<p className="text-sm text-gray-400 text-center mb-10">{t('enterSerialNumberText')}</p>*/}
      <CInput
        id="serialNumber"
        label={t('serialNumber')}
        placeholder={t('serialNumber')}
        value={serialNumber}
        handleInputChange={(value) => clearError('serialNumber', value, setSerialNumber)}
        error={getError('serialNumber')}
      />
      <div className="flex justify-end mt-5">
        <CPrimaryButton label={t('next')} isLoading={isLoading} onClick={search}/>
      </div>
    </div>
  )
}

export default SearchBySerialNumberForm