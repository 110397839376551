import React, {useState} from 'react';

const MerchantItem = ({merchant}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <a
      id={merchant?.name}
      title={merchant?.tradeName}
      href={merchant?.websiteUrl}
      target="_blank"
      rel="noreferrer"
      className="flex flex-col bg-white items-center justify-center cursor-pointer m-1 border border-gray-100 shadow-lg rounded-xl p-2 h-18 md:h-32 transform transition duration-500 hover:scale-105 hover:shadow-2xl"
    >
      {!loaded && (
        <div className="flex justify-center items-center">
          <div
            style={{ borderTopColor: 'transparent' }}
            className="h-4 w-4 md:w-8 md:h-8 border-2 border-blue-400 rounded-full animate-spin"
          />
        </div>
      )}
      <img
        src={merchant?.logoUrl}
        alt={merchant?.name}
        className={`object-contain h-5 md:h-10 ${!loaded ? 'hidden' : ''}`}
        onLoad={() => setLoaded(true)}
      />
      <span
        className="text-gray-700 text-center text-ellipsis overflow-hidden mt-1 text-xxs lg:text-base">
        {merchant?.tradeName}
      </span>
    </a>
  )
};

export default MerchantItem
