import React from 'react'
import BackButton from "@core/buttons/BackButton";
import {renderSupplierIcon} from "@utils/functions/WaterSupply";
import {useTranslation} from "react-i18next";

const ProviderHeader = ({ provider }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center">
      <div className="w-full mx-2 mt-10">
        <div className="flex flex-col">
          <BackButton className="md:pl-8 pl-4" />
        </div>
        <div className="flex flex-col">
          <img src={renderSupplierIcon(provider.toUpperCase())} alt={provider} className="h-14"/>
          <div className="font-montserrat font-medium text-white text-center text-xl uppercase mt-10">{provider}</div>
          <p className="text-center uppercase text-brand-gray mt-1">{t(`${provider}Description`)}</p>
        </div>
      </div>
    </div>
  )
}

export default ProviderHeader