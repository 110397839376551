import React, {useState} from 'react';
import Layout from '@hoc/layout/Layout';
import {useTranslation} from "react-i18next";
import {
  withStepProgress,
  useStepProgress,
  Step,
  StepProgressBar
} from "react-stepz";
import "@assets/css/wizard.css"
import OwnerInfo from "@components/Utilities/Insurances/TPL/OwnerInfo";
import SecondUser from "@components/Utilities/Insurances/TPL/SecondUser";
import TplDuration from "@components/Utilities/Insurances/TPL/TplDuration";
import ReviewData from "@components/Utilities/Insurances/TPL/ReviewData";
import BillingInfo from "@components/Utilities/Insurances/TPL/BillingInfo";
import SearchVehicle from "@components/Utilities/Insurances/TPL/SearchVehicle";
import ErrorWrapper from "@hoc/partials/ErrorWrapper";
import {renderAgencyIcon} from "@utils/functions/Tpl";
import CBackButton from "@core/buttons/CBackButton";

const allowedAgencies = ['ALBSIG', 'ANSIG', 'INTERSIG']

const PayTPLPage = () => {
  const url = new URL(window.location.href);
  const insuranceAgency = url.searchParams.get('agency')?.toUpperCase();

  const { t } = useTranslation()

  const [vehicleData, setVehicleData] = useState({});
  const [ownerData, setOwnerData] = useState({});
  const [secondUser, setSecondUser] = useState({});
  const [tplDuration, setTplDuration] = useState({});
  const [billingInfo, setBillingInfo] = useState({});
  const [premium, setPremium] = useState({});

  const steps = [{
    name: 'searchVehicle',
    nextStep: (vehicleData, premium) => {
      setVehicleData(vehicleData)
      setPremium(premium)
      stepForward()
    }
  }, {
    name: 'vehicleOwnership',
    nextStep: (ownerData) => {
      setOwnerData(ownerData)
      stepForward()
    }
  }, {
    name: 'vehicleOwnership',
    nextStep: (secondUser) => {
      setSecondUser(secondUser)
      stepForward()
    }
  }, {
    name: 'vehicleOwnership',
    nextStep: (duration) => {
      setTplDuration(duration)
      stepForward()
    }
  }, {
    name: 'billingInfo',
    nextStep: (billingInfo) => {
      setBillingInfo(billingInfo)
      stepForward()
    }
  }, {
    name: 'review'
  }
  ];

  const { stepForward, stepBackwards, currentStep } = useStepProgress({
    steps,
    startingStep: 0
  });

  return (
    <Layout>
      <ErrorWrapper error={!allowedAgencies.includes(insuranceAgency)} width={400} className="bg-gray-100 py-32">
        <div className="pt-22 pb-32 px-5 insurance-bg">
          <div className="flex justify-center">
            <div
              style={{ background: 'linear-gradient(299.73deg, rgba(255, 255, 255, 0.1064) 14.42%, rgba(1, 4, 43, 0.0686) 55.09%, rgba(255, 255, 255, 0.14) 100.22%)' }}
              className="w-full md:w-1/2 lg:w-1/3 drop-shadow-lg shadow-2xl backdrop-blur-xl border-b border-r border-opacity-20 rounded-4xl">
              <div className="flex justify-center w-full p-5">
                <div className="w-full relative mx-2">
                  <div className="absolute">
                    <CBackButton onClick={currentStep === 0 ? null : stepBackwards}/>
                  </div>
                  <div className="flex justify-center mt-7">
                    <img src={renderAgencyIcon(insuranceAgency)} alt={insuranceAgency} className={insuranceAgency === 'INTERSIG' ? 'h-14 w-38' : 'h-10'}/>
                  </div>
                </div>
              </div>
              <div className="font-montserrat text-center font-normal uppercase text-white text-base">
                {t('tplInsurance')}
              </div>
              <p className="text-center text-gray-500 uppercase mt-1 mb-4 font-light text-sm">{t(steps[currentStep].name)}</p>
              <div className="flex justify-center">
                <StepProgressBar className="w-4/5 md:w-2/3" steps={steps} />
              </div>
              <Step step={0}>
                <SearchVehicle
                  insuranceAgency={insuranceAgency}
                  initialState={vehicleData}
                  onNextStep={steps[0].nextStep}
                />
              </Step>
              <Step step={1}>
                <OwnerInfo
                  owner={`${vehicleData?.ownerFirstName} ${vehicleData?.ownerLastName}`}
                  premium={premium}
                  initialState={ownerData}
                  onNextStep={steps[1].nextStep}
                  stepBackwards={stepBackwards}
                />
              </Step>
              <Step step={2}>
                <SecondUser
                  initialState={secondUser}
                  onNextStep={steps[2].nextStep}
                  onSkip={stepForward}
                  stepBackwards={stepBackwards}
                />
              </Step>
              <Step step={3}>
                <TplDuration
                  initialState={tplDuration}
                  onNextStep={steps[3].nextStep}
                  stepBackwards={stepBackwards}
                />
              </Step>
              <Step step={4}>
                <BillingInfo
                  initialState={billingInfo}
                  onNextStep={steps[4].nextStep}
                  stepBackwards={stepBackwards}
                />
              </Step>
              <Step step={5}>
                <ReviewData
                  premium={premium}
                  tplDuration={tplDuration}
                  vehicleData={vehicleData}
                  ownerData={ownerData}
                  secondUser={secondUser}
                  billingInfo={billingInfo}
                  insuranceAgency={insuranceAgency}
                  stepBackwards={stepBackwards}
                />
              </Step>
            </div>
          </div>
        </div>
        <div className="footer-padding bg-brand-dark-blue"/>
      </ErrorWrapper>
    </Layout>
  )
}

export default withStepProgress(PayTPLPage);
