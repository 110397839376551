import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import RadioButton from "@core/inputs/RadioButton";
import useFormErrors from "@utils/hooks/useFormErrors";
import payTplValidator from "@utils/validators/PayTplValidator";
import CDatePicker from "@core/inputs/CDatePicker";
import CInput from "@core/inputs/CInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const SecondUser = ({ initialState, stepBackwards, onNextStep, onSkip }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [birthday, setBirthday] = useState('')
  const [gender, setGender] = useState('')
  const [nid, setNid] = useState('')
  const [isForeign, setIsForeign] = useState(false)

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  useEffect(() => {
    if (initialState) {
      const { firstName, lastName, address, birthday, gender, nid, isForeign } = initialState
      setFirstName(firstName)
      setLastName(lastName)
      setAddress(address)
      setBirthday(birthday)
      setGender(gender)
      setNid(nid)
      setIsForeign(isForeign)
    }
  }, [initialState])

  const goForward = () => {
    const secondUser = {
      firstName,
      lastName,
      address,
      birthday,
      gender,
      nid,
      isForeign
    }
    const errors = validateErrors(secondUser, payTplValidator.secondUser)
    if (errors) return;

    onNextStep(secondUser)
  }

  return <div className="flex flex-col px-10 md:px-15 rounded-lg shadow">
    <CInput
      id="nid"
      label={t('nid')}
      placeholder={t('nid')}
      value={nid}
      handleInputChange={(value) => clearError('nid', value, setNid)}
      error={getError('nid')}
      className="mb-3"
    />
    <CInput
      id="firstname"
      label={t('firstname')}
      placeholder={t('firstname')}
      value={firstName}
      handleInputChange={(value) => clearError('firstName', value, setFirstName)}
      error={getError('firstName')}
      className="mb-3"
    />
    <CInput
      id="lastname"
      label={t('lastname')}
      placeholder={t('lastname')}
      value={lastName}
      handleInputChange={(value) => clearError('lastName', value, setLastName)}
      error={getError('lastName')}
      className="mb-3"
    />
    <CInput
      id="address"
      label={t('address')}
      placeholder={t('address')}
      value={address}
      handleInputChange={(value) => clearError('address', value, setAddress)}
      error={getError('address')}
      className="mb-3"
    />

    <div className="mb-4">
      <div className="flex justify-between items-center mt-2">
        <label className="text-white flex w-full font-inter text-xs mb-1 text-opacity-40">
          {t('gender')}
        </label>
        <div className="flex">
          <RadioButton
            checked={gender === 'female'}
            onChange={() => clearError('gender', 'female', setGender)}
            label={t('female')}
            value={gender}
            className="mr-4"
          />
          <RadioButton
            checked={gender === 'male'}
            onChange={() => clearError('gender', 'male', setGender)}
            label={t('male')}
            value={gender}
          />
        </div>
      </div>
      {getError('gender') && <div className="text-right text-xs text-red-400 pt-1">{getError('gender')}</div>}
    </div>

    <div
      className={`w-full text-white ${
        getError('birthday')
          ? "text-red-500 text-opacity-100"
          : "focus-within:text-brand-light-blue text-opacity-40 focus-within:text-opacity-100"
      }`}
    >
      <label className="flex w-full font-inter text-xs mb-1" htmlFor="birthday">
        {t('birthday')}
        {getError('birthday') && <div className="ml-1">({getError('birthday')})</div>}
      </label>
      <CDatePicker
        id="birthday"
        label={t('birthday')}
        value={birthday}
        onChange={(value) => clearError('birthday', value, setBirthday)}
      />
    </div>
    <div className="flex justify-end mt-5 mb-10">
      <CPrimaryButton label={t('skip')} onClick={onSkip} className="mr-1 flex w-1/2 md:flex-none md:w-22"/>
      <CPrimaryButton label={t('next')} onClick={goForward} className="flex w-1/2 md:flex-none md:w-22"/>
    </div>
  </div>
}

export default SecondUser