import React from 'react'
import orderoo from "@assets/svg/orderoo.svg";
import whiteLogo from "@assets/svg/white_logo.svg";
import logo from "@assets/svg/pok_logo.svg";
import payWithPok from "@assets/svg/pay_with_pok.svg";
import menu from "@assets/svg/view_menu.svg";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import LoadingImg from "@core/Img";

const OrderooPage = ({ onPayWithPok, orderooId }) => {
  const { t } = useTranslation();

  const viewMenu = () => {
    window.location.replace(`https://orderoo.ai/qr/${orderooId}`);
  }

  return (
    <div className="relative">
      <div className="absolute flex flex-1 items-center justify-center md:justify-start h-14 p-10 lg:p-15">
        <Link to='/'>
          <img className="h-6 lg:h-8" src={whiteLogo} alt="Logo"/>
        </Link>
      </div>
      <div
        className="overscroll-none h-screen max-h-screen grid place-items-center bg-gradient-radial bg-center from-brand-light-blue to-brand-dark-blue pt-16 lg:pt-24 pb-10 md:py-0">
        <div className="flex flex-col md:flex-row items-center">
          <div className="rounded-3xl mx-auto md:mx-5 bg-white pb-5 pt-7 lg:pt-10 px-5 py-3 shadow-xl mb-6 md:mb-0">
            <div className="flex justify-center">
              <img src={logo} alt="POK Logo" className="h-8 lg:h-10 ml-2"/>
            </div>
            <div className="flex justify-center py-5 lg:py-7">
              <LoadingImg src={payWithPok} alt="POK Logo" className="ml-2 h-24 lg:h-38"/>
            </div>
            <div onClick={onPayWithPok}
                 className="flex items-center justify-center w-62 lg:w-78 rounded-full bg-brand-dark-blue text-white px-8 py-4 cursor-pointer transition-all hover:duration-200 text-sm lg:text-lg font-medium shadow-lg transform hover:scale-105">
              {t('pay_with_pok')}
            </div>
          </div>

          <div className="rounded-3xl mx-auto md:mx-5 bg-white pb-5 pt-7 lg:pt-10 px-5 py-3 shadow-xl">
            <div className="flex justify-center">
              <img src={orderoo} alt="Orderoo Logo" className="h-6 lg:h-8 ml-2"/>
            </div>
            <div className="flex justify-center py-6 lg:pt-8 lg:pb-7">
              <LoadingImg src={menu} alt="View Menu" className="h-24 lg:h-38"/>
            </div>
            <div onClick={viewMenu}
                 className="flex items-center justify-center w-62 lg:w-78 rounded-full bg-brand-dark-blue text-white px-8 py-4 cursor-pointer transition-all hover:duration-200 text-sm lg:text-lg font-medium shadow-lg transform hover:scale-105">
              {t('view_menu')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderooPage