import {Collapse} from "react-collapse";
import React, {useState} from "react";
import {FaPlus, FaMinus} from "react-icons/fa";

const QuestionComponent = ({ faq }) => {
  const [isQuestionOpened, setIsQuestionOpened] = useState(false)
  const { question, answer } = faq

  const toggleDetails = () => setIsQuestionOpened(prev => !prev)

  return (
    <div className="flex flex-col w-full border-b-0.5 border-opacity-30 py-4 cursor-pointer" onClick={toggleDetails}>
      <div className="flex flex-1 justify-between items-center font-montserrat">
        <span className="font-montserrat text-white text-opacity-80 font-medium tracking-tight">
          {question}
        </span>
        <div className="text-end text-white text-opacity-70 pr-2">
          {isQuestionOpened ? <FaMinus className="h-6"/> : <FaPlus className="h-6"/>}
        </div>
      </div>
      <Collapse isOpened={isQuestionOpened}>
        <div className="font-light text-white text-sm md:text-base text-justify py-2 md:pr-10">{answer}</div>
      </Collapse>
    </div>
  )
}
export default QuestionComponent