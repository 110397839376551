import { ADD_HOME_PAGE, REMOVE_HOME_PAGE } from '@constants/redux';

export const addHomePage = (payload) => {
  return {
    type: ADD_HOME_PAGE,
    payload
  };
};

export const removeHomePage = () => {
  return {
    type: REMOVE_HOME_PAGE
  };
};
