import React, { useEffect, useRef, useState } from 'react';
import Layout from "@hoc/layout/Layout";
import API from "@utils/plugins/API";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import DisputesForm from "@components/DisputesPage/DisputesForm";
import ContactDetails from "@components/ContactUsPage/ContactDetails";
import useMouseLight from "@utils/hooks/useMouseLight";

const Disputes = () => {
  const [siteKey, setSiteKey] = useState('');
  const disputesRef = useRef(null);
  useMouseLight(disputesRef);

  const { t } = useTranslation();

  useEffect(() => {
    API.get(`/public/recaptcha-key`).then(response => {
      const siteKey = response.data.data.siteKey;
      setSiteKey(siteKey);
    })
  }, []);

  return (
    <Layout>
      <GoogleReCaptchaProvider reCaptchaKey={siteKey} scriptProps={{ async: true }}>
        <div ref={disputesRef} className='fixed'/>
        <div className="contact-us-container sm:px-0 px-5 flex flex-col items-center justify-center">
          <div
            className="relative rounded-3xl md:rounded-4xl lg:rounded-5xl contact-us-card flex lg:flex-row flex-col justify-center md:w-2/3 lg:w-3/4 xl:w-2/3 lg:p-10 md:p-8 p-6 mt-24 lg:my-40 mb-10 md:mb-40 lg:mb-20">
            <div className="flex flex-col lg:items-start items-center text-white lg:w-2/5 lg:ml-5 pb-5">
              <div className="uppercase">
                <div
                  className="2xl:text-7xl xl:text-6xl sm:text-4xl text-3xl font-montserrat font-medium lg:text-left text-center mt-10">
                  {t('disputes')}
                </div>
                <div className="pt-5 opacity-70 lg:text-left text-justify text-sm">
                  {t('disputesDescription')}
                </div>
              </div>
              <div className="hidden lg:flex lg:mt-56 3xl:mt-60 opacity-70">
                <ContactDetails/>
              </div>
            </div>
            <div className="lg:w-3/5 lg:pl-16 justify-end">
              <DisputesForm/>
            </div>
            <div className="block lg:hidden mt-12">
              <ContactDetails/>
            </div>
          </div>
          <div className="footer-padding"/>
        </div>
      </GoogleReCaptchaProvider>
    </Layout>
  )
};

export default Disputes;
