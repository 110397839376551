import React, {useEffect, useMemo, useRef, useState} from 'react';
import _ from "lodash";
import {RiCloseLine} from "react-icons/ri";

const SearchableSelect = (
  {
    id,
    value,
    label,
    placeholder,
    options = [],
    valueKey = 'value',
    labelKey = 'label',
    onSelect,
    onFocus,
    icon,
    error,
    disabled,
    required,
    className,
  }) => {
  const placeholderOption = useMemo(() => {
    return { [valueKey]: null, [labelKey]: '' }
  }, [labelKey, valueKey]);

  const [qs, setQs] = useState('');
  const [selected, setSelected] = useState(placeholderOption);
  const [displayOptions, setDisplayOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);

  const searchRef = useRef();

  useEffect(() => {
    setDisplayOptions(options)
    if (!options.includes(selected)) {
      setSelected(placeholderOption)
    }
  }, [options, placeholderOption, selected])

  const clearOption = () => {
    setQs('');
    setSelected(placeholderOption);
  }

  const optionsArray = () => {
    return displayOptions.map(option => {
      return <div
        title={option[labelKey]}
        key={option[labelKey]}
        className={`text-sm py-1.5 px-3 flex items-center cursor-pointer ${option[valueKey] === selected[valueKey] ? 'bg-brand-light-blue text-white hover:bg-brand-light-blue' : 'hover:bg-brand-light-blue-10'}`}
        onClick={() => handleChange(option[labelKey])}
      >
        {option[labelKey]}
      </div>
    })
  };

  const handleChange = (value) => {
    if (disabled || !options.length) return;
    setQs(value);

    const matchingOption = options.find(option => option[labelKey].toLowerCase() === value.toLowerCase());
    if (matchingOption) {
      setSelected(matchingOption);
      setQs(matchingOption[labelKey]);
      setDisplayOptions(options);
      return;
    }
    search(value);
    setSelected({ [valueKey]: null, [labelKey]: value });
  };

  const handleFocus = () => {
    if (disabled) {
      searchRef.current.blur()
      return;
    }
    setShowOptions(true);
  }

  const search = _.debounce(() => {
    const filteredOptions = options.filter(option => option[labelKey].toLowerCase().includes(qs.toLowerCase()));
    setDisplayOptions(filteredOptions);
  }, 300);

  useEffect(() => {
    onSelect(selected[valueKey]);
  }, [selected, onSelect, valueKey]);

  const addOnClickOutsideListener = () => {
    document.addEventListener("click", onClickOutsideListener);
  }

  const onClickOutsideListener = () => {
    setShowOptions(false)
    document.removeEventListener("click", onClickOutsideListener);
  }

  useEffect(() => {
    if (value && options.length) {
      const initialOption = options.find(option => option[valueKey] === value);
      setSelected(initialOption || placeholderOption);
      setQs(initialOption[labelKey] || '');
    }
  }, [value, options, placeholderOption, labelKey, valueKey]);

  return (
    <div className={`relative ${className} w-full text-white ${
      error
        ? "text-red-500 text-opacity-100"
        : "focus-within:text-brand-light-blue text-opacity-40 focus-within:text-opacity-100"
    }`}
         onBlur={() => addOnClickOutsideListener()}>
      {label && (
        <label className="flex w-full font-inter text-xs mb-1" htmlFor={id}>
          {label}
          {error && <div className="ml-1">({error})</div>}
        </label>
      )}
      <div
        className={`flex flex-row items-center align-middle border border-white border-opacity-40 rounded-lg px-4 py-3 focus:border-brand-light-blue ${showOptions && 'border-brand-light-blue'} ${error && 'border-red-700 border-opacity-100'}`}
        onClick={onFocus}>
        <div className={`${!showOptions && 'text-gray-400'}`}>
          {icon}
        </div>
        <input
          placeholder={placeholder}
          ref={searchRef}
          value={selected[labelKey]}
          className={`ml-2 w-full text-sm focus:outline-none bg-transparent placeholder-white placeholder-opacity-40 placeholder-text-xs`}
          onFocus={handleFocus}
          onChange={(e) => handleChange(e.target.value)}
        />
        {
          selected[valueKey] &&
          <div className="text-gray-400 hover:text-brand-light-blue cursor-pointer" onClick={clearOption}>
            <RiCloseLine/>
          </div>
        }
      </div>
      <div
        style={{backdropFilter: 'blur(70px)'}}
        className={`${!showOptions ? 'hidden' : ''} w-full border-1 border-opacity-40 absolute flex flex-col z-50 bg-brand-dark-blue bg-opacity-50 backdrop-blur-lg max-h-56 mt-0.5 rounded-md shadow-2xl pr-1`}>
        <div className="overflow-y-auto overflow-ellipsis scrollbar-thin scrollbar-thumb-brand-light-blue scrollbar-track-transparent">
          {optionsArray()}
        </div>
      </div>
    </div>
  )
}

export default SearchableSelect;

