import { useEffect } from "react";

const useExpand = (containerRef, imageRef, buttonRef) => {
  const setHover = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.classList.add('shine-hover');
    }
    if (buttonRef && buttonRef.current) {
      buttonRef.current.classList.add('duration-200', 'shadow-inner', 'primary-button');
    }
  }

  const removeHover = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.classList.remove('shine-hover')
    }
    if (buttonRef && buttonRef.current) {
      buttonRef.current.classList.remove( 'duration-200', 'shadow-inner', 'primary-button');
    }
  }

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.addEventListener('mouseenter', setHover)
      containerRef.current.addEventListener('mouseleave', removeHover)

      return () => {
        containerRef.current.removeEventListener('mouseenter', setHover)
        containerRef.current.removeEventListener('mouseleave', removeHover)
      }
    }
  }, [containerRef])
}

export default useExpand;