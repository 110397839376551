import React, { useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import { useTranslation } from "react-i18next";
import ukt from "@assets/svg/utilities/ukt.svg";
import ukd from "@assets/svg/utilities/ukd.svg";
import ukv from "@assets/svg/utilities/ukv.svg";
import WaterSupply from "@assets/svg/utilities/water_supply.svg";
import { useHistory } from "react-router-dom";
import BackButton from "@core/buttons/BackButton";
import useMouseLight from "@utils/hooks/useMouseLight";

const ProviderItem = ({ title, description, icon, comingSoon }) => {
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  const goToProvider = () =>
    history.push(`/utilities/water-supply/search?provider=${title}`);

  return (
    <div
      onClick={goToProvider}
      className={`flex px-4 sm:px-8 py-5 rounded-2xl border border-opacity-20 my-3 transform utility-checkout-card ${
        comingSoon ? "opacity-50" : "cursor-pointer hover:translate-y-0.5"
      }`}
    >
      {!loaded && (
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-4 h-4 border-2 border-blue-400 rounded-full animate-spin my-auto"
        />
      )}
      <img
        src={icon}
        alt={title}
        className={`my-auto transform transition duration-500 hover:scale-125 w-11 h-11 ${
          !loaded ? "hidden" : ""
        }`}
        onLoad={() => setLoaded(true)}
      />
      <div className="ml-5 mr-3 text-white">
        <span className="font-medium uppercase">{title}</span>
        <p className="text-sm text-brand-gray">{description}</p>
      </div>
    </div>
  );
};

const WaterSupplyProvidersPage = () => {
  const { t } = useTranslation();
  const waterSupplyRef = useRef(null);
  useMouseLight(waterSupplyRef);

  return (
    <Layout>
      <div ref={waterSupplyRef} className='fixed'/>
      <div className="flex flex-col items-center py-32 bg-brand-dark-blue px-5 md:px-0 utility-container">
        <div className="relative flex flex-col mx-2 w-full sm:w-1/2 lg:w-2/5 border border-opacity-20 utility-card">
          <div className="flex flex-col my-8">
            <BackButton className="md:pl-8 pl-4" />
            <img src={WaterSupply} alt="Water Supply" className="h-14" />
          </div>
          <div className="text-center uppercase text-white font-montserrat font-medium mb-6">
            {t("waterSupply")}
          </div>
          <div className="px-5 sm:px-10 pb-10">
            <ProviderItem
              title="ukt"
              description={t("uktDescription")}
              icon={ukt}
            />
            <ProviderItem
              title="ukd"
              description={t("ukdDescription")}
              icon={ukd}
            />
            <ProviderItem
              title="ukv"
              description={t("ukvDescription")}
              icon={ukv}
            />
          </div>
        </div>
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  );
};

export default WaterSupplyProvidersPage;
