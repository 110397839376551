import Albsig from "@assets/svg/utilities/white/full/albsig.svg";
import Ansig from "@assets/svg/utilities/white/full/ansig.svg";
import Intersig from "@assets/images/intersig.png";

export const renderAgencyIcon = (agency) => {
    switch (agency) {
        case 'ALBSIG':
            return Albsig;
        case 'ANSIG':
            return Ansig;
        default:
            return Intersig
    }
}

export const prepareSdkOrderUrl = (url, billingInfo, ownerData) => {
    let query = ''
    const queryObject = {
        address: billingInfo.address1,
        city: billingInfo.locality,
        country: billingInfo.countryCode,
        zip: billingInfo.postalCode,
        state: billingInfo.administrativeArea,
        phone: ownerData.phone,
        email: ownerData.email,
    }
    Object.entries(queryObject).forEach(([key, value]) => {
        query += `&${key}=${value}`
    })
    return `${url}?${query.slice(1)}`
}