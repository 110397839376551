import React, {useState} from "react";
import '@assets/css/style.css'
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import {useTranslation} from "react-i18next";
import GetPokAppModal from "@components/GetPokApp/GetPokAppModal";

const GetAppButton = ({buttonRef = null, label = 'exploreEasyPayments', initialState = false, onOpen}) => {
    const {t} = useTranslation();
    const [getAppModal, setGetAppModal] = useState(initialState);

    return (
        <div>
            <div className="flex justify-start">
                <CPrimaryButton buttonRef={buttonRef} label={t(label)} onClick={() => {
                    setGetAppModal(!getAppModal);
                    onOpen(false)
                }}/>
            </div>
            <GetPokAppModal isOpen={getAppModal} setOpenModal={setGetAppModal} onOpen={onOpen}/>
        </div>
    )
}
export default GetAppButton