import {useEffect} from "react";

const useMouseLight = (sectionRef = {}, containerRef = {}, mouseClass = 'mouse-light') => {

    const setHover = () => {
        if (containerRef && containerRef.current) {
            sectionRef.current.classList.add(mouseClass);
            sectionRef.current.classList.remove('mouse-light');
        }
    }

    const removeHover = () => {
        if (containerRef && containerRef.current) {
            sectionRef.current.classList.remove(mouseClass);
            sectionRef.current.classList.add('mouse-light');
        }
    }

    useEffect(() => {
        const updateMousePosition = (ev: MouseEvent) => {
            if (!sectionRef.current) return
            const {clientX, clientY} = ev
            sectionRef.current.style.setProperty('--x', `${clientX}px`)
            sectionRef.current.style.setProperty('--y', `${clientY}px`)
            sectionRef.current.classList.add('mouse-light')
        }

        window.addEventListener('mousemove', updateMousePosition)
        if (containerRef && containerRef.current) {
            containerRef.current.addEventListener('mouseenter', setHover)
            containerRef.current.addEventListener('mouseleave', removeHover)
        }
        return () => {
            window.removeEventListener('mousemove', updateMousePosition)
            if (containerRef) {
                containerRef.current.removeEventListener('mouseenter', setHover)
                containerRef.current.removeEventListener('mouseleave', removeHover)
            }
        }
    }, [])
}

export default useMouseLight;
