const searchPhoneNumberValidator = {
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: "errors.emptyPhone",
    }
  }
};

export default searchPhoneNumberValidator;
