import { ADD_APP_STATE, REMOVE_APP_STATE } from '@constants/redux';

export const addAppState = (payload) => {
  return {
    type: ADD_APP_STATE,
    payload
  };
};

export const removeAppState = (payload) => {
  return {
    type: REMOVE_APP_STATE,
    payload
  };
};
