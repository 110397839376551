import React from "react";

const RoundedCheckbox = ({ onCheck, isChecked = false }) => (
  <div className="flex items-center" onClick={onCheck}>
    <label className="flex md:items-center my-2 cursor-pointer">
      <div
        className={`border-2 ${
          isChecked
            ? "border-brand-light-blue"
            : "hover:border-brand-light-blue hover:ring-brand-light-blue"
        } rounded-full p-1 hover:ring-2 transition duration-200`}
      >
        <div
          className={`${
            isChecked ? "bg-brand-light-blue-90" : ""
          } rounded-full w-4 h-4`}
        />
      </div>
    </label>
  </div>
);

export default RoundedCheckbox;
