import React, {useCallback, useEffect, useState} from "react";
import logo from "@assets/svg/POK.svg";
import {Transition} from "@headlessui/react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import LanguageSelector from "@core/dropdowns/LanguageSelector";
import {useTranslation} from "react-i18next";
import useActiveMenuItem from "@utils/hooks/useActiveMenuItem";

const NavItem = ({title, url, exact = false, hyperlink = false}) => {
    const isActive = useActiveMenuItem(url, exact)

    if (hyperlink) {
        return (
          <a
            rel="noreferrer"
            className={`font-inter text-sm mr-10 ${isActive ? 'text-brand-light-blue' : 'text-white'} text-opacity-90 hover:text-brand-light-blue transition duration-200`}
            href={url}
            target={'_blank'}
          >
              {title}
          </a>
        )
    }

    return <Link
      className={`font-inter text-sm mr-10 ${isActive ? 'text-brand-light-blue' : 'text-white'} text-opacity-90 hover:text-brand-light-blue transition duration-200`}
      to={url}
    >
        {title}
    </Link>
};

function NavBar() {
    const [isOpen, setIsOpen] = useState(false);

    const location = useLocation();
    const {t} = useTranslation();

    const [classes, setClasses] = useState("");
    const [isMounted, setIsMounted] = useState(true);

    const changeBackground = useCallback(() => {
        if (isMounted) {
            const newClasses = window.scrollY >= 66 ? "blur-nav" : "";
            setClasses(newClasses);
        }
    }, [isMounted]);

    useEffect(() => {
        setIsMounted(true);
        changeBackground();
        window.addEventListener("scroll", changeBackground);

        return () => setIsMounted(false);
    }, [changeBackground]);

    return (
      <nav
        className={`${classes} transition duration-300 fixed w-full justify-around nav-depth z-20`}
      >
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 3xl:px-0 hidden md:block">
              <div className="relative flex items-center justify-between py-7">
                  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                      <Link to="/">
                          <img
                            className="h-8 transform hover:scale-110 duration-300"
                            src={logo}
                            alt="Logo"
                          />
                      </Link>
                  </div>
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                      <div className="flex items-center">
                          <div className="flex">
                              <NavItem title={t("home")} url="/" exact/>
                              <NavItem title={t("news")} url="/news"/>
                              <NavItem title={t("aboutUs")} url="/about-us"/>
                              <NavItem title={t("ourServices")} url="/our-services"/>
                              <NavItem title={t("contact")} url="/contact-us"/>
                              <NavItem title={t("Documentation")} url="https://docs.pokpay.io/" hyperlink />
                          </div>
                          <LanguageSelector/>
                      </div>
                  </div>
              </div>
          </div>

          <div
            className={`md:hidden ${
              isOpen ? "main-nav-open" : null
            }`}
          >
              <div className="flex place-content-between p-4">
                  <Link className="mr-3" to="/">
                      <img className="h-8" src={logo} alt="Logo"/>
                  </Link>
                  <button
                    id="mobile-menu"
                    className="focus:outline-none mt-4"
                    onClick={() => setIsOpen(!isOpen)}
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                  >
                      <div className={`${isOpen ? 'open-burger' : 'burger'}`}/>
                  </button>
              </div>
              <Transition
                show={isOpen}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                  <div className="md:hidden" id="mobile-menu">
                      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 mt-12">
                          <Link
                            to="/"
                            className={`text-gray-${
                              location.pathname === "/" ? "700" : "300"
                            } hover:text-white block px-3 pb-7 rounded-md text-2xl font-medium main-nav-text-open`}
                          >
                              {t("home")}
                          </Link>
                          <Link
                            to="/news"
                            className={`text-gray-${
                              location.pathname === "/news" ? "700" : "300"
                            } hover:text-white block px-3 pb-7 rounded-md text-2xl font-medium main-nav-text-open`}
                          >
                              {t("news")}
                          </Link>

                          <Link
                            to="/about-us"
                            className={`text-gray-${
                              location.pathname === "/about-us" ? "700" : "300"
                            } hover:text-white block px-3 pb-7 rounded-md text-2xl font-medium main-nav-text-open`}
                          >
                              {t("aboutUs")}
                          </Link>

                          <Link
                            to="/our-services"
                            className={`text-gray-${
                              location.pathname === "/our-services" ? "700" : "300"
                            }  hover:text-white block px-3 pb-7 rounded-md text-2xl font-medium main-nav-text-open`}
                          >
                              {t("ourServices")}
                          </Link>

                          <Link
                            to="/contact-us"
                            className={`text-gray-${
                              location.pathname === "/contact-us" ? "700" : "300"
                            } hover:text-white block px-3 pb-7 rounded-md text-2xl font-medium main-nav-text-open`}
                          >
                              {t("contact")}
                          </Link>

                          <a
                            target="_blank"
                            href="https://docs.pokpay.io/"
                            className={`text-gray-300 hover:text-white block px-3 pb-7 rounded-md text-2xl font-medium main-nav-text-open`}
                          >
                              {t("Documentation")}
                          </a>
                          <div className="flex justify-center align-middle pt-12 main-nav-text-open">
                              <LanguageSelector/>
                          </div>
                      </div>
                  </div>
              </Transition>
          </div>
      </nav>
    );
}

export default NavBar;
