import React from 'react';

const CPrimaryButton = ({ label, onClick, disabled = false, isLoading = false, buttonRef, ...props }) => {
  return (
    <div {...props}>
      <button
        ref={buttonRef}
        disabled={disabled}
        onClick={onClick}
        className={`w-full flex flex-1 justify-center
        items-center uppercase text-xs border border-white border-opacity-40 font-inter text-white py-3 px-6 rounded-4xl focus:outline-none m-1 ${disabled ? 'bg-gray-100 bg-opacity-20' : 'primary-button'}`}>
        {isLoading && (
          <div className="mr-2">
            <div
              style={{ borderTopColor: 'transparent' }}
              className="w-3 h-3 border-2 border-white border-opacity-70 rounded-full animate-spin"
            />
          </div>
        )}
        {label}
      </button>
    </div>
  )
}

export default CPrimaryButton;

