import React from 'react'
import {useTranslation} from "react-i18next";

const ServiceStatusItem = ({ service }) => {
  const {t} = useTranslation();

  const statusFormatter = (status) => {
    switch(status) {
      case 'active':
        return <span className="text-white rounded-full bg-green-500 px-4 py-1">{t('active')}</span>
      case 'unavailable':
        return <span className="text-white rounded-full bg-red-500 px-4 py-1">{t('unavailable')}</span>
      default:
        return <span className="text-white rounded-full bg-attention px-4 py-1">{t('underMaintenance')}</span>
    }
  }

  return (
    <div className="flex justify-between items-center shadow-sm rounded-md border border-opacity-50 px-6 py-5 my-2">
      <div className="font-medium text-white uppercase">
        {service.name}
      </div>
      <div className="text-xs uppercase">{statusFormatter(service.status)}</div>
    </div>
  )
}

export default ServiceStatusItem