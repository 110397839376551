import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useFormErrors from "@utils/hooks/useFormErrors";
import TplService from "@utils/services/TplService";
import searchVehicleValidator from "@utils/validators/SearchVehicleValidator";
import {showError} from "@utils/helpers";
import CInput from "@core/inputs/CInput";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const SearchVehicle = ({ initialState, insuranceAgency, onNextStep }) => {
  const [plate, setPlate] = useState('');
  const [vin, setVin] = useState('');
  const [vinError, setVinError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  useEffect(() => {
    if (initialState) {
      setPlate(initialState.plate)
      setVin(initialState.vin)
    }
  }, [initialState])

  const searchVehicle = () => {
    const payload = { plate, vin: vin || '', insuranceAgency }

    const error = validateErrors(payload, searchVehicleValidator)
    if (error) return;

    setIsLoading(true)
    TplService.searchVehicle(payload).then((response) => {
      const { vehicleInfo, premium } = response.data.data
      onNextStep(vehicleInfo, premium)
    }).catch((err) => {
      if (err.response.data.statusCode === 406 && insuranceAgency === 'ALBSIG') {
        setVinError(t('errors.emptyVin'))
      }
      showError(err.response.data.message)
    })
      .finally(() => setIsLoading(false))
  }

  return <div
    className="flex flex-col px-10 md:px-15 rounded-lg shadow">
      <CInput
        id="plateNumber"
        label={t('plateNumber')}
        placeholder={t('plateNumber')}
        value={plate}
        handleInputChange={(value) => clearError('plate', value, setPlate)}
        error={getError('plate')}
        className="mb-3 flex"
      />
      <CInput
        id="vinNumber"
        label={t('vinNumber')}
        placeholder={t('vinNumber')}
        value={vin}
        handleInputChange={(value) => {
          setVinError('')
          clearError('vin', value, setVin)
        }}
        error={getError('vin') || vinError}
        className="mb-3 flex"
      />
    <div className="flex justify-end mt-5 mb-10">
      <CPrimaryButton label={t('next')} onClick={searchVehicle} isLoading={isLoading} className="flex w-full md:flex-none md:w-22"/>
    </div>
  </div>
}

export default SearchVehicle