import React from "react";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
const Header = ({ data }) => {

  return (
    <div className="flex justify-center items-center w-full pt-20">
      <div className="flex flex-col lg:w-11/12 xl:w-2/3 py-10 md:py-20 px-5">
        <div className="text-white font-montserrat text-2xl md:text-5xl xl:text-6xl font-semibold w-full uppercase text-center mouse-text">
          {data ? data?.title : <SkeletonLoader />}
        </div>
        <div className="text-center mx-auto font-light text-white sm:w-2/3 font-montserrat md:my-15 text-sm opacity-70 mouse-text">
          {data ? data?.subtitle : <SkeletonLoader variant='text' count={4}/>}
        </div>
      </div>
    </div>
  );
};

export default Header;
