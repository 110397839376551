import React, { useRef } from "react";
import Layout from "@hoc/layout/Layout";
import { useTranslation } from "react-i18next";
import "@assets/css/wizard.css";
import { useLocation } from "react-router-dom";
import InvoiceAnimation from "@assets/animations/invoice.json";
import Lottie from "react-lottie";
import _ from "lodash";
import DigicomInvoice from "@pages/Utilities/Digicom/DigicomInvoice";
import CBackButton from "@core/buttons/CBackButton";
import DigicomLogo from "@assets/svg/utilities/white/digicom-logo.svg";
import useMouseLight from "@utils/hooks/useMouseLight";

const DigicomInvoices = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [invoices] = _.get(location, "state.invoices", []);
  const invoicesRef = useRef(null);
  useMouseLight(invoicesRef);

  return (
    <Layout>
      <div ref={invoicesRef} className='fixed'/>
      <div className="flex justify-center items-center my-auto py-44 utility-container">
        <div className="relative flex flex-col mx-2 w-full sm:w-1/2 lg:w-2/5 2xl:w-1/3 border border-opacity-20 utility-card">
          <div className="flex flex-col my-8">
            <div>
              <CBackButton className="md:pl-8 pl-4" />
            </div>
            <img src={DigicomLogo} alt="Digicom" className="h-14" />
          </div>
          {invoices.length ? (
            invoices.map((invoice) => (
              <DigicomInvoice invoice={invoice} key={invoice.id} />
            ))
          ) : (
            <div className="w-1/2 mt-14 mb-18 mx-auto">
              <Lottie
                ariaRole="div"
                isClickToPauseDisabled
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: InvoiceAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                  width: 200,
                }}
              />
              <p className="text-center text-gray-400">{t("noInvoices")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  );
};

export default DigicomInvoices;
