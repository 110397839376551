import React, { useCallback, useEffect, useState } from 'react';
import DownChevron from '@assets/svg/down_chevron_gray.svg';

const Select = ({
                    id,
                    placeholder = 'Select',
                    options = [],
                    valueKey = 'value',
                    labelKey = 'label',
                    selected,
                    onSelect,
                    error,
                    disabled,
                    className,
                    focusedOption
                }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const onClickOutsideListener = useCallback(() => {
        setShowDropdown(false);
        document.removeEventListener('click', onClickOutsideListener);
    }, []);

    const addOnClickOutsideListener = useCallback(() => {
        document.addEventListener('click', onClickOutsideListener);
    }, [onClickOutsideListener]);

    const optionsList = () => {
        if (!options.length) return;

        return options.map((option) => (
            <div
                title={option[labelKey]}
                id={`${option[valueKey]}-${id}`}
                key={option[valueKey]}
                className={`px-4 py-2 hover:bg-brand-light-blue text-white text-opacity-70 ${
                    selected[valueKey] === option[valueKey] ? `bg-brand-light-blue text-brand-dark-blue font-medium` : ''
                }`}
                onClick={() => onSelect(option)}>
                <div className="text-sm overflow-x-hidden whitespace-nowrap overflow-ellipsis">
                    {option[labelKey]}
                </div>
            </div>
        ));
    };

    useEffect(() => {
        if (showDropdown) {
            addOnClickOutsideListener();
            if (focusedOption) {
                document
                    .getElementById(`${focusedOption[valueKey]}-${id}`)
                    .scrollIntoView({ block: 'center' });
            }
        }
    }, [addOnClickOutsideListener, showDropdown, focusedOption, valueKey, id]);

    return (
        <div
            data-testid="select"
            className={`relative flex-1 ${className} ${!disabled ? 'cursor-pointer' : ''}`}>
            <div
                data-testid="select-input"
                className={`border-b-1 w-full focus:outline-none py-1 mr-2
                    items-center flex flex-row justify-between ${
                    error ? 'border border-red-400' : ''
                }
        ${disabled ? 'bg-gray-50 text-gray-500' : 'bg-transparent text-white text-opacity-60'}`}
                onClick={() => {
                    if (!disabled) {
                        setShowDropdown((prevState) => !prevState);
                    }
                }}>
                {selected[labelKey] ? (
                    <div className="flex-1">{selected[labelKey]}</div>
                ) : (
                    <span className="text-gray-500">{placeholder}</span>
                )}
                <img
                    alt="DropDown"
                    src={DownChevron}
                    height={12}
                    width={11}
                    className="ml-1 text-gray-300"
                />
            </div>
            <div
              style={{ backdropFilter: 'blur(6px)' }}
                data-testid="dropdown-options"
                className={`${
                    showDropdown ? '' : 'hidden'
                } border-1 w-full absolute z-50 bg-white bg-opacity-20 mt-0.5 rounded-md shadow-2xl overflow-y-auto overflow-hidden max-h-56`}>
                <div className="text-white px-4 py-2 text-sm">{placeholder}</div>
                {optionsList()}
            </div>
            <div className={`text-xs text-red-400 pt-1 ${!error && 'pb-4'}`}>{error}</div>
        </div>
    );
};

export default Select;
