const searchByContractCode = {
  contractCode: {
    presence: {
      allowEmpty: false,
      message: 'errors.emptyContractCode'
    }
  },
};

export default searchByContractCode;
