import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";

const CBackButton = ({ onClick, icon, disabled = false, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    onClick ? onClick() : history.goBack();
  };

  return (
    <div {...props}>
      <button
        onClick={goBack}
        disabled={disabled}
        type="button"
        title={t('back')}
        className="p-2 bg-gray-700 bg-opacity-80 focus:outline-none disabled:opacity-50 transform hover:-translate-x-0.5 text-white rounded-full"
      >
        <FaChevronLeft className="h-3 w-3" />
      </button>
    </div>
  );
};

export default CBackButton;
