import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import alTranslations from "./al";
import enTranslations from "./en";

if (localStorage.getItem('POKWebLang') !== ('en' && 'al')) {
  localStorage.setItem('POKWebLang', 'al')
}

const language = localStorage.getItem('POKWebLang')

const resources = {
  en: {
    translation: enTranslations
  },
  al: {
    translation: alTranslations
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
