import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import API from "@utils/plugins/API";
import MerchantItem from "@components/HomePage/MerchantItem";
import _ from "lodash";
import {useSelector} from "react-redux";

const WhereToPay = () => {
    const {t} = useTranslation();
    const [merchants, setMerchants] = useState([]);
    const [filteredMerchants, setFilteredMerchants] = useState(merchants);
    const [qs, setQs] = useState('');

    const header = useSelector((state) =>
        _.get(state, 'homePageReducer.WhereYouCanPay', {})
    );

    const fetchData = useCallback(() => {
        API.get('/public/available-merchants').then((response) => {
            const {merchants} = response.data.data;
            setMerchants(merchants);
            setFilteredMerchants(merchants)
        }).catch(() => setMerchants([]));
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const search = _.debounce(() => {
        const filteredOptions = merchants.filter(option => option.tradeName.toLowerCase().includes(qs.toLowerCase()));
        setFilteredMerchants(filteredOptions);
    }, 300);

    useEffect(() => {
        if (!merchants.length) return;
        search();
    }, [qs])

    return (
        <div className="flex justify-center w-full py-10 2xl:pb-10 2xl:pt-0">
            <div className="flex flex-col w-full lg:w-2/3">
                <div className="block">
                    <div className="flex-1 flex flex-col justify-center mx-5 py-10 md:px-10 md:py-15 text-center">
                        <div
                            className="text-white text-4xl 2xl:text-5xl font-semibold uppercase">{header?.title}
                        </div>
                        <div className="mt-5 text-white text-opacity-60 text-xs md:text-base font-montserrat">
                            <div>{header?.subtitle}</div>
                            <div>{header?.description}</div>
                        </div>
                    </div>
                </div>
                <div
                    className={`flex where-to-pay-card flex-col rounded-2xl lg:rounded-5xl py-5 px-5 md:px-10 md:py-10 lg:py-15 xl:px-20 3xl:px-42 border-opacity-30 mx-5 lg:mx-0 border border-[#ffffff1a] ${filteredMerchants.length >= 12 ? 'h-120 md:h-150 lg:h-162' : 'h-70 md:h-100'}`}>
                    <div className="relative w-full md:w-11/12 mb-5 md:ml-5">
                        <div className="absolute inset-y-0 left-4 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-white" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor"
                                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input onChange={(e) => setQs(e.target.value)} id="default-search"
                               className="block w-full p-4 pl-14 text-base text-white rounded-full bg-brand-dark-blue focus:ring-white focus:border-white"
                               placeholder={t('search')} required/>
                        <button
                            className="hidden lg:flex where-to-pay-card text-white absolute right-2.5 bottom-2.5 font-medium rounded-full text-sm px-12 py-2">{t('search')}</button>
                    </div>
                    <div
                        className="rounded-2xl grid grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-4 border-none md:px-5 py-3 overflow-auto custom-scrollbar md:pr-14 bg-transparent drop-shadow-lg">
                        {filteredMerchants.map((merchant, key) => <MerchantItem merchant={merchant} key={key}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhereToPay;