import React, {useState} from 'react'

const LoadingImg = ({ alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <div className={!loaded ? 'hidden' : ''}>
        <img {...props} onLoad={() => setLoaded(true)} alt={alt}/>
      </div>
      {
        !loaded && <div
          style={{ borderTopColor: 'transparent' }}
          className="w-10 h-10 border-2 border-blue-400 rounded-full animate-spin my-4"
        />
      }
    </>
  )
}

export default LoadingImg;