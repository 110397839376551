import React from 'react';

const RadioButton = ({ label, value, checked, onChange, readOnly, ...props }) => {
    return (
        <div {...props}>
            <label className="flex items-center text-sm text-white text-opacity-40">
                <input
                    type="radio"
                    className="hidden"
                    readOnly={readOnly}
                    value={value}
                    name="radio"
                    checked={checked}
                    onChange={onChange}
                />
                <div
                    className={`${checked ? 'border-4 border-blue-500' : 'border-2'} rounded-full mr-2`}
                    style={{ height: 16, width: 16 }}
                />
                {label}
            </label>
        </div>
    );
};

export default RadioButton;
