import React from 'react';
import Download from '@assets/svg/download.svg';
import {useTranslation} from "react-i18next";

const DownloadButton = (props) => {
    const { onClick } = props;
    const {t} = useTranslation()

    const style = {
        background: 'linear-gradient(91.43deg, #7F7AFF 0%, #5752E1 100%)'
    }

    return (
        <button style={style} onClick={onClick} className={`flex justify-center text-center rounded-6xl text-white font-normal py-2 px-14 focus:outline-none active:opacity-60`}>
            <img alt='download' className='mr-2' src={Download}/>
            {t('download')}
        </button>
    )
}

export default DownloadButton;

