import API from "@utils/plugins/API";

const DigicomService = {
  searchByContractCode: (contractCode) => API.get(
    '/utilities/online-payments/digicom/search/by-contract-code', {params: {contractCode}}),
  requestInvoicePayment: (contractCode, serialNumber) => API.post(
    '/utilities/online-payments/digicom/request-invoice-payment', {contractCode, serialNumber}),
  searchBySerialNumber: (serialNumber) => API.get(
    '/utilities/online-payments/digicom/search/by-serial', {params: {serialNumber}})

}

export default DigicomService;
