import React, { useRef } from "react";
import '@assets/css/style.css'
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import useExpand from "@utils/hooks/useExpand";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const PokCardSection = ({ data }) => {
  const containerRef = useRef();
  const imageRef = useRef();
  const buttonRef = useRef();
  useExpand(containerRef, imageRef, buttonRef);

  return (
    <div className="flex flex-col-reverse w-full justify-center lg:pb-10 px-5 lg:px-0">
      <div className="block mouse-text">
        <div className="flex-1 flex flex-col justify-center mx-5 py-10 md:px-10 md:py-15 text-center">
          <div className="text-white text-4xl 2xl:text-5xl font-semibold uppercase">{data ? data?.headerTitle : <SkeletonLoader />}</div>
          <div className="md:w-2/3 mx-auto mt-5 text-white text-opacity-60 text-xs md:text-base font-montserrat">
            <div>{data ? data?.headerDescription : <SkeletonLoader/>}</div>
          </div>
        </div>
      </div>
      <div
        className="md:w-2/3 relative pok-card-component pl-6 md:pl-10 lg:pl-5 xl:pl-15 justify-center mx-auto" ref={containerRef}>
        <div className="flex flex-col lg:flex-row mouse-text overflow-hidden align-center items-center">
          <div className="flex lg:pl-10 lg:w-3/5 flex-col md:mt-5 xl:px-2 text-left md:text-left pt-10 xl:pt-18 pb-10 md:pr-10 lg:pr-5 xl:pr-15 xl:pb-18">
            <div
              className="font-montserrat 3xl:text-4xl md:text-3xl text-xl font-semibold text-white leading-5 tracking-wide mt-5 lg:pr-5">
              {data ? <div>{data?.title}</div> : <SkeletonLoader width={400} />}
            </div>
            <div
              className="font-light text-white font-montserrat mt-5 mb-5 lg:mt-10 lg:mb-18 text-xs md:text-sm lg:pr-16">
              {data ? data?.description : <SkeletonLoader variant='text' count={2} width={400}/>}
            </div>
            <div className="flex justify-start">
              {data ? <CPrimaryButton buttonRef={buttonRef} label={data?.button}/> : <SkeletonLoader width={400}/>}
            </div>
          </div>
          <div className="flex my-40">
            {data ? <img ref={imageRef} src={data?.image?.data?.attributes?.url} alt="PokCard" className="shine w-11/12 3xl:w-110 lg:w-2/5 xl:w-1/2 md:w-4/5 absolute bottom-0 right-0"/> : <SkeletonLoader variant='circular' width={300} height={300}/>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default PokCardSection