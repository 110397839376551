import {BsInstagram} from "react-icons/bs";
import {FaLinkedinIn, FaTwitter} from "react-icons/fa";
import {ImYoutube} from "react-icons/im";
import {RiFacebookFill} from "react-icons/ri";

const socials = [
  {icon: <RiFacebookFill />, url: 'https://www.facebook.com/Pokpayltd-107883398350904/'},
  {icon: <BsInstagram />, url: 'https://www.instagram.com/pok_albania/'},
  {icon: <FaLinkedinIn/>, url: 'https://www.linkedin.com/company/pok-pay-ltd/'},
  {icon: <FaTwitter />, url: 'https://twitter.com/pokpayltd'},
  {icon: <ImYoutube />, url: 'https://www.youtube.com/channel/UCWm9held1h-yEOccZ7h_cgg'},
]

export default socials
