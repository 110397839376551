import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "@assets/css/style.css";
import Ansig from "@assets/svg/utilities/white/ansig.svg";
import Digicom from "@assets/svg/utilities/white/digicom.svg";
import Intersig from "@assets/images/intersig.png";
import One from "@assets/svg/utilities/one.svg";
import Uji from "@assets/svg/utilities/white/water_supply.svg";
import Vodafone from "@assets/svg/utilities/white/vodafone.svg";
import Albsig from "@assets/svg/utilities/white/albsig.svg";
import {useSelector} from "react-redux";
import _ from "lodash";

const UtilityItem = ({ icon, title, comingSoon = false, url }) => {
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const navigate = () => {
    if (comingSoon) return;
    history.push(url);
  };
  return (
    <div
      className={`relative overflow-hidden flex px-3 md:px-5 py-6 md:py-10 w-full flex-col my-auto justify-center items-center rounded-3xl md:rounded-5xl shadow-md
      transform ${comingSoon ? 'coming-soon-utility-item opacity-50' : 'utility-item cursor-pointer hover:translate-y-0.5'} transition ease-in-out duration-200`}
      onClick={navigate}
    >
      {!loaded && (
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-6 h-6 border-2 border-blue-400 rounded-full animate-spin my-4"
        />
      )}
      <img
        src={icon}
        alt={title}
        className={`object-contain transform text-white transition duration-500 mb-2 md:mb-3 z-50 ${
          !loaded ? "hidden" : ""
        } ${title === 'One' ? 'h-4 my-2 md:h-6 md:my-3' : 'h-6 md:h-9'}`}
        onLoad={() => setLoaded(true)}
      />
      <span className="text-center text-white text-xs lg:text-base 2xl:text-xl truncate">
        {title}
      </span>
      {comingSoon && (<div className="absolute bottom-1 md:bottom-4">
        <span
          className="text-center text-white text-xxs md:text-xs font-light uppercase tracking-tight italic">{t('comingSoon')}</span>
      </div>)}
    </div>
  );
};

const UtilitySection = () => {
  const { t } = useTranslation();

  const header = useSelector((state) =>
      _.get(state, 'homePageReducer.Utilities', {})
  );

  return (
    <div className="flex w-full justify-center px-5">
      <div className="flex flex-col lg:w-2/3 justify-center">
        <div className="text-white font-montserrat text-3xl 2xl:text-6xl font-semibold w-full uppercase text-center">
          {header?.title}
        </div>
        <div
          className="font-light text-white font-montserrat my-5 md:my-15 text-sm text-center mx-auto">
          {header?.description}
        </div>
        <div className="grid grid-cols-3 gap-4 md:grid-cols-4 md:gap-6 xl:px-10 3xl:px-20">
          <UtilityItem title="Vodafone" icon={Vodafone} url="/utilities/vodafone"/>
          <UtilityItem
            title={t("waterSupply")}
            icon={Uji}
            url="/utilities/water-supply"
          />
          <UtilityItem title="Digicom" icon={Digicom} url="/utilities/digicom"/>
          <UtilityItem title="One" icon={One} url="/utilities/one-telecom"/>
          <UtilityItem title="Albsig" icon={Albsig} url="/utilities/albsig"/>
          <UtilityItem title="Intersig" icon={Intersig} url="/utilities/intersig"/>
          <UtilityItem title="Ansig" icon={Ansig} url="/utilities/ansig"/>
        </div>
      </div>
    </div>
  );
};

export default UtilitySection;