import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonLoader = ({ variant = 'rounded', count = 1, width, height}) => {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <div key={index} className='mb-2'>
      <Skeleton variant={variant} width={width} height={height}/>
    </div>
  ));

  return <>{skeletons}</>
};
export default SkeletonLoader