const merchantFormValdiator = {
  fullName: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    },
  },
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    },
    email: {
      message: "errors.incorrectEmailFormat",
    },
  },
  businessName: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    },
  },
  nuis: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    },
    format: {
      pattern: /^([A-Z]\d{8}[A-Z]$)/i,
      message: 'errors.invalidNuis'
    }
  },
  message: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    },
  },
};

export default merchantFormValdiator;
