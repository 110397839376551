import {notify} from 'react-notify-toast';
import moment from "moment";
import 'moment/locale/sq';
import I18n from "@i18n/index";

export const showMessage = (message, timeout = 2000) => {
  notify.show(message, 'custom', timeout, {
    background: '#008ACD',
    text: 'white',
  });
};

export const showError = (message, timeout = 3000) => {
  notify.show(message, 'custom', timeout, {
    background: '#ef4444',
    text: 'white',
  });
};

export const fileToBase64 = (file) => {
  if (!file) {
    return '';
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const splitArray = reader.result.split('base64,');
      resolve(splitArray[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const dateFormatter = (date = new Date(), format = 'DD/mm/YYYY') => {
  const language = I18n.language === 'al' ? 'sq' : 'en';

  return moment(date).locale(language).format(format);
}

export const truncateText = (text, length = 100) => {
  if (length > text.length) {
    return text
  }
  return `${text.substring(0, length)}...`
}

export const resizeImageHeight = (width) => {
  if (!width) {
    return 0
  }
  return width * (9 / 16)
}

export const amountFormatter = (amount, currencyCode = 'ALL', locales = 'en-US') => {
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formattedAmount = parseFloat(amount).toLocaleString(locales, options);
  return `${formattedAmount} ${currencyCode}`;
};

export const isObjectEmpty = (object) => Object.keys(object).length === 0;

