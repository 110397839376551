import React, { useState } from "react";
import { amountFormatter, showError } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import MobileInvoice from "@components/Utilities/WaterSupply/MobileInvoice";
import { Collapse } from "react-collapse";
import UKService from "@utils/services/UKService";
import _ from "lodash";
import { FaChevronDown } from "react-icons/fa";
import CPrimaryButton from "@core/buttons/CPrimaryButton";

const DetailRow = ({ title, value }) => (
  <div className="flex justify-between items-center my-2 text-white">
    <span className="text-sm leading-none">{title}</span>
    <span className="text-sm leading-5 font-medium">{value}</span>
  </div>
);

const Invoice = ({ provider, invoice }) => {
  const [showDetails, setShowDetails] = useState(false);
  const {
    serialNumber,
    monthHuman,
    contractCode,
    customerName,
    totalNoVat,
    totalVat,
    totalWithVat,
    totalInterestOverdue,
    amountPayed,
    debt,
  } = invoice;

  const { t } = useTranslation();

  const toggleDetails = () => setShowDetails((prev) => !prev);

  const requestPayment = (e) => {
    e.stopPropagation();
    UKService.requestPayment({
      contractCode: invoice.contractCode,
      ukProvider: provider.toUpperCase(),
      serialNumber: invoice.serialNumber,
    })
      .then((response) => {
        const confirmUrl = _.get(
          response,
          "data.data.sdkOrder._self.confirmUrl",
          null
        );
        if (confirmUrl) {
          window.location.assign(confirmUrl);
        }
      })
      .catch((err) => showError(err.response.data.message));
  };

  return (
    <>
      <div
        className="hidden lg:block cursor-pointer py-6 bg-brand-dark-blue hover:opacity-90 text-white my-5 mx-4 rounded-2xl border border-opacity-50 shadow utility-checkout-card"
        onClick={toggleDetails}
      >
        <div className="flex flex-col md:flex-row items-center justify-between pr-5 xl:pl-10 pl-5">
          <div className="flex flex-col">
            <h1 className="text-sm font-medium leading-5">{monthHuman}</h1>
            <div className="flex flex-col md:flex-row md:items-center my-2">
              <div className="flex font-normal leading-5 text-sm items-center">
                <span>
                  {t("invoiceNumber")}: {serialNumber}
                </span>
              </div>
            </div>
            <div className="flex">
              <span className="uppercase font-semibold">
                {t("total")}: {amountFormatter(debt, "ALL")}
              </span>
            </div>
          </div>
          <div className="flex items-center w-2/5">
            <div className="flex mr-4 w-full">
              <CPrimaryButton
                label={t("pay")}
                onClick={requestPayment}
                className="flex-1"
              />
            </div>
            <div className="cursor-pointer items-center bg-gray-600 rounded-full transform hover:translate-y-1 duration-200">
              <FaChevronDown className="m-2" />
            </div>
          </div>
        </div>
        <Collapse isOpened={showDetails}>
          <div className="md:pl-10 pl-5 md:pr-15 pr-10">
            <div className="mt-5 py-3 border-t border-b">
              <DetailRow title={t("contractNumber")} value={contractCode} />
              <DetailRow title={t("client")} value={customerName} />
            </div>
            <div className="border-b py-3">
              <DetailRow
                title={t("totalNoVat")}
                value={amountFormatter(totalNoVat, "ALL")}
              />
              <DetailRow
                title={t("vat")}
                value={amountFormatter(totalVat, "ALL")}
              />
              <DetailRow
                title={t("invoiceValue")}
                value={amountFormatter(totalWithVat, "ALL")}
              />
            </div>
            <div className="pt-3">
              <DetailRow
                title={t("interestOverdue")}
                value={amountFormatter(totalInterestOverdue, "ALL")}
              />
              <DetailRow
                title={t("paidAmount")}
                value={
                  amountPayed > 0 ? (
                    <span className="text-green-500">
                      -{amountFormatter(amountPayed, "ALL")}
                    </span>
                  ) : (
                    <span>{amountFormatter(amountPayed, "ALL")}</span>
                  )
                }
              />
            </div>
          </div>
        </Collapse>
      </div>
      <div className="block lg:hidden mx-2">
        <MobileInvoice invoice={invoice} requestPayment={requestPayment} />
      </div>
    </>
  );
};

export default Invoice;
