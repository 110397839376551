import React, { useEffect, useRef, useState } from "react";
import Layout from "@hoc/layout/Layout";
import GradientTop from "@assets/svg/homePage/gradient_top.svg"
import useMouseLight from "@utils/hooks/useMouseLight";
import Card from "@components/MarketplacePages/Card";
import GetAppButton from "@components/GetPokApp/GetAppButton";
import CMSAPI from "@utils/plugins/CMSAPI";
import { useTranslation } from "react-i18next";
import API from "@utils/plugins/API";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const CashbackPage = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [siteKey, setSiteKey] = useState('');
  const marketPlaceRef = useRef(null);
  useMouseLight(marketPlaceRef);

  useEffect(() => {
    API.get(`/public/recaptcha-key`).then(response => {
      const siteKey = response.data.data.siteKey;
      setSiteKey(siteKey);
    })
  }, []);

  useEffect(async () => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/pay-and-get-payed?locale=${language}&populate=deep`).then(response => {
      const { attributes } = response.data.data;
      setData(attributes);
    })
  }, [i18n?.language]);

  return (
    <Layout>
      <GoogleReCaptchaProvider reCaptchaKey={siteKey} scriptProps={{ async: true }}>
        <div className="flex flex-col items-center">
          <div ref={marketPlaceRef} className='fixed'/>
          <div className="relative">
            <img src={GradientTop} alt="" className="absolute xl:-right-0" style={{ top: '-700px' }}/>
            <div className="flex flex-col lg:flex-col-reverse w-full justify-center px-5 lg:px-0 mt-20 lg:mt-30">
              <div className="block mouse-text">
                <div className="flex-1 flex flex-col justify-center mx-5 py-10 md:px-10 md:py-15 text-center">
                  <div
                    className="text-white text-4xl md:text-5xl flex flex-col 2xl:text-6xl font-semibold lg:w-2/3 text-center mx-auto tracking-wide">
                    {data ? data?.title : <SkeletonLoader />}
                  </div>
                  <div
                    className="md:w-2/3 mx-auto mt-5 text-white text-opacity-60 text-xs lg:text-base font-montserrat">
                    <div>{data ? data?.description : <SkeletonLoader count={2} variant='text'/> }</div>
                  </div>
                  <div className="flex justify-center mt-10">
                    <GetAppButton label={data?.button}/>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-14 mb-20 md:mb-40 lg:mb-20">
                {data?.cards?.map((card, key) => (
                  <Card title={card?.title}
                        description={card?.description}
                        image={card?.image?.data?.attributes?.url}
                        button={card?.button}
                        reverse={key % 2 === 0}/>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-padding"/>
      </GoogleReCaptchaProvider>
    </Layout>
  );
}

export default CashbackPage;