import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from "@hoc/layout/Layout";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dateFormatter, showError } from "@utils/helpers";
import CPrimaryButton from "@core/buttons/CPrimaryButton";
import ApplyModal from "@components/CareersPage/ApplyModal";
import useMouseLight from "@utils/hooks/useMouseLight";
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";
import API from "@utils/plugins/API";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Tag = ({ name, ...props }) => (
  <div {...props}>
    <div className="group">
      <button
        className="flex news-data items-center justify-center rounded-full border-0.5 px-4 h-8 cursor-default"
      >
          <span
            className="text-center text-white whitespace-nowrap text-xxs md:text-sm uppercase font-montserrat font-semibold">
            {name}
          </span>
      </button>
    </div>
  </div>
)

const JobPostPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const jobApplicationRef = useRef(null);
  useMouseLight(jobApplicationRef);

  const [position, setPosition] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [applied, setApplied] = useState(false);
  const [siteKey, setSiteKey] = useState('');

  useEffect(() => {
    API.get(`/public/recaptcha-key`).then(response => {
      const siteKey = response.data.data.siteKey;
      setSiteKey(siteKey);
    })
  }, []);

  const getPosition = useCallback(() => {
    const language = i18n.language === 'al' ? "sq" : i18n.language;
    CMSAPI.get(`/vacant-positions/${id}?locale=${language}`).then(response => {
      setPosition(_.get(response, 'data.data', {}))
    })
      .catch(() => {
        showError(t('somethingWentWrong'));
        history.push('/news?selectedTab=career');
      })
  }, [id, i18n, history, t])

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  return (
    <Layout>
      <div className="flex flex-col items-center bg-brand-dark-blue">
        <GoogleReCaptchaProvider reCaptchaKey={siteKey} scriptProps={{ async: true }}>
          <div className='fixed' ref={jobApplicationRef}/>
          <div className="relative flex flex-col items-center justify-center py-20 3xl:py-40 px-8">
            <div className="w-full md:w-2/3">
              <div className="flex flex-row justify-between items-center my-5">
                <CPrimaryButton
                  label={t('back')}
                  onClick={() => history.push(`/news?selectedTab=career`)}
                />
                <div className="flex justify-between md:mr-2">
                  <div className="hidden md:block">
                    <Tag name={t('career')} className="pr-3" textWhite/>
                  </div>
                  <Tag name={dateFormatter(position?.attributes?.deadline, "DD MMMM YYYY HH:mm A")} textWhite/>
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 mt-5 md:mt-15">
                <div className="font-montserrat text-4xl text-white font-semibold pb-10 uppercase mr-4">
                  {position?.attributes?.title}
                </div>
                <div className="font-montserrat text-white text-opacity-70 text-justify pb-5">
                  {position?.attributes?.description}
                  <div className="flex my-5">
                    <CPrimaryButton
                      label={t('applyForThisPosition')}
                      onClick={() => setOpenModal(true)}
                      disabled={applied}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-padding 3xl:pt-126"/>
          </div>
          <ApplyModal positionId={position?.id} isOpen={openModal} setOpenModal={setOpenModal}
                      onSuccess={() => setApplied(true)}/>
        </GoogleReCaptchaProvider>
      </div>
    </Layout>
  );
};

export default JobPostPage;
