import React, {useCallback, useEffect} from "react";
import {isIOS, isAndroid, isMobile, isMobileSafari} from "react-device-detect";
import OrderooPage from "@pages/OrderooPage";
import HomePage from "@pages/HomePage";

const Deeplink = () => {
    const url = new URL(window.location.href);
    const path = url.pathname
    const orderooId = url.searchParams.get('orderooId');

    const redirect = useCallback(() => {
        if (isMobile) {
            try {
                window.location.assign(`pokpay:/${path}`);
            } catch (e) {
                console.log('No app found');
            }
            setTimeout(() => {
                if (document.hasFocus()) {
                    if (isMobileSafari) {
                        window.location.replace("/");
                    } else if (isAndroid) {
                        window.location = "https://play.google.com/store/apps/details?id=io.pokpay";
                    } else if (isIOS) {
                        window.location = "https://apps.apple.com/app/id1590835547";
                    } else {
                        window.location.replace("/");
                    }
                }
            }, 2000);
        } else {
            window.location.replace("/");
        }
    }, [path])

    useEffect(() => {
        if (!orderooId)
            redirect()
    }, [redirect, orderooId])

    return orderooId ? <OrderooPage onPayWithPok={redirect} orderooId={orderooId}/> : <HomePage/>
}

export default Deeplink
