import React from "react";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const WhatIsPokSection = ({ data }) => {

  return (
    <div className="flex flex-col-reverse md:flex-row flex-col sm:w-2/3 md:w-5/6 xl:w-2/3 justify-between items-center sm:px-0 px-8 relative">
      <div className="text-sm md:text-base md:w-1/2 flex flex-col items-center justify-center text-white text-justify opacity-70">
        <div>{data ? data?.firstParagraph : <SkeletonLoader variant='text' count={5} width={400}/>}</div>
        <div className="mt-10">{data ? data?.secondParagraph : <SkeletonLoader variant='text' count={5} width={400}/> }</div>
      </div>
      <div className="flex justify-center 2xl:pl-0 md:pl-10">
        {data ? <img src={data?.image?.data?.attributes?.url} alt="phone" style={{ height: "400px" }} /> : <SkeletonLoader variant='circular' height={400} width={400}/>}
      </div>
    </div>
  );
};

export default WhatIsPokSection;
